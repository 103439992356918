import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrl } from '../apiConfig';

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};


export const preMeet = createAsyncThunk(
    'mason/meet-approval',
    async (premeetData, { rejectWithValue }) => {
        try {
            console.log('Pre Meet data:', premeetData);
            const token = getTokenFromLocalStorage();
            const formData = new FormData();

            formData.append('dealerName', premeetData.dealer);
            formData.append('location', premeetData.location);
            formData.append('mason_attendance', premeetData.mason_attendance);
            formData.append('planned_expenses', premeetData.planned_expenses);
            formData.append('date', premeetData.date);
            formData.append('images', premeetData.image);
            formData.append('remarks', premeetData.remarks);

            const response = await fetch(`${apiUrl}mason/add`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.log('Error response:', errorData);
                return rejectWithValue(errorData);
            }

            return await response.json();
        } catch (error) {
            console.log('Error:', error);
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const postMeet = createAsyncThunk(
    'postmason/post-meet-approval',
    async ({ postMasonData, expenditureData, images }, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const formData = new FormData();
            console.log(' POST Meet data:', formData);

            const postMasonDataObject = {};
            Object.keys(postMasonData).forEach((key, index) => {
                postMasonDataObject[index] = postMasonData[key];
            });

            formData.append('masons', JSON.stringify(postMasonDataObject));

            if (expenditureData) {
                Object.keys(expenditureData).forEach((key) => {
                    formData.append(`expenditureData[${key}]`, expenditureData[key]);
                });
            }
            if (images && Array.isArray(images)) {
                images.forEach((image, index) => {
                    formData.append('images[]', image);
                });
            }

            const response = await fetch(`${apiUrl}postmason/add`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.log('Error response:', errorData);
                return rejectWithValue(errorData);
            }

            return await response.json();
        } catch (error) {
            console.log('Error:', error);
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const fetchSalesMasonMeetData = createAsyncThunk(
    'salesMasonMeet/fetchPreData',
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}complaint/premason`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: '*/*',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || 'Something went wrong');
            }

            const data = await response.json();
            return data.data;

        } catch (error) {
            return rejectWithValue(error.message || 'Something went wrong');
        }
    }
);

export const fetchSalesPostMasonMeetData = createAsyncThunk(
    'salesMasonMeet/fetchPostData',
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}postmason`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: '*/*',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || 'Something went wrong');
            }

            const data = await response.json();
            return data.data;

        } catch (error) {
            return rejectWithValue(error.message || 'Something went wrong');
        }
    }
);


const masonSlice = createSlice({
    name: 'masonApproval',
    initialState: {
        data: [],
        postMasonData: [],
        preStatus: 'idle',
        postStatus: 'idle',
        status: 'idle',
        meetApproval: {},
        postStatus: 'idle',
        postMeetApproval: {},
        error: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(preMeet.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(preMeet.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.meetApproval = action.payload;
            })
            .addCase(preMeet.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(postMeet.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postMeet.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.postMeetApproval = action.payload;
            })
            .addCase(postMeet.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(fetchSalesMasonMeetData.pending, (state) => {
                state.preStatus = 'loading';
            })
            .addCase(fetchSalesMasonMeetData.fulfilled, (state, action) => {
                state.data = action.payload;
                state.preStatus = 'succeeded';
            })
            .addCase(fetchSalesMasonMeetData.rejected, (state, action) => {
                state.preStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(fetchSalesPostMasonMeetData.pending, (state) => {
                state.postStatus = 'loading';
            })
            .addCase(fetchSalesPostMasonMeetData.fulfilled, (state, action) => {
                state.postMasonData = action.payload;
                state.postStatus = 'succeeded';
            })
            .addCase(fetchSalesPostMasonMeetData.rejected, (state, action) => {
                state.postStatus = 'failed';
                state.error = action.payload;
            });
    },
})

export default masonSlice.reducer;