import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrl } from '../apiConfig';

const getTokenFromLocalStorage = () => localStorage.getItem("token");

export const fetchProductionMasonMeetData = createAsyncThunk(
    'productionMasonMeet/fetchData',
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}production/premason`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: '*/*',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || 'Something went wrong');
            }

            const data = await response.json();
            return data.data;

        } catch (error) {
            return rejectWithValue(error.message || 'Something went wrong');
        }
    }
);

const initialState = {
    data: [],
    status: 'idle',
    error: null,
};

const productionMasonMeetSlice = createSlice({
    name: 'productionMasonMeet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductionMasonMeetData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProductionMasonMeetData.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchProductionMasonMeetData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default productionMasonMeetSlice.reducer;
export const { } = productionMasonMeetSlice.actions;