import RsmNavbar from './RsmNavbar';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from '../../component/utils/Dashboard/Dashboard';
import { useEffect, useState } from 'react';
import { getresmcomplaint } from '../../redux/slices/Rsm/rsmcomplaint';


const RsmHome = () => {
    const { rsmdata = [] } = useSelector(state => state.rsmcomplaint);
    const dispatch = useDispatch();
    const [toggleStatus, setToggleStatus] = useState(null);
    const handleToggleStatus = (data) => {
        setToggleStatus(data);
    };

    useEffect(() => {
        dispatch(getresmcomplaint());
    }, [dispatch]);
    return (
        <>
            <RsmNavbar
                onData={handleToggleStatus}
            />
            <Dashboard
                countSlice={rsmdata}
                complaintLink={'rsm-complaint'}
                masonMeetLink={'rsm-mason-meet'}
                toggleStatus={toggleStatus}
                masonCard={true}
            />
        </>
    );
};

export default RsmHome;
