import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../redux/slices/authSlice/authSlice';
import 'font-awesome/css/font-awesome.min.css';
import logo from '../../assets/nepovit-logo.png';

const Nav = () => {
    const { name } = useSelector((state) => state.auth);
    const [toggle, setToggle] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const navbarRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();


    const handleDropdownToggle = () => {
        setToggle(false);
        setDropdownOpen(!dropdownOpen);
    };

    const handleToggle = () => {
        setDropdownOpen(false);
        setToggle(!toggle);
    };

    useEffect(() => {
        if (location.pathname === '/admin') {
            setToggle(true);

        }
    }, [location])

    const handleLogout = () => {
        dispatch(logout());
    };



    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                setToggle(false);
                setDropdownOpen(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <nav ref={navbarRef} className="flex z-30 justify-between fixed w-full bg-[rgb(14,92,167)] h-20 mb-4 text-white ">
            <button
                className="focus:outline-none z-30 mx-4 lg:block lg:ml-[5vw] hover:text-red-500 hover:scale-125"
                onClick={handleToggle}
            >
                <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 10h16M4 14h16M4 18h16"
                    ></path>
                </svg>
            </button>

            {toggle && (
                <div
                    className={`fixed inset-0 top-0 pt-16 mt-8 w-64 bg-[rgb(14,92,167)] text-white py-4 transition-transform duration-300 ${toggle ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0 lg:fixed lg:top-0 lg:left-0 lg:h-screen lg:w-1/5 rounded-lg flex flex-col justify-between bg-opacity-70 backdrop-blur-lg`}
                >
                    {/* Nav Links */}
                    <div className='flex flex-col items-center space-y-4 p-4'>
                        <NavLink
                            to="/admin"
                            className={({ isActive }) =>
                                isActive
                                    ? "bg-red-500 text-white text-center py-2 px-4 rounded-lg shadow-md transition-colors duration-300 w-full"
                                    : "bg-[#28205E] text-white text-center py-2 px-4 rounded-lg shadow-md transition-colors duration-300 hover:bg-[#1f1a4d] w-full"
                            }
                        >
                            <span className='gap-6 flex items-center'><i className="fa fa-home"></i> Dashboard</span>
                        </NavLink>

                        <NavLink
                            to="/admin-users"
                            className={({ isActive }) =>
                                isActive
                                    ? "bg-red-500 text-white text-center py-2 px-4 rounded-lg shadow-md transition-colors duration-300 w-full"
                                    : "bg-[#28205E] text-white text-center py-2 px-4 rounded-lg shadow-md transition-colors duration-300 hover:bg-[#1f1a4d] w-full"
                            }
                        >
                            <span className='gap-6 flex items-center'><i className="fa fa-users"></i>  Users</span>
                        </NavLink>

                        <NavLink
                            to="/admin-user-register"
                            className={({ isActive }) =>
                                isActive
                                    ? "bg-red-500 text-white text-center py-2 px-4 rounded-lg shadow-md transition-colors duration-300 w-full"
                                    : "bg-[#28205E] text-white text-center py-2 px-4 rounded-lg shadow-md transition-colors duration-300 hover:bg-[#1f1a4d] w-full"
                            }
                        >
                            <span className='gap-6 flex items-center'> <i className="fa fa-user-plus"></i> Register User</span>
                        </NavLink>

                        <NavLink
                            to="/admin-complaint-list"
                            className={({ isActive }) =>
                                isActive
                                    ? "bg-red-500 text-white text-center py-2 px-4 rounded-lg shadow-md transition-colors duration-300 w-full"
                                    : "bg-[#28205E] text-white text-center py-2 px-4 rounded-lg shadow-md transition-colors duration-300 hover:bg-[#1f1a4d] w-full"
                            }
                        >
                            <span className='gap-6 flex items-center'> <i className="fa fa-list-alt" /> Complaints</span>
                        </NavLink>
                        <NavLink
                            to="/manage-products"
                            className={({ isActive }) =>
                                isActive
                                    ? "bg-red-500 text-white text-center py-2 px-4 rounded-lg shadow-md transition-colors duration-300 w-full"
                                    : "bg-[#28205E] text-white text-center py-2 px-4 rounded-lg shadow-md transition-colors duration-300 hover:bg-[#1f1a4d] w-full"
                            }
                        >
                            <span className='gap-6 flex items-center'><i className="fa fa-edit"></i> Update Products</span>
                        </NavLink>
                    </div>

                    <div className="fixed bottom-0 left-0 right-0  lg:mb-7 flex flex-col items-center p-2 mt-2 space-y-2 text-sm bg-gradient-to-r from-[#28205E] via-[#4c3c92] to-[#6b5cb8] rounded-lg border border-gray-500 shadow-lg">
                        <p className="text-gray-300"> Made with <i className="fa fa-heart text-red-500" aria-hidden="true"></i> by </p>
                        <p className="font-bold text-white tracking-wider"> RUMINATE IT SOLUTION </p>
                    </div>


                </div>
            )}


            <div className="flex items-center h-[80px] w-[130px]  ml-36 transition duration-300 ease-in-out">
                <Link to='/admin'>
                    <img src={logo} className=" object-contain  hover:scale-110 transition duration-300 ease-in-out" />
                </Link>
            </div>

            <div className="relative flex items-center pr-4 gap-4">
                <span className="text-lg font-bold text-white">Welcome, {name}!</span>
                <button onClick={handleDropdownToggle} className="text-white text-3xl">
                    <i className="mr-8 fa fa-user-circle-o hover:text-red-500" />
                </button>
                {dropdownOpen && (
                    <div className="absolute right-0 mt-[13rem] mr-3 w-48 bg-blue-600 text-white rounded-lg shadow-lg z-50 bg-opacity-70 backdrop-blur-lg">
                        <div className="px-4 py-2">
                            <button
                                onClick={() => navigate('/change')}
                                className="w-full bg-gray-500 text-white text-sm py-2 rounded-lg shadow-md transition-colors duration-300 hover:bg-gray-400"
                            >
                                Change Password
                            </button>
                        </div>
                        <div className="px-4 py-2">
                            <button
                                onClick={handleLogout}
                                className="w-full bg-red-600 text-white text-sm py-2 rounded-lg shadow-md transition-colors duration-300 hover:bg-red-500"
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
}

export default Nav;
