import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSheadMasonMeetData, updateSheadMeet } from '../../redux/slices/Shead/masonMeetSlice';
import SheadNavbar from './SheadNavbar';
import MeetDetails from '../../component/utils/MasonList/MeetDetails';
import { Link, useParams } from 'react-router-dom';

const SheadMeetView = () => {

    const dispatch = useDispatch();
    const { data, status, error, updateStatus } = useSelector((state) => state.sheadMasonMeet);
    const { id } = useParams();
    const [ids, setIds] = useState("");
    const [meetDetails, setMeetDetails] = useState([]);

    useEffect(() => {
        dispatch(fetchSheadMasonMeetData());
    }, [dispatch]);

    useEffect(() => {
        if (data.length > 0) {
            const meet = data.find((meet) => meet.id === parseInt(id));
            setMeetDetails(meet);
            setIds(meet.id);
        }
    }, [data, id]);

    return (
        <>
            <SheadNavbar />
            <div className="container mx-auto pt-20 p-4">
                <MeetDetails
                    meet={meetDetails}
                    statusOptions={[
                        { value: 'open', label: 'Approve' },
                        { value: 'reject', label: 'Reject' },
                        { value: 'close', label: 'Close' },
                    ]}
                    editThunk={updateSheadMeet}
                    fetchThunk={fetchSheadMasonMeetData}
                    updateStatus={updateStatus}
                    backTo={'shead-mason-meet'}
                />
            </div>
        </>
    )
}

export default SheadMeetView;