
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from '../../component/utils/Dashboard/Dashboard';
import DispatchNavbar from './DispatchNavbar';
import { getDispatchComplaint } from '../../redux/slices/Dispatch/dispatchcomplaintslice';
import { useEffect, useState } from 'react';

const DispatchHome = () => {

    const { dispatchData } = useSelector(state => state.dispatchComplaint);
    const dispatch = useDispatch();
    const [toggleStatus, setToggleStatus] = useState(null);


    useEffect(() => {
        dispatch(getDispatchComplaint());
    }, [dispatch]);
    const handleToggleStatus = (data) => {
        setToggleStatus(data);
    };

    return (
        <>
            <DispatchNavbar
                onData={handleToggleStatus}
            />
            <Dashboard
                countSlice={dispatchData}
                complaintLink={'dispatch-complaint'}
                toggleStatus={toggleStatus}
            />
        </>
    );
}

export default DispatchHome;
