import AsmNavbar from './AsmNavbar';
import Dashboard from '../../component/utils/Dashboard/Dashboard';
import { getAsmComplaint } from '../../redux/slices/Asm/asmcomplaintslice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const AsmHome = () => {

    const { asmData } = useSelector(state => state.asmComplaint);
    const dispatch = useDispatch();
    const [toggleStatus, setToggleStatus] = useState(null);


    useEffect(() => {
        dispatch(getAsmComplaint());
    }, [dispatch]);

    const handleToggleStatus = (data) => {
        setToggleStatus(data);
    };

    return (
        <>
            <AsmNavbar
                onData={handleToggleStatus}
            />
            <Dashboard
                countSlice={asmData}
                complaintLink={'asm-complaint'}
                toggleStatus={toggleStatus}
            />
        </>
    );
}

export default AsmHome;
