import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, getUser } from '../../../redux/slices/admin/registerUserSlice';
import Nav from '../Nav';
import { Link, useNavigate } from 'react-router-dom';
import Notification from '../../notification/Notification';

const UserRegister = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        role: '',
        territory: '',
        reports_to: '',
        is_active: '1',
    });
    const [notification, setNotification] = useState({ message: '', type: '' });
    const [userList, setUserList] = useState([]);
    const dispatch = useDispatch();
    const { isLoading, status: apiStatus, error } = useSelector(state => state.users); // Renaming status to apiStatus to avoid confusion
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getUser()).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                setUserList(result.payload.data);
            }
        });
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const resultAction = await dispatch(registerUser({
                ...formData,
                is_active: Number(formData.is_active),
            })).unwrap();

            setNotification({ message: 'User registered successfully!', type: 'success' });

            setTimeout(() => {
                navigate('/admin-users');
            }, 2000);

            if (resultAction.status) {
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    role: '',
                    territory: '',
                    reports_to: '',
                    is_active: '1',
                });


            } else if (resultAction.errors) {
                setNotification({ message: 'Registration failed. Please check your input.', type: 'error' });

            }
        } catch (error) {
            if (apiStatus === 'failed') {
                setNotification({ message: error?.errors, type: 'error' });
            }
            // setNotification({ message: 'An unexpected error occurred. Please try again later.', type: 'error' });

        }
    };

    const reportToOptions = useMemo(() => {
        const filteredUsers = userList.filter(user => Number(user.role_id) === 6);

        return filteredUsers.map(user => (
            <option key={user.id} value={user.id}>{user.name}</option>
        ));
    }, [userList]);

    return (
        <>
            <Nav />
            {notification.message && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification({ message: '', type: '' })}
                />
            )}

            {isLoading === 'loading' && (
                <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
                    <div className="flex space-x-2">
                        <div className="w-6 h-6 bg-blue-500 rounded-full animate-bounce animate-delay-0"></div>
                        <div className="w-6 h-6 bg-green-500 rounded-full animate-bounce animate-delay-200"></div>
                        <div className="w-6 h-6 bg-red-500 rounded-full animate-bounce animate-delay-400"></div>
                        {/* <div className="w-4 h-4 bg-yellow-500 rounded-full animate-bounce animate-delay-600"></div> */}
                    </div>
                </div>
            )}
            <div className="container mx-auto pt-28 p-4">
                <div className="flex justify-center mb-6">
                    <h2 className="text-3xl text-blue-900 font-bold">User Registration</h2>
                </div>
                <div className="bg-white shadow-lg rounded-lg p-8 mt-8 border border-gray-200">
                    {showAlert && (
                        <div className="mb-6 p-4 bg-green-100 text-green-800 border border-green-300 rounded-lg">
                            <p>User registered successfully!</p>
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                            <div>
                                <label htmlFor="name" className="block text-gray-700 text-sm font-semibold mb-2">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="Enter name"
                                />
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="Enter email"
                                />
                            </div>
                            <div>
                                <label htmlFor="phone" className="block text-gray-700 text-sm font-semibold mb-2">Phone</label>
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="Enter phone number"
                                    pattern="[0-9]{10}"
                                    title="Please enter a 10-digit phone number"
                                />
                            </div>
                            <div>
                                <label htmlFor="role" className="block text-gray-700 text-sm font-semibold mb-2">Role</label>
                                <select
                                    id="role"
                                    name="role"
                                    value={formData.role}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                >
                                    <option value="">Select role</option>
                                    <option value="2">Accounts</option>
                                    <option value="3">Production Head</option>
                                    <option value="4">NSM</option>
                                    <option value="5">Dispatch Head</option>
                                    <option value="6">RSM</option>
                                    <option value="7">Sales Officer</option>
                                    <option value="8">TSM</option>
                                    <option value="10">Director</option>
                                    <option value="11">Billing Department</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="territory" className="block text-gray-700 text-sm font-semibold mb-2">Territory</label>
                                <input
                                    type="text"
                                    id="territory"
                                    name="territory"
                                    value={formData.territory}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="Enter the territory"
                                />
                            </div>
                            {formData.role === '7' && (
                                <div>
                                    <label htmlFor="reports_to" className="block text-gray-700 text-sm font-semibold mb-2">Reports To</label>
                                    <select
                                        id="reports_to"
                                        name="reports_to"
                                        value={formData.reports_to}
                                        onChange={handleChange}
                                        className="border border-gray-300 rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >
                                        <option value="">Select</option>
                                        {reportToOptions}
                                    </select>
                                </div>
                            )}
                            <div>
                                <label htmlFor="is_active" className="block text-gray-700 text-sm font-semibold mb-2">Status</label>
                                <select
                                    id="is_active"
                                    name="is_active"
                                    value={formData.is_active}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                >
                                    <option value="1">Activate</option>
                                    <option value="0">Deactivate</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex justify-center gap-8 mt-8">
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Submitting...' : 'Submit'}
                            </button>
                            <Link to='/admin-users'>
                                <button
                                    type="button"
                                    className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded-lg"
                                    onClick={() => setFormData({
                                        name: '',
                                        email: '',
                                        phone: '',
                                        role: '',
                                        territory: '',
                                        reports_to: '',
                                        is_active: '1',
                                    })}
                                >
                                    Cancel
                                </button>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default UserRegister;