import React, { useState, useEffect } from 'react';

const Notification = ({ message, type, onClose }) => {
    const [show, setShow] = useState(true);

    const notificationStyles = {
        success: 'bg-green-500 text-white',
        error: 'bg-red-500 text-white',
    };

    useEffect(() => {
        if (message) {
            setShow(true);
            const timer = setTimeout(() => {
                setShow(false);
                // setTimeout(() => onClose(), 300);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [message]);

    return (
        <div
            className={`fixed top-0 z-20 left-1/2 transform -translate-x-1/2 mt-[5.4rem] p-4 rounded shadow-lg transition-transform duration-500 ${show ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
                } ${message.includes('successfully') ? 'bg-green-500' : 'bg-red-500'} text-white`}
        >
            <p>{message}</p>
            <button
                onClick={() => setShow(false)}
                className="mt-2 bg-transparent text-white border border-white rounded px-2 py-1 text-sm"
            >
                OK
            </button>
        </div>
    );
};

export default Notification;
