import Navbar from './Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComplaints } from '../../redux/slices/SalesSlice/salesComplaintSlice';
import Dashboard from '../../component/utils/Dashboard/Dashboard';
import { useEffect, useState } from 'react';

const Home = () => {
    const { complaints } = useSelector((state) => state.complaintList);
    const dispatch = useDispatch();
    const [toggleStatus, setToggleStatus] = useState(null);

    const handleToggleStatus = (data) => {
        setToggleStatus(data);
    };

    useEffect(() => {
        dispatch(fetchComplaints());
    }, [dispatch]);

    return (
        <>
            <Navbar
                onData={handleToggleStatus}
            />
            <Dashboard
                countSlice={complaints}
                complaintLink={'so-complaint'}
                masonMeetLink={'sales-mason-meet'}
                toggleStatus={toggleStatus}
                masonCard={true}
            />

        </>
    );
}

export default Home;
