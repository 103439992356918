import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../../redux/slices/authSlice/authSlice';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const { loading, error, resetMessage } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validate = () => {
        const newErrors = {};
        if (!email) newErrors.email = 'Email is required';
        return newErrors;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();

        if (Object.keys(validationErrors).length === 0) {
            sessionStorage.setItem('userEmail', email);
            dispatch(forgotPassword(email));
        } else {
            setErrors(validationErrors);
        }
    };

    const handleCancel = () => {
        navigate('/');
    };

    return (
        <div className="h-screen bg-[rgb(14,92,167)] flex justify-center items-center">
            <div className="lg:w-[35vw] sm:w-full p-4 md:p-6 lg:p-8 bg-[#28205E] rounded-lg shadow-md mx-4 md:mx-6">
                <button
                    onClick={handleCancel}
                    className="float-right  h-10 w-10 rounded-full bg-red-600 text-white hover:bg-red-800 transition-colors transform hover:scale-110"
                >
                    ✕
                </button>
                <div className="p-4 md:p-6 lg:p-8">
                    <h1 className="text-3xl mt-4 md:text-4xl font-bold text-white mb-4">Forgot Password</h1>
                    <form onSubmit={handleSubmit} className="space-y-10 mt-10">
                        <div className="flex flex-col">
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your Email Id"
                                className={`w-full p-2 md:p-3 lg:p-4 pl-3 border rounded focus:border-red-500 focus:ring-red-500 focus:text-red-500 ${errors.email ? 'border-red-500' : 'border-white'}`}
                            />
                            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-red-500 text-white py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded shadow-md transform transition duration-300 hover:bg-red-700 disabled:bg-gray-500"
                            disabled={loading}
                        >
                            {loading ? 'Sending...' : 'Send Reset Link'}
                        </button>
                        {resetMessage && <p className="text-green-500 text-sm mt-4">{resetMessage}</p>}
                        {error && <p className="text-red-500 text-sm mt-4">{error}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
