import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const getDirectorComplaint = createAsyncThunk(
    "directorComplaint/getDirectorComplaint",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}director`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data.data; // Return the data array
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const updateDirectorComplaint = createAsyncThunk(
    "directorComplaint/updateDirectorComplaint",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}director/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data.data; // Return the updated data
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const directorComplaintSlice = createSlice({
    name: 'directorComplaint',
    initialState: {
        getStatus: 'idle',
        updateStatus: 'idle',
        directorData: [],
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDirectorComplaint.pending, (state) => {
                state.getStatus = "pending";
            })
            .addCase(getDirectorComplaint.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.directorData = action.payload;
            })
            .addCase(getDirectorComplaint.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(updateDirectorComplaint.pending, (state) => {
                state.updateStatus = "pending";
            })
            .addCase(updateDirectorComplaint.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';

            })
            .addCase(updateDirectorComplaint.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default directorComplaintSlice.reducer;
