import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProductionMasonMeetData } from '../../redux/slices/Production/masonMeetSlice';
import MasonMeetList from '../../component/utils/MasonList/MasonMeetList';
import ProductionNavbar from './ProductionNavbar';

const ProductionMasonMeet = () => {

    const dispatch = useDispatch();
    const { data, status, error } = useSelector((state) => state.productionMasonMeet);

    useEffect(() => {
        dispatch(fetchProductionMasonMeetData());
    }, [dispatch]);

    return (
        <>
            <ProductionNavbar />
            <MasonMeetList
                fetchData={data}
                status={status}
                error={error}
                view={'production-meet-details'}
            />
        </>
    )
}

export default ProductionMasonMeet;