import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiUrl } from '../apiConfig';
const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};
export const fetchComplaints = createAsyncThunk('complaintList/fetchComplaints', async (_, { rejectWithValue }) => {
    try {
        const token = getTokenFromLocalStorage();
        const response = await axios.get(`${apiUrl}complaint`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                Accept: "*/*",
            },
        });

        return response.data.data;
    } catch (error) {
        return rejectWithValue(error.response.data.message || 'Something went wrong');
    }
});

const salesComplaintSlice = createSlice({
    name: 'complaintList',
    initialState: {
        complaints: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchComplaints.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchComplaints.fulfilled, (state, action) => {
                state.loading = false;
                state.complaints = action.payload;
            })
            .addCase(fetchComplaints.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default salesComplaintSlice.reducer;
