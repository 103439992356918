import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrl } from '../apiConfig';

const getTokenFromLocalStorage = () => localStorage.getItem("token");

export const fetchDirMasonMeetData = createAsyncThunk(
    'dirMasonMeet/fetchPreData',
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}director/premason`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: '*/*',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || 'Something went wrong');
            }

            const data = await response.json();
            return data.data;

        } catch (error) {
            return rejectWithValue(error.message || 'Something went wrong');
        }
    }
);

export const fetchDirPostMasonMeetData = createAsyncThunk(
    'dirMasonMeet/fetchPostData',
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}director/postmason`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: '*/*',
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || 'Something went wrong');
            }

            const data = await response.json();
            return data.data;

        } catch (error) {
            return rejectWithValue(error.message || 'Something went wrong');
        }
    }
);


export const updateDirMeet = createAsyncThunk(
    "dirMasonMeet/updateMeet",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}director/premason/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(datas),
            });
            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }
            const data = await response.json();
            return data.data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const initialState = {
    data: [],
    status: 'idle',
    postStatus: 'idle',
    updateStatus: 'idle',
    postMasonData: [],
    error: null,
};

const dirMasonMeetSlice = createSlice({
    name: 'dirMasonMeet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDirMasonMeetData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDirMasonMeetData.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchDirMasonMeetData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(fetchDirPostMasonMeetData.pending, (state) => {
                state.postStatus = 'loading';
            })
            .addCase(fetchDirPostMasonMeetData.fulfilled, (state, action) => {
                state.postMasonData = action.payload;
                state.postStatus = 'succeeded';
            })
            .addCase(fetchDirPostMasonMeetData.rejected, (state, action) => {
                state.postStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(updateDirMeet.pending, (state) => {
                state.updateStatus = "pending";
            })
            .addCase(updateDirMeet.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';

            })
            .addCase(updateDirMeet.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            });


    },
});

export default dirMasonMeetSlice.reducer;