import React, { useEffect } from 'react';
import Nav from './Nav';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAdminMasonMeetData, fetchAdminPostMasonMeetData } from '../../redux/slices/admin/masonMeetSlice';
import MasonMeetList from '../../component/utils/MasonList/MasonMeetList';
import { Link } from 'react-router-dom';

const AdminMasonMeet = () => {

    const dispatch = useDispatch();
    const { data, status, error, postMasonData, postStatus } = useSelector((state) => state.adminMasonMeet);

    useEffect(() => {
        dispatch(fetchAdminMasonMeetData());
        dispatch(fetchAdminPostMasonMeetData());

    }, [dispatch]);

    return (
        <>
            <div className='mt-0'></div>
            <Nav />
            <MasonMeetList
                fetchData={data}
                postData={postMasonData}
                status={status}
                error={error}
                view={'admin-meet-details'}
                postView={'admin-postmeet-details'}

            />
        </>
    )
}

export default AdminMasonMeet;