import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const getAsmComplaint = createAsyncThunk(
    "asmComplaint/getAsmComplaint",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}asm`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data.data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);



const asmComplaintSlice = createSlice({
    name: 'asmComplaint',
    initialState: {
        getStatus: 'idle',
        asmData: [],
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAsmComplaint.pending, (state) => {
                state.getStatus = "pending";
            })
            .addCase(getAsmComplaint.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.asmData = action.payload;
            })
            .addCase(getAsmComplaint.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })

    }
});

export default asmComplaintSlice.reducer;
