import React from 'react'

const Loader = () => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
            <div className="flex space-x-2">
                <div className="w-6 h-6 bg-blue-500 rounded-full animate-bounce animate-delay-0"></div>
                <div className="w-6 h-6 bg-green-500 rounded-full animate-bounce animate-delay-200"></div>
                <div className="w-6 h-6 bg-red-500 rounded-full animate-bounce animate-delay-400"></div>
                {/* <div className="w-4 h-4 bg-yellow-500 rounded-full animate-bounce animate-delay-600"></div> */}
            </div>
        </div>
    )
}

export default Loader