import { useState } from 'react';
import Navbar from './Navbar';
import { preMeet, postMeet } from '../../redux/slices/SalesSlice/salesMasonMeet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../loader/Loader';

const Meet = () => {
    const [masonMeetFilter, setMasonMeetFilter] = useState('pre');
    const { status, error } = useSelector(state => state.meetApproval);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [preMasonFormData, setPreMasonFormData] = useState({
        dealer: '',
        location: '',
        mason_attendance: '',
        planned_expenses: '',
        date: '',
        image: null,
        remarks: '',
    });
    const [postImages, setPostImages] = useState([]);
    const [postMasonData, setPostMasonData] = useState([]);
    const [expenditureData, setExpenditureData] = useState({
        food_number_of_masons: 0,
        food_expense_per_mason: 400,
        food_total_amount: 0,
        kit_number_of_masons: 0,
        kit_expense_per_mason: 800,
        kit_total_amount: 0,
        billPDF: null,
        total_expenditure: 0,
    });

    const [rows, setRows] = useState([
        { masonName: "", masonPhone: "", masonKit: "YES", food: "YES", presentation: "YES" },
    ]);

    const addRow = () => {
        setRows([
            ...rows,
            { masonName: "", masonPhone: "", masonKit: "YES", food: "YES", presentation: "YES" },
        ]);
    };

    const deleteRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const handleRowChange = (e, index) => {
        const { name, value } = e.target;
        const updatedRows = rows.map((row, i) =>
            i === index ? { ...row, [name]: value } : row
        );
        setRows(updatedRows);

        const masons = updatedRows.filter((row) => row.masonKit === 'YES' || row.food === 'YES');
        const foodingMasons = masons.filter((row) => row.food === 'YES');
        const kitMasons = masons.filter((row) => row.masonKit === 'YES');

        setExpenditureData({
            ...expenditureData,
            food_number_of_masons: foodingMasons.length,
            food_total_amount: foodingMasons.length * expenditureData.food_expense_per_mason,
            kit_number_of_masons: kitMasons.length,
            kit_total_amount: kitMasons.length * expenditureData.kit_expense_per_mason,
            total_expenditure: foodingMasons.length * expenditureData.food_expense_per_mason + kitMasons.length * expenditureData.kit_expense_per_mason,
        });

        setPostMasonData(updatedRows);
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);

        if (files.length > 3) {
            setErrors({ ...errors, images: 'You can only upload a maximum of 3 images.' });
        } else {
            setPostImages(files);
            setErrors({ ...errors, images: null });
        }
    };

    const handlePreMasonChange = (e) => {
        const { name, value, type, files } = e.target;
        setPreMasonFormData({
            ...preMasonFormData,
            [name]: type === 'file' ? files[0] : value,
        });
    };

    const handleSave = async (e) => {
        e.preventDefault();

        if (masonMeetFilter === 'pre') {

            console.table('Pre ', preMasonFormData);
            try {
                await dispatch(preMeet(preMasonFormData)).unwrap();
                setSuccessMessage('Registered successfully');
            } catch (error) {
                setSuccessMessage('');
                setErrors('Some  error occurred. Please try again later !');
            }

        } else {
            try {
                await dispatch(postMeet({ postMasonData, expenditureData, images: postImages })).unwrap();
                setSuccessMessage('Registered successfully');
            } catch (error) {
                setSuccessMessage('');
                setErrors('Some  error occurred. Please try again later !');
            }
        }
    };
    console.log("pre masonn images" + preMasonFormData.image)
    console.log("images" + JSON.stringify(postImages));
    return (
        <>
            <Navbar />

            {status === 'loading' && (
                <Loader />
            )}

            <div className="container mx-auto p-6 mt-24 bg-gray-100 border-2 mb-5 border-gradient shadow-lg rounded-lg">
                {successMessage && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                        <div className="bg-white p-6 rounded-md shadow-lg max-w-sm w-full mx-4 text-center">
                            <h3 className="text-lg font-medium text-gray-800 mb-4">{successMessage}</h3>
                            <button
                                onClick={() => {
                                    setSuccessMessage(false);
                                    navigate(-1);

                                }}
                                className="bg-blue-500 text-white py-2 px-6 rounded-md font-semibold hover:bg-blue-600 transition-colors duration-150"
                            >
                                OK
                            </button>
                        </div>
                    </div>
                )}
                {/* Pre Mason Meet */}
                {masonMeetFilter === 'pre' && (
                    <div className="flex flex-col items-center bg-white p-6 shadow-lg rounded-lg mb-6">
                        <div className="flex flex-col items-center justify-center mb-6">
                            <label className="block text-lg font-bold mb-4">Mason Meet:</label>
                            <div>
                                <button
                                    className={`px-6 py-2 text-md font-bold rounded-l ${masonMeetFilter === 'pre' ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-gray-300 hover:bg-gray-400 text-gray-800'}`}
                                    onClick={() => setMasonMeetFilter('pre')}
                                >
                                    Pre
                                </button>
                                <button
                                    className={`px-6 py-2 text-md font-bold rounded-r ${masonMeetFilter === 'post' ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-gray-300 hover:bg-gray-400 text-gray-800'}`}
                                    onClick={() => setMasonMeetFilter('post')}
                                >
                                    Post
                                </button>
                            </div>
                        </div>
                        <table className="min-w-full bg-white border border-gray-300 rounded-lg overflow-hidden">
                            <tbody>
                                {['dealer', 'location', 'mason_attendance', 'planned_expenses', 'date'].map((field, index) => (
                                    <tr key={field} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                        <td className="px-6 py-4 font-medium ">{field.replace('_', ' ').toUpperCase()}</td>
                                        <td className="px-6 py-4">
                                            <input
                                                type={field === 'date' ? 'date' : 'text'}
                                                name={field}
                                                value={preMasonFormData[field] || ''}
                                                onChange={handlePreMasonChange}
                                                className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                            />
                                        </td>
                                    </tr>
                                ))}
                                <tr className="bg-gray-50">
                                    <td className="px-6 py-4 font-medium ">UPLOAD IMAGE</td>
                                    <td className="px-6 py-4">
                                        <input
                                            type="file"
                                            name="image"
                                            onChange={handlePreMasonChange}
                                            className="w-full p-2 border border-gray-300 rounded-lg shadow-sm"
                                        />
                                    </td>
                                </tr>
                                <tr className="bg-white">
                                    <td className="px-6 py-4 font-medium">ANY EXTRA SUPPORT (Remarks)</td>
                                    <td className="px-6 py-4">
                                        <textarea
                                            name="remarks"
                                            value={preMasonFormData.remarks || ''}
                                            onChange={handlePreMasonChange}
                                            className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}


                {/* Post Mason Meet */}
                {masonMeetFilter === 'post' && (
                    <div className="bg-white p-6 shadow-md rounded-lg">
                        <div className="flex flex-col items-center justify-center mb-6">
                            <label className="block text-lg font-bold mb-4">Mason Meet:</label>
                            <div>
                                <button
                                    className={`px-6 py-2 text-md font-bold rounded-l ${masonMeetFilter === 'pre' ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-gray-300 hover:bg-gray-400 text-gray-800'}`}
                                    onClick={() => setMasonMeetFilter('pre')}
                                >
                                    Pre
                                </button>
                                <button
                                    className={`px-6 py-2 text-md font-bold rounded-r ${masonMeetFilter === 'post' ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-gray-300 hover:bg-gray-400 text-gray-800'}`}
                                    onClick={() => setMasonMeetFilter('post')}
                                >
                                    Post
                                </button>
                            </div>
                        </div>

                        <table className="table-auto w-full border-collapse mb-6 shadow-lg rounded-lg overflow-hidden">
                            <thead className="bg-gradient-to-r from-blue-500 to-blue-400 text-white">
                                <tr>
                                    <th className="px-4 py-3 border-b">S.N.</th>
                                    <th className="px-4 py-3 border-b">MASON NAME</th>
                                    <th className="px-4 py-3 border-b">PHONE NO.</th>
                                    <th className="px-4 py-3 border-b">KIT</th>
                                    <th className="px-4 py-3 border-b">FOOD</th>
                                    <th className="px-4 py-3 border-b">PRESENTATION</th>
                                    <th className="px-4 py-3 border-b text-center">ACTION</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {rows.map((row, index) => (
                                    <tr key={index} className="hover:bg-gray-100 transition-colors">
                                        <td className="px-4 py-3 border-b">{index + 1}</td>
                                        <td className="px-4 py-3 border-b">
                                            <input
                                                type="text"
                                                name="masonName"
                                                value={row.masonName}
                                                onChange={(e) => handleRowChange(e, index)}
                                                className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-300"
                                            />
                                        </td>
                                        <td className="px-4 py-3 border-b">
                                            <input
                                                type="tel"
                                                pattern="[0-9]{10}"
                                                name="masonPhone"
                                                value={row.masonPhone}
                                                onChange={(e) => handleRowChange(e, index)}
                                                className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-300"
                                            />
                                        </td>
                                        <td className="px-4 py-3 border-b">
                                            <select
                                                name="masonKit"
                                                value={row.masonKit}
                                                onChange={(e) => handleRowChange(e, index)}
                                                className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-300"
                                            >
                                                <option value="YES">YES</option>
                                                <option value="NO">NO</option>
                                            </select>
                                        </td>
                                        <td className="px-4 py-3 border-b">
                                            <select
                                                name="food"
                                                value={row.food}
                                                onChange={(e) => handleRowChange(e, index)}
                                                className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-300"
                                            >
                                                <option value="YES">YES</option>
                                                <option value="NO">NO</option>
                                            </select>
                                        </td>
                                        <td className="px-4 py-3 border-b">
                                            <select
                                                name="presentation"
                                                value={row.presentation}
                                                onChange={(e) => handleRowChange(e, index)}
                                                className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-300"
                                            >
                                                <option value="YES">YES</option>
                                                <option value="NO">NO</option>
                                            </select>
                                        </td>
                                        <td className="px-4 py-3 border-b text-center">
                                            <button
                                                onClick={() => deleteRow(index)}
                                                className="text-red-500 hover:text-red-700 transition-colors transform hover:scale-110"
                                            >
                                                ✕
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <button
                            onClick={addRow}
                            className="py-2 px-4 float-right m-4 bg-green-600 text-white rounded"
                        >
                            Add Row
                        </button>

                        {/* Footer Table */}
                        <div className="mt-6">
                            <table className="table-auto w-full border-collapse mb-6 shadow-lg rounded-lg overflow-hidden">
                                <thead className="bg-gradient-to-r from-green-500 to-green-400 text-white">
                                    <tr>
                                        <th className="px-4 py-3 border-b">CATEGORY</th>
                                        <th className="px-4 py-3 border-b">TOTAL NUMBER OF MASON</th>
                                        <th className="px-4 py-3 border-b">EXPENSES PER MASON</th>
                                        <th className="px-4 py-3 border-b">TOTAL AMOUNT</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    <tr className="hover:bg-gray-100 transition-colors">
                                        <td className="px-4 py-3 font-bold border-b">FOODING</td>
                                        <td className="px-4 py-3 border-b">{expenditureData.food_number_of_masons}</td>
                                        <td className="px-4 py-3 border-b">
                                            <input
                                                value={expenditureData.food_expense_per_mason}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    const newExpensesPerMason = isNaN(parseInt(newValue, 10)) ? 0 : parseInt(newValue, 10);
                                                    setExpenditureData((prevData) => ({
                                                        ...prevData,
                                                        food_expense_per_mason: newExpensesPerMason,
                                                        food_total_amount: newExpensesPerMason * prevData.food_number_of_masons,
                                                    }));
                                                }}
                                                className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-green-300"
                                            />
                                        </td>
                                        <td className="px-4 py-3 border-b">{expenditureData.food_total_amount}</td>
                                    </tr>
                                    <tr className="hover:bg-gray-100 transition-colors">
                                        <td className="px-4 py-3 font-bold border-b">KIT</td>
                                        <td className="px-4 py-3 border-b">{expenditureData.kit_number_of_masons}</td>
                                        <td className="px-4 py-3 border-b">{expenditureData.kit_expense_per_mason}</td>
                                        <td className="px-4 py-3 border-b">{expenditureData.kit_total_amount}</td>
                                    </tr>
                                    <tr className="bg-gray-50">
                                        <td className="px-4 py-3 font-bold text-lg">TOTAL EXPENDITURE</td>
                                        <td className="px-4 py-3"></td>
                                        <td className="px-4 py-3"></td>
                                        <td className="px-4 py-3 font-bold text-lg">{expenditureData.food_total_amount + expenditureData.kit_total_amount}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <div className="flex gap-2 mt-8 max-w-md mx-auto">
                            <div className="mb-6">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Upload PDF</label>
                                <input
                                    type="file"
                                    name="billPDF"
                                    onChange={(e) => {
                                        setExpenditureData({ ...expenditureData, billPDF: e.target.files[0] });
                                    }}
                                    className="block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Upload Image</label>
                                <input
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    name="billJPEG"
                                    onChange={handleFileChange}
                                    className={`border ${errors.images ? 'border-red-500' : 'border-gray-300'} p-3 rounded-lg focus:outline-none focus:border-blue-500 w-full`}
                                />
                                {errors.images && <p className="text-red-500 text-sm mt-1">{errors.images}</p>}

                            </div>
                        </div>

                    </div>
                )}
                <div className="flex justify-center gap-32 pt-10 pb-6">
                    <button
                        onClick={handleSave}
                        className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md transform transition duration-300 hover:bg-blue-700 hover:-translate-y-1 hover:shadow-lg"
                        disabled={status === 'loading'}

                    >
                        {status === 'loading' ? 'Submitting...' : 'Submit'}

                    </button>
                    <button
                        className="bg-red-600 text-white py-2 px-4 rounded-lg shadow-md transform transition duration-300 hover:bg-red-700 hover:-translate-y-1 hover:shadow-lg"
                        onClick={() => navigate(-1)}
                        type="button"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </>
    );
};

export default Meet;
