import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAcctMasonMeetData, updateAcctMeet } from '../../redux/slices/Account/masonMeetSlice';
import AccountNavbar from './AccountNavbar';
import MeetDetails from '../../component/utils/MasonList/MeetDetails';
import { Link, useParams } from 'react-router-dom';

const AcctMeetView = () => {

    const dispatch = useDispatch();
    const { data, status, error, updateStatus } = useSelector((state) => state.acctMasonMeet);
    const { id } = useParams();
    const [ids, setIds] = useState("");
    const [meetDetails, setMeetDetails] = useState([]);

    useEffect(() => {
        dispatch(fetchAcctMasonMeetData());
    }, [dispatch]);

    useEffect(() => {
        if (data.length > 0) {
            const meet = data.find((meet) => meet.id === parseInt(id));
            setMeetDetails(meet);
            setIds(meet.id);
        }
    }, [data, id]);

    return (
        <>
            <AccountNavbar />
            <div className="container mx-auto pt-20 p-4">

                <MeetDetails
                    meet={meetDetails}
                    statusOptions={[
                        { value: 'close', label: 'Close' }
                    ]}
                    editThunk={updateAcctMeet}
                    fetchThunk={fetchAcctMasonMeetData}
                    updateStatus={updateStatus}
                    backTo={'acct-mason-meet'}
                />

            </div>
        </>
    )
}

export default AcctMeetView;