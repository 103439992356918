import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Dashboard = ({ countSlice, complaintLink, masonMeetLink, toggleStatus, masonCard }) => {
    const [openCount, setOpenCount] = useState(0);
    const [closedCount, setClosedCount] = useState(0);
    const [rejectedCount, setRejectedCount] = useState(0);

    useEffect(() => {
        if (countSlice) {

            const open = countSlice.filter(complaint => complaint.status === 'open').length;
            const closed = countSlice.filter(complaint => complaint.status === 'close').length;
            const rejected = countSlice.filter(complaint => complaint.status === 'reject').length;

            setOpenCount(open);
            setClosedCount(closed);
            setRejectedCount(rejected);
        }
    }, [countSlice]);
    // console.log('dashboard', toggleStatus)

    return (
        <>
            <div className="bg-gray-100 min-h-screen flex justify-center items-center p-6">
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:gap-[20rem] max-w-md lg:translate-x-20'>

                    {/* if needed use this */}
                    {/* <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:gap-[20rem] max-w-md 
                         ${!toggleStatus ? (masonCard ? 'lg:translate-x-[-5rem]' : 'lg:translate-x-[5rem]') : 'lg:translate-x-20'}`}> */}

                    <Link to={`/${complaintLink}`}>
                        <div className=" h-48 lg:w-72 lg:h-48 bg-gradient-to-r w-64 from-blue-300 to-blue-500 border border-gray-200 rounded-xl shadow-lg p-6 hover:shadow-2xl hover:scale-105 transition-transform duration-300">
                            <h2 className="text-lg font-bold text-white mb-4 text-center">Complaints</h2>
                            <div className="flex flex-col space-y-3">
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-medium text-white">Open</p>
                                    <p className="text-xl font-extrabold text-white">{openCount}</p>
                                </div>
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-medium text-white">Closed</p>
                                    <p className="text-xl font-extrabold text-white">{closedCount}</p>
                                </div>
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-medium text-white">Rejected</p>
                                    <p className="text-xl font-extrabold text-white">{rejectedCount}</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                    {/* {masonCard && (

                        <Link to={`/${masonMeetLink}`}>
                            <div className=" h-48 lg:w-72 lg:h-48 bg-gradient-to-r w-64 from-blue-300 to-blue-500 border border-gray-200 rounded-xl shadow-lg p-6 hover:shadow-2xl hover:scale-105 transition-transform duration-300">
                                <h2 className="text-lg font-bold text-white mb-4 text-center">Mason Meet</h2>
                                <div className="flex flex-col space-y-3">

                                </div>
                            </div>
                        </Link>
                    )} */}

                </div>
            </div>
        </>
    );
}

export default Dashboard;
