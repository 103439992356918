import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Navbar from './Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComplaints } from '../../redux/slices/SalesSlice/salesComplaintSlice';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Loader from '../loader/Loader';
import Pdf from '../../component/utils/pdfDownload/Pdf';
import ComplaintDetails from '../../component/utils/ComplaintView/ComplaintDetails';

const ComplaintStructure = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { complaints, loading } = useSelector((state) => state.complaintList);
    const [complaint, setComplaint] = useState(null);
    const [lightbox, setLightbox] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    let grievance_type = "product";

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

    };


    useEffect(() => {
        if (!complaints.length) {
            dispatch(fetchComplaints());
        } else {
            const foundComplaint = complaints.find((complaint) => complaint.id === parseInt(id, 10));
            setComplaint(foundComplaint);
        }
    }, [id, complaints, dispatch]);

    if (!complaint) {
        return (
            <div className="container mx-auto pt-24 p-4 animate-pulse">
                {loading === true && (
                    <Loader />
                )}
                <h2 className="h-8 bg-gray-300 rounded mb-8 mx-auto w-1/3 text-center"></h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Complaint Information Skeleton */}
                    <div className="bg-white p-8 border-2 border-gray-300 rounded-lg space-y-4">
                        <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                        <div className="space-y-2">
                            {Array(15).fill('').map((_, index) => (
                                <div key={index} className="h-4 bg-gray-300 rounded w-full"></div>
                            ))}
                        </div>
                    </div>


                    <div className="bg-white p-8 border-2 border-gray-300 rounded-lg space-y-4">
                        <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                        <div className="space-y-2">
                            {Array(3).fill('').map((_, index) => (
                                <div key={index} className="h-4 bg-gray-300 rounded w-full"></div>
                            ))}
                        </div>
                    </div>


                    <div className="bg-white p-8 border-2 border-gray-300 rounded-lg">
                        <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {Array(3).fill('').map((_, index) => (
                                <div key={index} className="h-24 bg-gray-300 rounded w-full"></div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex justify-center mt-8">
                    <div className="h-10 bg-gray-300 rounded w-32"></div>
                </div>
            </div>
        );
    }


    return (
        <div>
            <Navbar />
            {loading === true && (
                <Loader />
            )}
            <div className=" container mx-auto pt-24 p-4">
                <div className="flex justify-between items-center mt-8 mb-8">
                    <Link to="/so-complaint">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 lg:h-10 lg:w-10 p-1 delay-200 text-blue-500 hover:text-blue-700 cursor-pointer bg-gray-300 hover:bg-gray-100 rounded-full"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </Link>
                    <h2 className="text-2xl font-bold text-blue-900  ml-10">
                        Complaint Details
                    </h2>
                    <Pdf complaintDetails={complaint} />

                </div>
                <ComplaintDetails
                    complaints={complaint}
                    tagged={complaint.tagname}
                />

                <div className="bg-white p-6 mt-8 border-2 border-gradient rounded-lg shadow-lg">
                    <h3 className="text-2xl font-semibold mb-6 text-center">Image Gallery</h3>
                    <div className="image-gallery grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4">
                        {complaint.images && complaint.images.map((img, index) => (
                            <div key={index} className=" image-thumb p-1">
                                <img
                                    src={img}
                                    alt={`Image ${index + 1}`}
                                    className="    h-32 w-32 object-fit-contain rounded-lg cursor-pointer transition-transform transform hover:scale-105"
                                    onClick={() => {
                                        setSelectedImage(img);
                                        setLightbox(true);
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    {lightbox && (
                        <div className="lightbox mt-10 fixed inset-0 z-40 bg-black bg-opacity-70 flex justify-center items-center">
                            <div className="slider-container lg:h-[42rem] lg:w-[37rem] h-[30rem] w-[22rem] rounded-lg relative overflow-hidden">
                                <button
                                    onClick={() => setLightbox(false)}
                                    className="absolute z-30 top-20 right-4 text-gray-500 hover:text-gray-900 bg-white p-2 rounded-full shadow-md hover:bg-gray-200 transition duration-300"
                                    aria-label="Close Lightbox"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                                <Slider {...settings} className="">
                                    {complaint.images.map((img, index) => (
                                        <div key={index} className="flex justify-center items-center p-2">
                                            <img
                                                src={img}
                                                alt={`Image ${index + 1}`}
                                                className=" lg:h-[42rem] lg:w-[37rem] h-[30rem] w-[22rem] object-contain rounded-lg"
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    )}
                </div>
                {/* back button */}
                <div className="flex justify-center mt-6">
                    <Link to="/so-complaint">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 lg:h-10 lg:w-10 p-1 delay-200 text-blue-500 hover:text-blue-700 cursor-pointer bg-gray-300 hover:bg-gray-100 rounded-full"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ComplaintStructure;
