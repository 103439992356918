import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrl } from '../apiConfig';

const getTokenFromLocalStorage = () => localStorage.getItem("token");

// Async thunk for fetching general complaints
export const fetchComplaints = createAsyncThunk('complaintList/fetchComplaints', async (_, { rejectWithValue }) => {
    try {
        const token = getTokenFromLocalStorage();
        const response = await fetch(`${apiUrl}admin/complaints`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            return rejectWithValue(errorData.message || 'Something went wrong');
        }

        const data = await response.json();
        return data.data; // Adjust if necessary

    } catch (error) {
        return rejectWithValue(error.message || 'Something went wrong');
    }
});

//  thunk for admin dashboard complaints
export const fetchAdminDashboardComplaints = createAsyncThunk('complaintList/fetchAdminDashboardComplaints', async (_, { rejectWithValue }) => {
    try {
        const token = getTokenFromLocalStorage();
        const response = await fetch(`${apiUrl}admin/dashboard`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            return rejectWithValue(errorData.message || 'Something went wrong');
        }

        const data = await response.json();
        return data.data;

    } catch (error) {
        return rejectWithValue(error.message || 'Something went wrong');
    }
});

// Async thunk for updating a complaint
export const updateComplaint = createAsyncThunk(
    'updateComplaint',
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/complaints/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: '*/*',
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;

        } catch (error) {
            return rejectWithValue({ message: 'Something went wrong' });
        }
    });

const complaintListSlice = createSlice({
    name: 'complaintList',
    initialState: {
        updateStatus: 'idle',
        complaints: [],
        dashboardComplaints: [],
        loading: false,
        error: null,
        complaint: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchComplaints.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchComplaints.fulfilled, (state, action) => {
                state.loading = false;
                state.complaints = action.payload;
            })
            .addCase(fetchComplaints.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchAdminDashboardComplaints.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAdminDashboardComplaints.fulfilled, (state, action) => {
                state.loading = false;
                state.dashboardComplaints = action.payload;
            })
            .addCase(fetchAdminDashboardComplaints.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateComplaint.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(updateComplaint.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
                // state.complaint = action.payload;
            })
            .addCase(updateComplaint.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            });
    },
});

export default complaintListSlice.reducer;
