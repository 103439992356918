import Nav from "./Nav";
import { addType, fetchProduct } from "../../redux/slices/admin/productUpdateSlice";
import { addProduct } from "../../redux/slices/admin/productUpdateSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchComplaintType } from "../../redux/slices/FormProductSuggestion/suggestionsSlice";
import Loader from "../loader/Loader";
import Notification from '../../pages/notification/Notification'

const ProductUpdate = () => {
    const { products, loading, error, updateStatus } = useSelector((state) => state.productList);
    const { complaintType } = useSelector((state) => state.suggestions);
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [productDetails, setProductDetails] = useState({
        sku_code: "",
        name: "",
        size: "",
    });

    const [searchQuery, setSearchQuery] = useState('');

    const filteredProducts = products.filter((product) => {
        const query = searchQuery.toLowerCase();
        return (
            product.sku_code.toLowerCase().includes(query) ||
            product.name.toLowerCase().includes(query) ||
            product.size.toLowerCase().includes(query)
        );
    });

    useEffect(() => {
        dispatch(fetchProduct());
        dispatch(fetchComplaintType());
        console.log("products " + products + "type " + complaintType);
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setProductDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(addProduct(productDetails));
        dispatch(addType(name));
        setProductDetails({
            sku_code: "",
            name: "",
            size: "",
        });
    };
    return (
        <>
            <Nav />
            {loading === true || updateStatus === 'pending' && (
                <Loader />
            )}
            {updateStatus === 'succeeded' && (
                <Notification message={'Product added successfully'} />
            )}
            <div className="container mx-auto p-6 bg-gray-50">
                <div className="mt-20  border-2 border-gradient">
                    {/* Add Product Form */}
                    <div className="bg-gradient-to-r from-blue-600 to-teal-400 shadow-lg m-1 rounded-lg p-6 mb-8 border border-gray-200 ">
                        <form onSubmit={handleSubmit} className="space-y-4 mt-7">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                                <div className="flex flex-col">
                                    <label className="text-sm font-medium text-white mb-1">SKU Code</label>
                                    <input
                                        type="text"
                                        name="sku_code"
                                        value={productDetails.sku_code}
                                        onChange={handleInputChange}
                                        className="p-3 border w-[14rem] border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        placeholder="Enter SKU Code"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label className="text-sm font-medium text-white mb-1">Product Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={productDetails.name}
                                        onChange={handleInputChange}
                                        className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        placeholder="Enter Product Name"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label className="text-sm font-medium text-white mb-1">Product Size</label>
                                    <input
                                        type="text"
                                        name="size"
                                        value={productDetails.size}
                                        onChange={handleInputChange}
                                        className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        placeholder="Enter Product Size"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label className="text-sm font-medium text-white mb-1">Complaint Type</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        placeholder="Enter Complaint Type"
                                    />
                                </div>
                            </div>
                            <div className="flex justify-center mt-6">
                                <button
                                    type="submit"
                                    className="py-2 px-6 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-500 hover:scale-105 focus:outline-none focus:ring-2 transition duration-300"
                                >
                                    Add
                                </button>
                            </div>
                        </form>
                    </div>

                    {/* Products and Complaint Types Tables */}
                    <div className="bg-white shadow-lg rounded-lg p-6 border border-gray-200">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                            <div>
                                <div className="flex justify-between mb-4">
                                    <h3 className="text-lg font-semibold text-gray-700 mb-4">Products</h3>
                                    <input
                                        type="search"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        placeholder="Search products..."
                                        className="p-3 py-0 border border-indigo-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    />
                                </div>
                                <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
                                    <thead className='bg-gradient-to-r from-blue-500 to-teal-500 text-white'>
                                        <tr>
                                            <th className="p-4 text-left">SKU Code</th>
                                            <th className="p-4 text-left">Product Name</th>
                                            <th className="p-4 text-left">Product Size</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredProducts.map((product, index) => (
                                            <tr key={index} className="border-b hover:bg-gray-100">
                                                <td className="p-4 font-semibold">{product.sku_code}</td>
                                                <td className="p-4">{product.name}</td>
                                                <td className="p-4">{product.size}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div>
                                {/* <h3 className="text-lg pb-4 font-semibold text-gray-700 mb-4">Complaint Types</h3> */}
                                <table className="min-w-full mt-[3.8rem] bg-white border border-gray-200 rounded-lg shadow-lg">
                                    <thead className='bg-gradient-to-r from-blue-500 to-teal-500 text-white'>
                                        <tr>
                                            <th className="p-4 text-left"> Complaint Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {complaintType && complaintType.map((type, index) => (
                                            <tr key={index} className="border-b hover:bg-gray-100">
                                                <td className="p-4 font-semibold">{type.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductUpdate;
