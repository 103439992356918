import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRmPostMasonMeetData } from '../../redux/slices/Rsm/masonMeetSlice';
import RsmNavbar from './RsmNavbar';
import PostMeetDetails from '../../component/utils/MasonList/PostMeetDetails';
import { Link, useParams } from 'react-router-dom';

const RsmPostMeetView = () => {

    const dispatch = useDispatch();
    const { postMasonData, status, error } = useSelector((state) => state.rmMasonMeet);
    const { id } = useParams();
    const [ids, setIds] = useState("");
    const [meetDetails, setMeetDetails] = useState([]);

    useEffect(() => {
        dispatch(fetchRmPostMasonMeetData());
    }, [dispatch]);

    useEffect(() => {
        if (postMasonData.length > 0) {
            const meet = postMasonData.find((meet) => meet.id === parseInt(id));
            setMeetDetails(meet);
            setIds(meet.id);
        }
    }, [postMasonData, id]);

    return (
        <>
            <RsmNavbar />
            <div className="container mx-auto pt-20 p-4">
                <div className="flex justify-between items-center mt-8 mb-8">
                    <Link to="/rsm-mason-meet">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 lg:h-10 lg:w-10 p-1 delay-200 text-blue-500 hover:text-blue-700 cursor-pointer bg-gray-300 hover:bg-gray-100 rounded-full"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </Link>
                    <h2 className="text-2xl font-bold text-blue-900  ml-10">
                        Post Meet Details
                    </h2>
                </div>
                <PostMeetDetails
                    meet={meetDetails}
                />
                <span className='flex items-center justify-center p-8'>
                    <Link to="/rsm-mason-meet">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 lg:h-10 lg:w-10 p-1 delay-200 text-blue-500 hover:text-blue-700 cursor-pointer bg-gray-300 hover:bg-gray-100 rounded-full"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </Link>
                </span>
            </div>
        </>
    )
}

export default RsmPostMeetView;