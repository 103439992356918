import ProductionNavbar from './ProductionNavbar';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from '../../component/utils/Dashboard/Dashboard';
import { getProductionComplaint } from '../../redux/slices/Production/productionComplaintSlice';

import { useEffect, useState } from 'react';

const ProductionHome = () => {

    const { productionData } = useSelector(state => state.productionComplaint);
    const dispatch = useDispatch();
    const [toggleStatus, setToggleStatus] = useState(null);


    useEffect(() => {
        dispatch(getProductionComplaint());
    }, [dispatch]);

    const handleToggleStatus = (data) => {
        setToggleStatus(data);
    };

    return (
        <>
            <ProductionNavbar
                onData={handleToggleStatus}
            />
            <Dashboard
                countSlice={productionData}
                complaintLink={'production-Complaint'}
                toggleStatus={toggleStatus}
            />
        </>
    );
}

export default ProductionHome;
