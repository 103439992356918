import React, { useEffect, useState } from 'react';
import TsmNavbar from './TsmNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { getTsmComplaint } from '../../redux/slices/Tsm/tsmcomplaintslice';
import Dashboard from '../../component/utils/Dashboard/Dashboard';

const TsmHome = () => {
    const { tsmData } = useSelector(state => state.tsmComplaint);
    const dispatch = useDispatch();
    const [toggleStatus, setToggleStatus] = useState(null);

    const handleToggleStatus = (data) => {
        setToggleStatus(data);
    };
    useEffect(() => {
        dispatch(getTsmComplaint());
    }, [dispatch]);

    return (
        <>
            <TsmNavbar
                onData={handleToggleStatus}
            />
            <Dashboard
                countSlice={tsmData}
                complaintLink={'tsm-complaint'}
                toggleStatus={toggleStatus}
            />
        </>
    );
}

export default TsmHome;
