import React, { useEffect } from 'react';
import RsmNavbar from './RsmNavbar';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRmMasonMeetData, fetchRmPostMasonMeetData, updateRsmMeet } from '../../redux/slices/Rsm/masonMeetSlice';
import MasonMeetList from '../../component/utils/MasonList/MasonMeetList';
import { Link } from 'react-router-dom';

const MasonMeet = () => {

    const dispatch = useDispatch();
    const { data, status, error, postMasonData, postStatus, updateStatus } = useSelector((state) => state.rmMasonMeet);

    useEffect(() => {
        dispatch(fetchRmMasonMeetData());
        dispatch(fetchRmPostMasonMeetData());

    }, [dispatch]);

    return (
        <>
            <RsmNavbar />
            <MasonMeetList
                fetchData={data}
                postData={postMasonData}
                fetchStatus={status}
                error={error}
                view={'rsm-meet-details'}
                postView={'rsm-postmeet-details'}
                editThunk={updateRsmMeet}
                updateStatus={updateStatus}
            />
        </>
    )
}

export default MasonMeet