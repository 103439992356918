import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComplaints } from '../../redux/slices/SalesSlice/salesComplaintSlice';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Loader from '../loader/Loader';
import DownloadExcel from '../excel/DownloadExcel';
import Filters from '../../component/utils/filter/Fiilter';

const ComplaintSO = () => {
    const dispatch = useDispatch();
    const { complaints, loading, error } = useSelector((state) => state.complaintList);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedComplaint, setSelectedComplaint] = useState(null);
    const [status, setStatus] = useState('');
    const [comment, setComment] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [grievanceFilter, setGrievanceFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');

    const filteredComplaints = complaints.filter((complaint) => {
        if (grievanceFilter && complaint.type !== grievanceFilter) return false;
        if (statusFilter && complaint.status !== statusFilter) return false;
        return (
            (complaint.invoice_no && complaint.invoice_no.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (complaint.distributor_name && complaint.distributor_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (complaint.username && complaint.username.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (complaint.dealer_name && complaint.dealer_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (complaint.complaint_no && complaint.complaint_no.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (complaint.status && complaint.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (complaint.type && complaint.type.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (complaint.invoice_amount && complaint.invoice_amount.toString().includes(searchTerm.replace(/[^\d.-]/g, ''))) ||
            (complaint.customer_name && complaint.customer_name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    });
    const totalPages = Math.ceil(filteredComplaints.length / pageSize);
    const paginatedComplaints = filteredComplaints.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePageSizeChange = (e) => {
        setPageSize(Number(e.target.value));
        setCurrentPage(1);
    };

    useEffect(() => {
        dispatch(fetchComplaints());
    }, [dispatch]);

    return (
        <>
            <Navbar />
            {loading === true && (
                <Loader />
            )}
            <div className="container mt-20 mx-auto p-4">
                <div className="flex flex-col md:flex-row items-center justify-between w-full mb-6">
                    <input
                        type="search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search complaints..."
                        className="border border-gray-300 rounded-lg p-3 w-full md:w-[50rem] lg:w-[37rem] xl:w-[40rem] text-sm md:text-base lg:text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
                    />
                    <div className="flex gap-32 lg:gap-0 md:gap-0 md:ml-3 space-x-4 md:space-x-6 mt-4 md:mt-0">
                        <Link to="/register-complain">
                            <button className="bg-[#28205E] text-white py-3 px-6 rounded-lg shadow-md transition-colors duration-300 hover:bg-red-500 hover:text-white text-sm font-semibold tracking-wide border-0 cursor-pointer">
                                Register
                            </button>
                        </Link>
                        <Link onClick={() => DownloadExcel('download-table', 'complaints.xlsx')}>
                            <button className="bg-[#28205E] text-white py-3 px-6 rounded-lg shadow-md transition-colors duration-300 hover:bg-red-500 hover:text-white text-sm font-semibold tracking-wide border-0 cursor-pointer">
                                Download
                            </button>
                        </Link>
                    </div>
                </div>

                {/* Pagination Controls/ extra filters */}
                <div className="flex items-center justify-between gap-2 mb-4">
                    <div className="flex gap-4">
                        <div>
                            <label className="block text-sm font-bold mb-2">Show:</label>
                            <select
                                id="pageSize"
                                value={pageSize}
                                onChange={handlePageSizeChange}
                                className="border border-gray-300 rounded-lg py-2 px-4"
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <Filters
                            grievanceFilter={grievanceFilter}
                            statusFilter={statusFilter}
                            handleGrievanceChange={(e) => setGrievanceFilter(e.target.value)}
                            handleStatusChange={(e) => setStatusFilter(e.target.value)}
                        />
                    </div>
                    <div className="text-right text-sm">
                        Page {currentPage} of {totalPages}
                    </div>
                </div>

                {/* Mobile View */}
                <div className="lg:hidden flex flex-wrap -mx-4">
                    {paginatedComplaints.map((complaint) => (
                        <div key={complaint.id} className="w-full md:w-1/2 xl:w-1/3 p-4">
                            <div className="bg-white p-8 border border-slate-300 rounded-lg shadow-md">
                                <h3 className="text-2xl font-bold mb-4">Complaint #{complaint.id}</h3>
                                <p className="text-sm font-bold mb-2">Complaint No.: {complaint.complaint_no}</p>
                                <p className="text-sm font-bold mb-2">Complaint By: {complaint.username}</p>
                                <p className="text-sm font-bold mb-2">Complaint Date: {complaint.invoice_date.split('-').reverse().join('/')}</p>
                                <p className="text-sm font-bold mb-2">Grievance Type: {complaint.type}</p>
                                <p className="text-sm font-bold mb-2">Distributor Name: {complaint.distributor_name}</p>
                                <p className="text-sm font-bold mb-2">Dealer Name: {complaint.dealer_name}</p>
                                <p className="text-sm font-bold mb-2">Customer Name: {complaint.customer_name}</p>
                                <p className="text-sm font-bold mb-2">Amount: {complaint.invoice_amount}</p>
                                <p className="text-sm font-bold mb-2">Action By: {complaint.approved_name}</p>
                                <p className="text-sm font-bold mb-2">Status:
                                    <span
                                        className={`inline-block py-1 px-3 rounded-full text-xs font-medium
                                            ${complaint.status === "open"
                                                ? "bg-blue-200 text-blue-800"
                                                : complaint.status === "close"
                                                    ? "bg-green-200 text-green-800"
                                                    : complaint.status === "reject"
                                                        ? "bg-red-200 text-red-800"
                                                        : "bg-gray-200 text-gray-800"
                                            }`}
                                    >
                                        {complaint.status}
                                    </span>
                                </p>
                                <div className="flex gap-2">
                                    <Link to={`/sales/complaint/${complaint.id}`}>
                                        <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                            View
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Desktop View */}
                <div className="hidden lg:block">
                    <table
                        id="download-table"
                        className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
                        <thead className='bg-gradient-to-r from-blue-500 to-teal-500 text-white'>
                            <tr>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Sr. No.</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Complaint No.</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Complaint By</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Complaint Date</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Grievance Type</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Distributor Name</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Dealer Name</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Customer Name</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Amount</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Action By</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Status</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {paginatedComplaints.map((complaint, index) => (
                                <tr key={complaint.id} className="hover:bg-gray-100 transition-all duration-300 ease-in-out">
                                    <td className="px-6 py-4 text-left">{(currentPage - 1) * pageSize + index + 1}</td>
                                    <td className="px-6 py-4 text-left">{complaint.complaint_no}</td>
                                    <td className="px-6 py-4 text-left">{complaint.username}</td>
                                    <td className="px-6 py-4 text-left">{complaint.invoice_date.split('-').reverse().join('/')}</td>
                                    <td className="px-6 py-4 text-left">{complaint.type}</td>
                                    <td className="px-6 py-4 text-left">{complaint.distributor_name}</td>
                                    <td className="px-6 py-4 text-left">{complaint.dealer_name}</td>
                                    <td className="px-6 py-4 text-left">{complaint.customer_name}</td>
                                    <td className="px-6 py-4 text-left">{complaint.invoice_amount}</td>
                                    <td className="px-6 py-4 text-left">{complaint.approved_name}</td>
                                    <td className="px-6 py-4 text-left">
                                        <span
                                            className={`inline-block py-1 px-3 rounded-full text-xs font-medium
                                                ${complaint.status === "open"
                                                    ? "bg-blue-200 text-blue-800"
                                                    : complaint.status === "close"
                                                        ? "bg-green-200 text-green-800"
                                                        : complaint.status === "reject"
                                                            ? "bg-red-200 text-red-800"
                                                            : "bg-gray-200 text-gray-800"
                                                }`}
                                        >
                                            {complaint.status}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 text-left">
                                        <Link to={`/sales/complaint/${complaint.id}`}>
                                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                View
                                            </button>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls for Mobile and Desktop */}
                <div className=" flex justify-between items-center mt-4">
                    {/* Mobile Pagination Controls */}
                    <div className=" lg:hidden flex items-center justify-center gap-2 p-2 w-full">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Prev
                        </button>
                        <div className="flex gap-1 overflow-x-auto">
                            {Array.from({ length: totalPages }, (_, i) => (
                                <button
                                    key={i + 1}
                                    onClick={() => handlePageChange(i + 1)}
                                    className={`px-3 py-1 rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'} focus:outline-none`}
                                >
                                    {i + 1}
                                </button>
                            ))}
                        </div>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Next
                        </button>
                    </div>


                    {/* Desktop Pagination Controls */}
                    <div className="hidden lg:flex items-center justify-between w-full">
                        {/* Previous Button */}
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l disabled:opacity-50"
                        >
                            Previous
                        </button>

                        {/* Pagination Numbers */}
                        <div className="flex flex-grow justify-center mx-4">
                            {Array.from({ length: totalPages }, (_, i) => (
                                <button
                                    key={i + 1}
                                    onClick={() => handlePageChange(i + 1)}
                                    className={`px-3 py-2 rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                                >
                                    {i + 1}
                                </button>
                            ))}
                        </div>

                        {/* Next Button */}
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r disabled:opacity-50"
                        >
                            Next
                        </button>
                    </div>

                </div>
            </div>


        </>
    );
};

export default ComplaintSO;
