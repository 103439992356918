import React, { useEffect } from 'react';
import AccountNavbar from './AccountNavbar';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAcctMasonMeetData, fetchAcctPostMasonMeetData, updateAcctMeet } from '../../redux/slices/Account/masonMeetSlice';
import MasonMeetList from '../../component/utils/MasonList/MasonMeetList';
import { Link } from 'react-router-dom';

const AcctMasonMeet = () => {

    const dispatch = useDispatch();
    const { data, status, error, postMasonData, postStatus, updateStatus } = useSelector((state) => state.acctMasonMeet);

    useEffect(() => {
        dispatch(fetchAcctMasonMeetData());
        dispatch(fetchAcctPostMasonMeetData());

    }, [dispatch]);

    return (
        <>
            <AccountNavbar />
            <MasonMeetList
                fetchData={data}
                postData={postMasonData}
                fetchStatus={status}
                error={error}
                view={'acct-meet-details'}
                postView={'acct-postmeet-details'}
                editThunk={updateAcctMeet}
                updateStatus={updateStatus}

            />
        </>
    )
}

export default AcctMasonMeet