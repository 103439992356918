import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Edit from "./ViewEdit/Edit";
import Notification from "../../../pages/notification/Notification";
import Loader from "../../../pages/loader/Loader";

const MeetDetails = ({
    meet,
    statusOptions,
    editThunk,
    fetchThunk,
    updateStatus,
    backTo,
    editting = true
}) => {

    const [editt, setEdit] = useState(false);
    const [notification, setNotification] = useState({ message: '', type: '' });



    useEffect(() => {
        if (updateStatus === 'succeeded') {
            setTimeout(() => {
                setEdit(false);
            }, 1000);
        }

        if (updateStatus === 'succeeded' && editt === true) {
            setNotification({ message: 'Complaint updated successfully!', type: 'success' });
            setEdit(false);
        } else if (updateStatus === 'failed' && editt === true) {
            setNotification({ message: 'Failed to update. Try again later!', type: 'error' });
        }
    }, [updateStatus]);

    const roles = {
        2: "Account Head",
        3: "Production Head",
        4: "Sales Head",
        5: "Dispatch Head",
        6: "RSM",
        10: "Director",
        11: "Billing",
    };
    function parseDate(dateString) {
        if (!dateString) return null;
        const parts = dateString.split('-');
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return new Date(`${month}/${day}/${year}`);
    }

    const getCommentRows = () => {

        return [
            {
                role_id: 6,
                comment: meet.rsmcomment,
                date: meet.rsmcommentdate,
            },
            {
                role_id: 4,
                comment: meet.salescomment,
                date: meet.salescommentdate,
            },
            {
                role_id: 10,
                comment: meet.directorcomment,
                date: meet.directorcommentdate,
            },
            {
                role_id: 2,
                comment: meet.accountcomment,
                date: meet.accountcommentdate,
            },
        ];
    };

    return (
        <>
            {notification.message && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification({ message: '', type: '' })}
                />
            )}
            {updateStatus === 'pending' && (
                <Loader />
            )}
            <div className="flex justify-between items-center mt-8 mb-8">
                <Link to={`/${backTo}`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8 lg:h-10 lg:w-10 p-1 delay-200 text-blue-500 hover:text-blue-700 cursor-pointer bg-gray-300 hover:bg-gray-100 rounded-full"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 19l-7-7 7-7"
                        />
                    </svg>
                </Link>
                <h2 className="text-2xl font-bold text-blue-900  ml-10">
                    Meet Details
                </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-white p-8 border-2 border-gradient rounded-lg">
                    <h3 className="text-xl font-bold mb-4">Pre Meet Information</h3>
                    <table className="w-full mb-4">
                        <tbody>
                            <tr className="border-b-2">
                                <td className="text-sm font-bold py-2">Meet No:</td>
                                <td className="text-sm py-2">{meet.id}</td>
                            </tr>
                            <tr className="border-b-2">
                                <td className="text-sm font-bold py-2">Meet Date:</td>
                                <td className="text-sm py-2">{new Date(meet.created_at).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                            </tr>
                            <tr className="border-b-2">
                                <td className="text-sm font-bold py-2">Dealer Name:</td>
                                <td className="text-sm py-2">{meet.dealerName}</td>
                            </tr>
                            <tr className="border-b-2">
                                <td className="text-sm font-bold py-2">Remarks:</td>
                                <td className="text-sm py-2">{meet.remarks}</td>
                            </tr>
                            <tr className="border-b-2">
                                <td className="text-sm font-bold py-2">Location:</td>
                                <td className="text-sm py-2">{meet.location}</td>
                            </tr>
                            <tr className="border-b-2">
                                <td className="text-sm font-bold py-2">Planned Expenses:</td>
                                <td className="text-sm py-2">{meet.planned_expenses}</td>
                            </tr>
                            <tr className="border-b-2">
                                <td className="text-sm font-bold py-2">Status:</td>
                                <td className="text-sm py-2">{meet.status}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* comments of roles   */}

                <div className="bg-white p-8 border-2 border-gradient rounded-lg shadow-md">
                    <h3 className="text-2xl font-semibold mb-6 text-gray-800">Comments</h3>
                    <div className="bg-gray-50 p-6  rounded-lg ">
                        {getCommentRows().length > 0 ? (
                            <div className="relative border-l border-gray-300 pl-6">
                                {getCommentRows().map((comment, index) => (
                                    <div key={index} className="mb-8">
                                        <div className="absolute -left-4 w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center shadow-md">
                                            <span className="text-xs font-bold">{roles[comment.role_id]?.[0] || "?"}</span>
                                        </div>
                                        <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200 relative">
                                            <div className="flex justify-between items-center mb-2">
                                                <p className="text-sm font-semibold text-gray-900">{roles[comment.role_id]}</p>

                                                <p className="text-xs text-gray-500 text-right">
                                                    {comment.comment && comment.date ? parseDate(comment.date).toLocaleDateString() : "pending"}
                                                </p>
                                            </div>
                                            <p className="text-sm text-gray-600">{comment.comment}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-sm text-gray-500">No comments available.</p>
                        )}
                    </div>
                </div>
            </div>

            {editt === true && (<Edit
                statusOptions={statusOptions}
                editThunk={editThunk}
                fetchThunk={fetchThunk}
                updateStatus={updateStatus}
                id={meet.id}
            />
            )}
            <span className='flex items-center justify-center p-8 gap-7'>
                <Link to={`/${backTo}`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8 lg:h-10 lg:w-10 p-1 delay-200 text-blue-500 hover:text-blue-700 cursor-pointer bg-gray-300 hover:bg-gray-100 rounded-full"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M15 19l-7-7 7-7"
                        />
                    </svg>
                </Link>
                {editting && (
                    <button
                        className={`${editt ? "bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300 shadow-md hover:shadow-lg" : "bg-gradient-to-r from-blue-400 to-teal-400 hover:from-blue-500 hover:to-teal-500 text-white font-bold py-2 px-4 rounded transition-colors duration-300 shadow-md hover:shadow-lg"} ${meet.edit === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                        onClick={() => setEdit((prev) => !prev)}
                        disabled={meet.edit === 1}
                    >
                        {editt ? "Cancel" : "Edit"}
                    </button>


                )}
            </span>
        </>
    );
};

export default MeetDetails;
