import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrl } from '../apiConfig';
const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};
export const registerUser = createAsyncThunk(
    'users/registerUser',
    async (userData, { rejectWithValue }) => {
        try {

            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/sign-up`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            return await response.json();
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
//2
export const getUser = createAsyncThunk(
    'users/getUser',
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                // body: JSON.stringify(userData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            return await response.json();
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

//3 
export const updateUser = createAsyncThunk(
    'users/updateUser',
    async (userData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/user/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            return await response.json();
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while updating user.' });
        }
    }
);

//end

const userSlice = createSlice({
    name: 'users',
    initialState: {
        isLoading: false,
        isError: false,
        status: '',
        user: {},
        error: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(registerUser.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.status = 'loading';
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;
                state.status = 'succeeded';
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.payload;
                state.status = 'failed';
            })


            //2
            .addCase(getUser.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.status = 'loading';
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload.data;
                state.status = 'succeeded';
            })
            .addCase(getUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.payload;
                state.status = 'failed';
            })
            // Update
            .addCase(updateUser.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.status = 'loading';
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;
                state.status = 'succeeded';
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.payload;
                state.status = 'failed';
            });
    },
});

export default userSlice.reducer;
