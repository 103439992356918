import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../redux/slices/admin/registerUserSlice';
import { useNavigate } from 'react-router-dom';
import { fetchSuggestions, fetchComplaintType } from '../../../redux/slices/FormProductSuggestion/suggestionsSlice'
import { fetchProduct } from '../../../redux/slices/admin/productUpdateSlice';
import Select from 'react-select';
import Notification from '../../../pages/notification/Notification';

const formFieldsConfig = {
    billing: [
        { label: 'Customer Name ', key: 'customer_name', type: 'text' },
        { label: 'Invoice Date *', key: 'invoice_date', type: 'date' },
        { label: 'Complaint Amount *', key: 'invoice_amount', type: 'number' },
        { label: 'Invoice Number *', key: 'invoice_no', type: 'text' },
    ],
    dispatch: [
        { label: 'Distributor Name *', key: 'distributor_name', type: 'text' },
        { label: 'Dealer Name *', key: 'dealer_name', type: 'text' },
        { label: 'Customer Name ', key: 'customer_name', type: 'text' },
        { label: 'Vehicle Number *', key: 'vehicle_number', type: 'text' },
        { label: 'Product Name *', key: 'product_name', type: 'text' },
        { label: 'Complaint Quantity in box *', key: 'complaint_qty_box', type: 'number' },
        { label: 'Product Size *', key: 'product_size', type: 'select', options: ['600 x 600 mm', '400 x 400 mm'] },
        { label: 'Invoice Date *', key: 'invoice_date', type: 'date' },
        { label: 'Invoice Number *', key: 'invoice_no', type: 'text' },
        { label: 'Complaint Amount *', key: 'invoice_amount', type: 'number' },
    ],
    product: [
        { label: 'Distributor Name *', key: 'distributor_name', type: 'text' },
        { label: 'Distributor Phone *', key: 'distributor_phone_no', type: 'tel', pattern: "[0-9]{10}", title: "Please enter a 10-digit phone number" },
        { label: 'Dealer Name *', key: 'dealer_name', type: 'text' },
        { label: 'Dealer Phone *', key: 'dealer_phone_no', type: 'tel', pattern: "[0-9]{10}", title: "Please enter a 10-digit phone number" },
        { label: 'Customer Name ', key: 'customer_name', type: 'text' },
        { label: 'Customer Phone ', key: 'customer_phone_no', type: 'tel', pattern: "[0-9]{10}", title: "Please enter a 10-digit phone number" },
        { label: 'Customer Address ', key: 'customer_address', type: 'textarea' },
        { label: 'Products', type: 'add_products' },
        // { label: 'Complaint Quantity in box *', key: 'complaint_qty_box', type: 'number' },
        // { label: 'Complaint Type *', key: 'complaint_type', type: 'select', options: ['Crazing', 'Shade Variation', 'Breakage', 'Other'] },
        { label: 'Invoice Number *', key: 'invoice_no', type: 'text' },
        { label: 'Invoice Date *', key: 'invoice_date', type: 'date' },
        { label: 'Complaint Amount *', key: 'invoice_amount', type: 'number' },
        { label: 'Batch Number *', key: 'batch_no', type: 'text' },
    ]

};

const RegisterComplaint = ({ registerComplaint, complaintStatus, complaintError, backToComplaint }) => {
    const initialState = {
        grievance_type: '',
        distributor_name: '',
        distributor_phone_no: '',
        dealer_name: '',
        dealer_phone_no: '',
        customer_name: '',
        customer_phone_no: '',
        customer_address: '',
        product_name: '',
        product_size: '',
        complaint_qty_box: '',
        complaint_type: '',
        complaint_remarks: '',
        invoice_no: '',
        invoice_date: '',
        invoice_amount: '',
        batch_no: '',
        tag: '',
        vehicle_number: '',
        product: []
    };




    const [grievanceType, setGrievanceType] = useState('');
    const [errors, setErrors] = useState({});
    const [uploadImages, setUploadImages] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    const { user } = useSelector(state => state.users);
    const { suggestion, complaintType } = useSelector(state => state.suggestions);
    const { products, loading, error, updateStatus } = useSelector((state) => state.productList);
    const [tagList, setTagList] = useState([]);
    const [table, setTable] = useState(false);
    const [complaint, setComplaint] = useState(initialState);
    const [skuOption, setSkuOption] = useState([]);
    const [productNameOptions, setProductNameOption] = useState([]);
    const [sizeOption, setSizeOption] = useState([]);
    const [typeComplaint, setTypeComplaint] = useState([]);
    const [filteredName, setFilteredName] = useState('');
    const [filteredSize, setFilteredSize] = useState('');

    const [rows, setRows] = useState([
        { sku_code: "", name: "", size: "", complaint_type: "", complaint_qty_box: "" },
    ]);

    const addRow = () => {
        setRows([
            ...rows,
            { sku_code: "", name: "", size: "", complaint_type: "", complaint_qty_box: "" },
        ]);
    };

    const deleteRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const handleRowChange = (value, index, field, name) => {
        const updatedRows = [...rows];
        if (field in updatedRows[index]) {
            updatedRows[index][field] = value;
        } else if (name in updatedRows[index]) {
            updatedRows[index][name] = value;
        }


        if (field === 'sku_code') {
            const filteredProduct = products.find((item) => item.sku_code === value);
            if (filteredProduct) {
                updatedRows[index].name = filteredProduct.name;
                updatedRows[index].size = filteredProduct.size;
                setErrors((prevErrors) => ({ ...prevErrors, skuCodeError: null }));
            }
        } else if (field === 'name') {
            const filteredProduct = products.find((item) => item.name === value);
            if (filteredProduct) {
                updatedRows[index].sku_code = filteredProduct.sku_code;
                updatedRows[index].size = filteredProduct.size;
            }
        }

        setRows(updatedRows);
    };

    // products 

    useEffect(() => {
        dispatch(fetchProduct());
        if (products.length > 0) {
            const skuOptions = products.map((item) => (item.sku_code));
            const sizeOptions = products.map((item) => (item.size));
            const productNameOptions = products.map((item) => (item.name));

            setSkuOption(skuOptions);
            setSizeOption(sizeOptions);
            setProductNameOption(productNameOptions);
        }
    }, [table, dispatch]);

    const handleProductCancel = () => {
        setRows([{ sku_code: "", name: "", size: "", complaint_type: "", complaint_qty_box: "" }]);
        setTable(false);
    }

    useEffect(() => {
        setComplaint({ ...complaint, product: rows });
    }, [rows]);


    const handleSave = () => {
        if (rows.some((row) => row.sku_code === "")) {
            setErrors({ skuCodeError: "Product row can't be empty either fill it or remove it" });
            return;
        } else {
            setErrors({ skuCodeError: null })
            setTable(false);
        }
        // rest of the code
    }

    // tag list
    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getUser());
        dispatch(fetchComplaintType());
        let typeComplaintArray = [];
        complaintType?.forEach((item) => {
            typeComplaintArray.push(item.name);
        })
        setTypeComplaint(typeComplaintArray);

    }, [table, dispatch]);

    useEffect(() => {
        dispatch(getUser()).then((result) => {
            if (result.meta.requestStatus === 'fulfilled') {
                setTagList(result.payload.data);
            }
        });
    }, [dispatch]);

    const tagOptions = useMemo(() => {
        const filteredUsers = tagList.filter(user => Number(user.role_id) === 8 || Number(user.role_id) === 9);
        return filteredUsers.map(user => (
            <option key={user.id} value={user.id}>{user.name}</option>
        ));
    }, [tagList]);

    const handleSubmit = async (e) => {
        e.preventDefault();


        let validationErrors = {};

        if (grievanceType === 'billing') {
            if (!complaint.invoice_no) {
                validationErrors.invoice_no = '*';
            }

            if (!complaint.invoice_date) {
                validationErrors.invoice_date = '*';
            }
            if (!complaint.invoice_amount) {
                validationErrors.invoice_amount = '*';
            }
            if (!complaint.customer_name) {
                validationErrors.customer_name = '*';
            }
        }

        if (grievanceType === 'dispatch') {
            if (!complaint.invoice_no) {
                validationErrors.invoice_no = '*';
            }
            if (!complaint.invoice_date) {
                validationErrors.invoice_date = '*';
            }
            if (!complaint.vehicle_number) {
                validationErrors.vehicle_number = '*';
            }
            if (!complaint.product_name) {
                validationErrors.product_name = '*';
            }
            if (!complaint.complaint_qty_box) {
                validationErrors.complaint_qty_box = '*';
            }
            if (!complaint.product_size) {
                validationErrors.product_size = '*';
            }
            if (!complaint.invoice_amount) {
                validationErrors.invoice_amount = '*';
            }

            if (!complaint.dealer_name && !complaint.distributor_name) {
                validationErrors.dealer_name = 'At least one of Dealer name or Distributor name is required.';
            }

        }

        if (grievanceType === 'product') {
            if (!complaint.invoice_no) {
                validationErrors.invoice_no = '*';
            }
            // if (!complaint.product_name) {
            //     validationErrors.product_name = '*';
            // }
            // if (!complaint.product_size) {
            //     validationErrors.product_size = '*';
            // }

            // if (!complaint.complaint_qty_box) {
            //     validationErrors.complaint_qty_box = '*';
            // }
            // if (!complaint.complaint_type) {
            //     validationErrors.complaint_type = '*';
            // }

            if (!complaint.invoice_date) {
                validationErrors.invoice_date = '*';
            }
            if (!complaint.dealer_name && !complaint.distributor_name) {
                validationErrors.dealer_name = 'At least one of Dealer name or Distributor name is required.';
            }

            const phoneNumberPattern = /^[0-9]{10}$/;

            if ((!complaint.dealer_phone_no || !phoneNumberPattern.test(complaint.dealer_phone_no)) && (!complaint.distributor_phone_no || !phoneNumberPattern.test(complaint.distributor_phone_no))) {
                validationErrors.dealer_phone_no = 'At least one of distributor or dealer valid 10-digit phone number is required.';
            }
        }

        if (!complaint.complaint_remarks) {
            validationErrors.complaint_remarks = '*';
        }

        if (!complaint.tag) {
            validationErrors.tag = '*';
        }

        if (uploadImages.length > 10) {
            validationErrors.images = 'Please select  only 10 images.';
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            await dispatch(registerComplaint({ complaint, grievanceType, images: uploadImages })).unwrap();
            setSuccessMessage('Complaint registered successfully!');
        } catch (error) {
            setSuccessMessage('');
            setErrors({ apiError: 'Failed to register complaint. Please try again.' });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setComplaint({ ...complaint, [name]: value });
    };

    const handleCancel = () => {
        setComplaint(initialState);
        setUploadImages([]);
        navigate(-1);
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);

        if (files.length > 10) {
            setErrors({ ...errors, images: 'You can only upload a maximum of 10 images.' });
        } else {
            setUploadImages(files);
            setErrors({ ...errors, images: null });
        }
    };

    return (
        <>

            {table && (
                <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-2xl lg:w-[75rem] h-[30rem]  max-h-96 overflow-auto p-6 mt-20">
                        {errors.skuCodeError && (
                            <Notification message={errors.skuCodeError} />
                        )}
                        <table className="table-auto w-full border-collapse">

                            <thead className="bg-gradient-to-r from-blue-500 to-blue-300 text-white">
                                <tr>
                                    <th className="px-4 py-3">SKU Code</th>
                                    <th className="px-4 py-3">Product Name</th>
                                    <th className="px-4 py-3">Product Size</th>
                                    <th className="px-4 py-3">Complaint Type</th>
                                    <th className="px-4 py-3">Quantity (in box)</th>
                                    <th className="px-4 py-3 text-center">Action</th>
                                </tr>
                            </thead>

                            <tbody className="bg-white divide-y divide-gray-200">
                                {rows.map((row, index) => (
                                    <tr key={`${row.sku_code}${row.name}`} className="hover:bg-gray-100 transition duration-300">
                                        <td className="px-4 py-3">
                                            <Select
                                                options={skuOption.map(sku => ({ value: sku, label: sku }))}
                                                value={{ value: row.sku_code, label: row.sku_code }}
                                                onChange={(selectedOption) => handleRowChange(selectedOption.value, index, 'sku_code')}
                                                className="w-[11rem]"
                                                styles={{
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999,
                                                    }),
                                                    menuList: (provided) => ({
                                                        ...provided,
                                                        maxHeight: '200px',
                                                        overflowY: 'auto',
                                                        '::-webkit-scrollbar': {
                                                            width: '0px',
                                                            background: 'transparent',
                                                        },
                                                        scrollbarWidth: 'none',
                                                        '-msOverflowStyle': 'none',
                                                    }),
                                                }}
                                            />


                                        </td>
                                        <td className="px-4 py-3">
                                            <Select
                                                options={productNameOptions.map(name => ({ value: name, label: name }))}
                                                value={{ value: row.name, label: row.name }}
                                                onChange={(selectedOption) => handleRowChange(selectedOption.value, index, 'name')}
                                                className="w-[16.5rem]"
                                                placeholder={"Enter product name"}
                                                styles={{
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999,
                                                    }),
                                                    menuList: (provided) => ({
                                                        ...provided,
                                                        maxHeight: '200px',
                                                        overflowY: 'auto',
                                                        '::-webkit-scrollbar': {
                                                            width: '0px',
                                                            background: 'transparent',
                                                        },
                                                        scrollbarWidth: 'none',
                                                        '-msOverflowStyle': 'none',
                                                    }),
                                                }}
                                            />
                                        </td>
                                        <td className="px-4 py-3">
                                            <Select
                                                options={sizeOption.map(size => ({ value: size, label: size }))}
                                                value={{ value: row.size, label: row.size }}
                                                onChange={(selectedOption) => handleRowChange(selectedOption.value, index, 'size')}
                                                className="w-[11rem]"
                                                styles={{
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999,
                                                    }),
                                                    menuList: (provided) => ({
                                                        ...provided,
                                                        maxHeight: '200px',
                                                        overflowY: 'auto',
                                                        '::-webkit-scrollbar': {
                                                            width: '0px',
                                                            background: 'transparent',
                                                        },
                                                        scrollbarWidth: 'none',
                                                        '-msOverflowStyle': 'none',
                                                    }),
                                                }}
                                            />
                                        </td>
                                        <td className="px-4 py-3">
                                            <Select

                                                options={typeComplaint.map(type => ({ value: type, label: type }))}
                                                value={{ value: row.complaint_type, label: row.complaint_type }}
                                                onChange={(selectedOption) => handleRowChange(selectedOption.value, index, 'complaint_type')}
                                                className="w-[12rem]"
                                                styles={{
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999,
                                                    }),
                                                    menuList: (provided) => ({
                                                        ...provided,
                                                        maxHeight: '200px',
                                                        overflowY: 'auto',
                                                        '::-webkit-scrollbar': {
                                                            width: '0px',
                                                            background: 'transparent',
                                                        },
                                                        scrollbarWidth: 'none',
                                                        '-msOverflowStyle': 'none',
                                                    }),
                                                }}
                                            />
                                        </td>
                                        <td className="px-4 py-3">
                                            <input
                                                type="number"
                                                name="complaint_qty_box"
                                                value={row.complaint_qty_box || ''}
                                                onChange={(e) => {
                                                    handleRowChange(e.target.value, index, 'complaint_qty_box');
                                                }}
                                                className="w-[6rem] p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-400"
                                            />


                                        </td>
                                        <td className="px-4 py-3 text-center">
                                            {rows.length > 1 && (

                                                <button
                                                    onClick={() => deleteRow(index)}
                                                    className="text-red-500 hover:text-red-700 transition transform hover:scale-110"
                                                >
                                                    ✕
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button
                            onClick={addRow}
                            className="py-2 px-4 bg-green-500 text-white font-semibold rounded-lg mt-4 hover:bg-green-600 transition duration-300"
                        >
                            Add Row
                        </button>
                    </div>
                    <span className='flex items-center justify-center'>
                        <button
                            type="button"
                            onClick={handleSave}
                            className="py-2 px-4 float-right m-4 text-xl bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            onClick={handleProductCancel}
                            className="py-2 px-4 float-right m-4 text-xl bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
                        >
                            Cancel
                        </button>
                    </span>
                </div>
            )}

            <div className="max-w-xl md:max-w-2xl lg:max-w-4xl mt-20 mx-auto p-4 md:p-6 lg:p-8 bg-white shadow-lg rounded-lg">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-blue-900 mb-8 md:mb-10 lg:mb-12 leading-tight">
                        Register Complaint
                    </h2>
                </div>

                {successMessage && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                        <div className="bg-white p-6 rounded-md shadow-lg max-w-sm w-full mx-4 text-center">
                            <h3 className="text-lg font-medium text-gray-800 mb-4">{successMessage}</h3>
                            <button
                                onClick={() => {
                                    setSuccessMessage(false);
                                    navigate(`/${backToComplaint}`);

                                }}
                                className="bg-blue-500 text-white py-2 px-6 rounded-md font-semibold hover:bg-blue-600 transition-colors duration-150"
                            >
                                OK
                            </button>
                        </div>
                    </div>
                )}

                {complaintStatus === 'loading' && (
                    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
                        <div className="flex space-x-2">
                            <div className="w-6 h-6 bg-blue-500 rounded-full animate-bounce animate-delay-0"></div>
                            <div className="w-6 h-6 bg-green-500 rounded-full animate-bounce animate-delay-200"></div>
                            <div className="w-6 h-6 bg-red-500 rounded-full animate-bounce animate-delay-400"></div>
                            {/* <div className="w-4 h-4 bg-yellow-500 rounded-full animate-bounce animate-delay-600"></div> */}
                        </div>
                    </div>
                )}

                {errors.apiError && (
                    <div className="mb-6 p-4 bg-red-100 text-red-800 border border-red-300 rounded-lg">
                        <p>{errors.apiError}</p>
                    </div>
                )}



                <form className="space-y-4" onSubmit={handleSubmit}>
                    <div className="flex flex-col md:flex-row md:items-center md:gap-4">
                        <label className="text-sm md:text-base font-medium text-gray-700 md:w-1/3">Grievance Type:</label>
                        <select
                            name="grievance_type"
                            value={grievanceType}
                            onChange={(e) => {
                                setGrievanceType(e.target.value);
                                setComplaint({ ...complaint, grievance_type: e.target.value });
                            }}
                            className={`border ${errors.grievance_type ? 'border-red-500' : 'border-gray-300'} p-2 rounded-lg focus:outline-none focus:border-blue-500 w-full md:w-2/3`}
                        >
                            <option value="">Select Grievance Type</option>
                            <option value="billing">Billing</option>
                            <option value="dispatch">Dispatch</option>
                            <option value="product">Product</option>
                        </select>
                        {errors.grievance_type && <p className="text-red-500 text-sm mt-1">{errors.grievance_type}</p>}
                    </div>

                    {grievanceType && formFieldsConfig[grievanceType.toLowerCase()].map(({ label, key, type, options }) => (
                        <div className="flex flex-col md:flex-row md:items-center md:gap-4" key={`${label}${key}`}>
                            <label className="text-sm md:text-base font-medium text-gray-700 md:w-1/3">{label}:</label>
                            {type === 'textarea' ? (
                                <textarea
                                    name={key}
                                    value={complaint[key] || ''}
                                    onChange={handleChange}
                                    className={`border ${errors[key] ? 'border-red-500' : 'border-gray-300'} p-2 rounded-lg focus:outline-none focus:border-blue-500 w-full md:w-2/3`}
                                />
                            ) : type === 'select' ? (
                                <select
                                    name={key}
                                    value={complaint[key] || ''}
                                    onChange={handleChange}
                                    className={`border ${errors[key] ? 'border-red-500' : 'border-gray-300'} p-2 rounded-lg focus:outline-none focus:border-blue-500 w-full md:w-2/3`}
                                >
                                    <option value="">Select</option>
                                    {options.map((option) => (
                                        <option key={option} value={option}>{option}</option>
                                    ))}
                                </select>
                            ) : type === 'add_products' ? (
                                <span className='flex items-center bg-gray-100 justify-center rounded-lg'>
                                    <button
                                        type='button'
                                        onClick={() => setTable(true)}
                                        className='py-2 px-4 float-right m-3 bg-green-600 text-white rounded'
                                    >
                                        ADD PRODUCTS
                                    </button>
                                    {rows[0].sku_code !== '' ? (
                                        <div class="flex items-end m-3  p-3 rounded-md mb-4">
                                            <span class="bg-green-300 text-white px-2  rounded-full">{rows.length}</span>
                                            <p class="text-sm font-bold text-gray-800 ml-2">Product{rows.length > 1 ? 's' : ''} Added</p>
                                        </div>) : null}
                                </span>
                            ) : (
                                <input
                                    name={key}
                                    type={type}
                                    value={complaint[key] || ''}
                                    onChange={handleChange}
                                    className={`border ${errors[key] ? 'border-red-500' : 'border-gray-300'} p-2 rounded-lg focus:outline-none focus:border-blue-500 w-full md:w-2/3`}
                                    {...(type === 'tel' && { pattern: "[0-9]{10}", title: "Please enter a 10-digit phone number" })}
                                />
                            )}



                            {errors[key] && <p className="text-red-500 text-sm mt-1">{errors[key]}</p>}
                        </div>
                    ))}
                    <div className="flex flex-col md:flex-row md:items-center md:gap-4">
                        <label className="text-sm md:text-base font-medium text-gray-700 md:w-1/3">Complaint Remarks:</label>
                        <textarea
                            name={'complaint_remarks'}
                            value={complaint['complaint_remarks']}
                            onChange={handleChange}
                            className={`border ${errors.complaint_remarks ? 'border-red-500' : 'border-gray-300'} p-2 rounded-lg focus:outline-none focus:border-blue-500 w-full md:w-2/3`}
                        />
                        {errors.complaint_remarks && <p className="text-red-500 text-sm mt-1">{errors.complaint_remarks}</p>}
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center md:gap-4">
                        <label className="text-sm md:text-base font-medium text-gray-700 md:w-1/3">Tag:</label>
                        <select
                            id="tag"
                            name="tag"
                            value={complaint['tag']}
                            onChange={handleChange}
                            className={`border ${errors.tag ? 'border-red-500' : 'border-gray-300'} p-2 rounded-lg focus:outline-none focus:border-blue-500 w-full md:w-2/3`}
                        >
                            <option value="">Select</option>
                            {tagOptions}
                        </select>
                        {errors.tag && <p className="text-red-500 text-sm mt-1">{errors.tag}</p>}
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center md:gap-4">
                        <label className="text-sm md:text-base font-medium text-gray-700 md:w-1/3">Upload Images:</label>
                        <input
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handleFileChange}
                            className={`border ${errors.images ? 'border-red-500' : 'border-gray-300'} p-2 rounded-lg focus:outline-none focus:border-blue-500 w-full md:w-2/3`}
                        />
                        {errors.images && <p className="text-red-500 text-sm mt-1">{errors.images}</p>}
                    </div>

                    <div className="flex justify-center gap-32 pt-10 pb-6">
                        <button
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md transform transition duration-300 hover:bg-blue-700 hover:-translate-y-1 hover:shadow-lg"
                            type="submit"
                            disabled={complaintStatus === 'loading'}
                        >
                            {complaintStatus === 'loading' ? 'Submitting...' : 'Submit'}
                        </button>

                        <button
                            className="bg-red-600 text-white py-2 px-4 rounded-lg shadow-md transform transition duration-300 hover:bg-red-700 hover:-translate-y-1 hover:shadow-lg"
                            onClick={handleCancel}
                            type="button"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default RegisterComplaint;