import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSalesMasonMeetData } from '../../redux/slices/SalesSlice/salesMasonMeet';
import Navbar from './Navbar';
import MeetDetails from '../../component/utils/MasonList/MeetDetails';
import { Link, useParams } from 'react-router-dom';

const SalesMeetView = () => {

    const dispatch = useDispatch();
    const { data, status, error } = useSelector((state) => state.meetApproval);
    const { id } = useParams();
    const [ids, setIds] = useState("");
    const [meetDetails, setMeetDetails] = useState([]);

    useEffect(() => {
        dispatch(fetchSalesMasonMeetData());
    }, [dispatch]);

    useEffect(() => {
        if (data.length > 0) {
            const meet = data.find((meet) => meet.id === parseInt(id));
            setMeetDetails(meet);
            setIds(meet.id);
        }
    }, [data, id]);

    return (
        <>
            <Navbar />
            <div className="container mx-auto pt-20 p-4">

                <MeetDetails
                    meet={meetDetails}
                    backTo={'sales-mason-meet'}
                    editting={false}
                />

            </div>
        </>
    )
}

export default SalesMeetView;