import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const Edit = ({
    statusOptions,
    editThunk,
    fetchThunk,
    updateStatus,
    id
}) => {
    const [status, setStatus] = useState('');
    const [comment, setComment] = useState('');
    const dispatch = useDispatch();
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!status) {
            setError("* required");
            return;
        }
        if (!comment) {
            setError("* required");
            return;
        }
        await dispatch(editThunk({ status, comment, id }));
        dispatch(fetchThunk());
    };

    return (
        <div className="bg-gradient-to-r from-blue-500 to-teal-500 p-4 rounded-lg shadow-lg mt-8 max-w-md mx-auto">
            <h2 className="text-lg text-white font-bold mb-2 text-center">Edit Complaint</h2>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                <div className="mb-4">
                    <label className="block text-sm text-white font-bold mb-2">Status                    {status ? '' : <span className="text-red-500 text-sm">{error}</span>}
                    </label>
                    <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                    >
                        <option value="">Select</option>
                        {statusOptions.map((option) => (
                            <option value={option.value}>{option.label}</option>
                        ))}
                    </select>

                </div>
                <div className="mb-4">
                    <label className="block text-sm text-white font-bold mb-2">Comment                        {comment ? '' : <span className="text-red-500 text-sm">{error}</span>}
                    </label>
                    <textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        className="border border-gray-300 rounded-lg p-2 w-full"
                        rows="4"
                    ></textarea>

                </div>
                <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg border border-white"
                >
                    {updateStatus === 'pending' ? 'Saving' : 'Save'}
                </button>
            </form>
        </div>
    );
};

export default Edit;