import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../redux/slices/authSlice/authSlice';
import { useNavigate, useParams } from 'react-router-dom';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
    const [errors, setErrors] = useState({});
    const { loading, error, resetMessage } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const { token } = useParams();
    const cleanedToken = token.replace(/:/, '');

    useEffect(() => {
        const emailId = sessionStorage.getItem('userEmail');
        if (emailId) {
            setEmail(emailId);
        }
    }, []);

    const validate = () => {
        const newErrors = {};
        if (!newPassword) newErrors.newPassword = 'Password is required';
        if (newPassword !== newPasswordConfirmation) newErrors.newPasswordConfirmation = 'Passwords do not match';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();

        if (Object.keys(validationErrors).length === 0) {
            dispatch(resetPassword({
                new_password: newPassword,
                new_password_confirmation: newPasswordConfirmation,
                token: cleanedToken,
                email: email
            }))
                .then(() => {
                    // navigate('/');
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            setErrors(validationErrors);
        }
    };

    const handleCancel = () => {
        navigate('/');
    };

    return (
        <div className="h-screen bg-[rgb(14,92,167)] flex justify-center items-center">
            <div className="lg:w-[35vw] sm:w-full p-4 md:p-6 lg:p-8 bg-[#28205E] rounded-lg shadow-md mx-4 md:mx-6">
                <button
                    onClick={handleCancel}
                    className="float-right top-4 right-4 h-10 w-10 rounded-3xl bg-red-600 text-white hover:bg-red-800 transition-colors transform hover:scale-110"
                >
                    ✕
                </button>
                <div className="p-4 md:p-6 lg:p-8 text-center">
                    <h1 className="float text-2xl mt-5 lg:text-3xl md:text-3xl font-bold text-white mb-10">Reset Password</h1>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* <div className="flex flex-col">
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                className={`w-full p-2 md:p-3 lg:p-4 pl-3 border rounded focus:border-red-500 focus:ring-red-500 focus:text-red-500 ${errors.newPassword ? 'border-red-500' : 'border-white'}`}
                            />
                            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                        </div> */}
                        <div className="flex flex-col">
                            <input
                                type="password"
                                id="newPassword"
                                name="newPassword"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="Enter new password"
                                className={`w-full p-2 md:p-3 lg:p-4 pl-3 border rounded focus:border-red-500 focus:ring-red-500 focus:text-red-500 ${errors.newPassword ? 'border-red-500' : 'border-white'}`}
                            />
                            {errors.newPassword && <p className="text-red-500 text-sm mt-1">{errors.newPassword}</p>}
                        </div>
                        <div className="flex flex-col">
                            <input
                                type="password"
                                id="newPasswordConfirmation"
                                name="newPasswordConfirmation"
                                value={newPasswordConfirmation}
                                onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                                placeholder="Confirm new password"
                                className={`w-full p-2 md:p-3 lg:p-4 pl-3 border rounded focus:border-red-500 focus:ring-red-500 focus:text-red-500 ${errors.newPasswordConfirmation ? 'border-red-500' : 'border-white'}`}
                            />
                            {errors.newPasswordConfirmation && <p className="text-red-500 text-sm mt-1">{errors.newPasswordConfirmation}</p>}
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-red-500 text-white py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded shadow-md transform transition duration-300 hover:bg-red-700 disabled:bg-gray-500"
                            disabled={loading}
                        >
                            {loading ? 'Changing...' : 'Change Password'}
                        </button>
                        {resetMessage && <p className="text-green-500 text-sm mt-4">{resetMessage}</p>}
                        {error && <p className="text-red-500 text-sm mt-4">{error}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
