import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const getBillingComplaint = createAsyncThunk(
    "billingComplaint/getBillingComplaint",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}billing`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data.data; // Return the data array
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const updateBillingComplaint = createAsyncThunk(
    "billingComplaint/updateBillingComplaint",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}billing/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data.data; // Return the updated data
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const billingComplaintSlice = createSlice({
    name: 'billingComplaint',
    initialState: {
        getStatus: 'idle',
        updateStatus: 'idle',
        billingData: [],
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBillingComplaint.pending, (state) => {
                state.getStatus = "pending";
            })
            .addCase(getBillingComplaint.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.billingData = action.payload;
            })
            .addCase(getBillingComplaint.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(updateBillingComplaint.pending, (state) => {
                state.updateStatus = "pending";
            })
            .addCase(updateBillingComplaint.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';

            })
            .addCase(updateBillingComplaint.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default billingComplaintSlice.reducer;
