import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
} from 'react-router-dom';

import UserLogin from './pages/account/UserLogin';
import ForgotPassword from './pages/account/ForgotPassword';
import ChangePassword from './pages/account/ChangePassword';

import Home from './pages/SalesOfficer/Home';
import Navbar from './pages/SalesOfficer/Navbar';
import RegisterComplaint from './pages/SalesOfficer/RegisterComplaint';
import ComplaintStructure from './pages/SalesOfficer/ComplaintStructure';
import SalesMasonMeet from './pages/SalesOfficer/SalesMasonMeet';
import SalesPostMeetView from './pages/SalesOfficer/SalesPostMeetView';
import SalesMeetView from './pages/SalesOfficer/SalesMeetView';

import UserRegister from './pages/Admin/UserRegister/UserRegister';
import AdminDashboard from './pages/Admin/AdminDashboard';
import AdminComplainList from './pages/Admin/AdminComplainList';
import AdminComplaintDetails from './pages/Admin/AdminComplaintDetails';
import UsersList from "./pages/Admin/UsersList";
import Nav from './pages/Admin/Nav';
import AdminMasonMeet from './pages/Admin/AdminMasonMeet';
import AdminPostMeetView from './pages/Admin/AdminPostMeetView';
import AdminMeetView from './pages/Admin/AdminMeetView';

import AccountComplaint from './pages/AccountHead/AccountComplaint';
import AccountComplaintStructure from './pages/AccountHead/AccountComplaintStructure';
import AccountHome from './pages/AccountHead/AccountHome';
import AcctMasonMeet from './pages/AccountHead/AcctMasonMeet';
import AcctPostMeetView from './pages/AccountHead/AcctPostMeetView';
import AcctMeetView from './pages/AccountHead/AcctMeetView';

import SheadComplaint from './pages/SalesHead/SheadComplaint';
import SheadComplaintStructure from './pages/SalesHead/SheadComplaintStructure';
import SheadHome from './pages/SalesHead/SheadHome';
import SheadMeetView from './pages/SalesHead/SheadMeetView';
import SheadPostMeetView from './pages/SalesHead/SheadPostMeetView';
import SheadMasonMeet from './pages/SalesHead/SheadMasonMeet';

import DispatchComplaint from './pages/DispatchHead/DispatchComplaint';
import DispatchComplaintStructure from './pages/DispatchHead/DispatchComplaintStructure';
import DispatchHome from './pages/DispatchHead/DispatchHome';

import TsmComplaint from './pages/tsm/TsmComplaint';
import TsmComplaintStructure from './pages/tsm/TsmComplaintStructure';
import TsmHome from './pages/tsm/TsmHome';

import AsmComplaint from './pages/asm/AsmComplaint';
import AsmComplaintStructure from './pages/asm/AsmComplaintStructure';
import AsmHome from './pages/asm/AsmHome';

import BillingComplaint from './pages/billing/BillingComplaint';
import BillingComplaintStructure from './pages/billing/BillingComplaintStructure';
import BillingHome from './pages/billing/BillingHome';

import DirectorComplaint from './pages/Director/DirectorComplaint';
import DirectorComplaintStructure from './pages/Director/DirectorComplaintStructure';
import DirectorHome from './pages/Director/DirectorHome';
import DirPostMeetView from './pages/Director/DirPostMeetView';
import DirMasonMeet from './pages/Director/DirMasonMeet';
import DirMeetView from './pages/Director/DirMeetView';

import ProductionComplaintStructure from './pages/ProductionHead/ProductionComplaintStructure';
import ProductionComplaint from './pages/ProductionHead/ProductionComplaint';
import ProductionHome from './pages/ProductionHead/ProductionHome';
import ProductionMasonMeet from './pages/ProductionHead/ProductionMasonMeet';
import MeetView from './pages/ProductionHead/MeetView';

import RsmComplaint from './pages/RSM/RsmComplaint';
import RsmComplaintStructure from './pages/RSM/RsmComplaintStructure';
import RsmHome from './pages/RSM/RsmHome';
import RsmRegisterComplaint from './pages/RSM/RsmRegisterComplaint';
import RsmMeetView from './pages/RSM/RsmMeetView';

import ProtectedRoute from './component/Protected/ProtectedRoute';
import ResetPassword from './pages/account/ResetPassword';
import ComplaintSO from './pages/SalesOfficer/ComplaintSO';

import Meet from './pages/SalesOfficer/Meet';
import MasonMeet from './pages/RSM/MasonMeet';
import RsmPostMeetView from './pages/RSM/RsmPostMeetView';
import ProductUpdate from './pages/Admin/ProductUpdate';


function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {/* Public Route */}

        <Route path="/" element={<UserLogin />} />
        <Route path="/change" element={<ChangePassword />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path='password-reset/:token' element={<ResetPassword />}></Route>

        {/* Protected Routes */}
        <Route path="/account" element={<ProtectedRoute element={<AccountHome />} allowedRoles={['2']} />} />
        <Route path="/account-complaint" element={<ProtectedRoute element={<AccountComplaint />} allowedRoles={['2']} />} />
        <Route path="/account-complaint-details/:id" element={<ProtectedRoute element={<AccountComplaintStructure />} allowedRoles={['2']} />} />
        <Route path="/acct-meet-details/:id" element={<ProtectedRoute element={<AcctMeetView />} allowedRoles={['2']} />} />
        <Route path="/acct-postmeet-details/:id" element={<ProtectedRoute element={<AcctPostMeetView />} allowedRoles={['2']} />} />
        <Route path="/acct-mason-meet" element={<ProtectedRoute element={<AcctMasonMeet />} allowedRoles={['2']} />} />

        <Route path="/production" element={<ProtectedRoute element={<ProductionHome />} allowedRoles={['3']} />} />
        <Route path="/production-complaint" element={<ProtectedRoute element={<ProductionComplaint />} allowedRoles={['3']} />} />
        <Route path="/production-complaint-details/:id" element={<ProtectedRoute element={<ProductionComplaintStructure />} allowedRoles={['3']} />} />
        <Route path="/production-meet-details/:id" element={<ProtectedRoute element={<MeetView />} allowedRoles={['3']} />} />
        <Route path="/production-mason-meet" element={<ProtectedRoute element={<ProductionMasonMeet />} allowedRoles={['3']} />} />

        <Route path="/shead" element={<ProtectedRoute element={<SheadHome />} allowedRoles={['4']} />} />
        <Route path="/shead-complaint" element={<ProtectedRoute element={<SheadComplaint />} allowedRoles={['4']} />} />
        <Route path="/shead-complaint-details/:id" element={<ProtectedRoute element={<SheadComplaintStructure />} allowedRoles={['4']} />} />
        <Route path="/shead-meet-details/:id" element={<ProtectedRoute element={<SheadMeetView />} allowedRoles={['4']} />} />
        <Route path="/shead-postmeet-details/:id" element={<ProtectedRoute element={<SheadPostMeetView />} allowedRoles={['4']} />} />
        <Route path="/shead-mason-meet" element={<ProtectedRoute element={<SheadMasonMeet />} allowedRoles={['4']} />} />

        <Route path="/dispatch" element={<ProtectedRoute element={<DispatchHome />} allowedRoles={['5']} />} />
        <Route path="/dispatch-complaint" element={<ProtectedRoute element={<DispatchComplaint />} allowedRoles={['5']} />} />
        <Route path="/dispatch-complaint-details/:id" element={<ProtectedRoute element={<DispatchComplaintStructure />} allowedRoles={['5']} />} />

        <Route path="/tsm" element={<ProtectedRoute element={<TsmHome />} allowedRoles={['8']} />} />
        <Route path="/tsm-complaint" element={<ProtectedRoute element={<TsmComplaint />} allowedRoles={['8']} />} />
        <Route path="/tsm-complaint-details/:id" element={<ProtectedRoute element={<TsmComplaintStructure />} allowedRoles={['8']} />} />

        <Route path="/asm" element={<ProtectedRoute element={<AsmHome />} allowedRoles={['9']} />} />
        <Route path="/asm-complaint" element={<ProtectedRoute element={<AsmComplaint />} allowedRoles={['9']} />} />
        <Route path="/asm-complaint-details/:id" element={<ProtectedRoute element={<AsmComplaintStructure />} allowedRoles={['9']} />} />

        <Route path="/billing" element={<ProtectedRoute element={<BillingHome />} allowedRoles={['11']} />} />
        <Route path="/billing-complaint" element={<ProtectedRoute element={<BillingComplaint />} allowedRoles={['11']} />} />
        <Route path="/billing-complaint-details/:id" element={<ProtectedRoute element={<BillingComplaintStructure />} allowedRoles={['11']} />} />

        <Route path="/director" element={<ProtectedRoute element={<DirectorHome />} allowedRoles={['10']} />} />
        <Route path="/director-complaint" element={<ProtectedRoute element={<DirectorComplaint />} allowedRoles={['10']} />} />
        <Route path="/director-complaint-details/:id" element={<ProtectedRoute element={<DirectorComplaintStructure />} allowedRoles={['10']} />} />
        <Route path="/dir-meet-details/:id" element={<ProtectedRoute element={<DirMeetView />} allowedRoles={['10']} />} />
        <Route path="/dir-postmeet-details/:id" element={<ProtectedRoute element={<DirPostMeetView />} allowedRoles={['10']} />} />
        <Route path="/dir-mason-meet" element={<ProtectedRoute element={<DirMasonMeet />} allowedRoles={['10']} />} />

        <Route path="/rsm" element={<ProtectedRoute element={<RsmHome />} allowedRoles={['6']} />} />
        <Route path="/rsm-complaint" element={<ProtectedRoute element={<RsmComplaint />} allowedRoles={['6']} />} />
        <Route path="/rsm-register-complain" element={<ProtectedRoute element={<RsmRegisterComplaint />} allowedRoles={['6']} />} />
        <Route path="/rsm-complaint-details/:id" element={<ProtectedRoute element={<RsmComplaintStructure />} allowedRoles={['6']} />} />
        <Route path="/rsm-meet-details/:id" element={<ProtectedRoute element={<RsmMeetView />} allowedRoles={['6']} />} />
        <Route path="/rsm-postmeet-details/:id" element={<ProtectedRoute element={<RsmPostMeetView />} allowedRoles={['6']} />} />
        <Route path="/rsm-mason-meet" element={<ProtectedRoute element={<MasonMeet />} allowedRoles={['6']} />} />

        <Route path="/sales" element={<ProtectedRoute element={<Home />} allowedRoles={['7']} />} />
        <Route path="/so-complaint" element={<ProtectedRoute element={<ComplaintSO />} allowedRoles={['7']} />} />
        <Route path="/register-complain" element={<ProtectedRoute element={<RegisterComplaint />} allowedRoles={['7']} />} />
        <Route path="sales/complaint/:id" element={<ProtectedRoute element={<ComplaintStructure />} allowedRoles={['7']} />} />
        <Route path="/meet" element={<ProtectedRoute element={<Meet />} allowedRoles={['7']} />} />
        <Route path="/sales-meet-details/:id" element={<ProtectedRoute element={<SalesMeetView />} allowedRoles={['7']} />} />
        <Route path="/sales-postmeet-details/:id" element={<ProtectedRoute element={<SalesPostMeetView />} allowedRoles={['7']} />} />
        <Route path="/sales-mason-meet" element={<ProtectedRoute element={<SalesMasonMeet />} allowedRoles={['7']} />} />

        <Route path="/admin-user-register" element={<ProtectedRoute element={<UserRegister />} allowedRoles={['1']} />} />
        <Route path="/admin" element={<ProtectedRoute element={<AdminDashboard />} allowedRoles={['1']} />} />
        <Route path="/admin-complaint-list" element={<ProtectedRoute element={<AdminComplainList />} allowedRoles={['1']} />} />
        <Route path="/admin/complaint/:id" element={<ProtectedRoute element={<AdminComplaintDetails />} allowedRoles={['1']} />} />
        <Route path="/admin-users" element={<ProtectedRoute element={<UsersList />} allowedRoles={['1']} />} />
        <Route path="/admin-nav" element={<ProtectedRoute element={<Nav />} allowedRoles={['1']} />} />
        <Route path="/admin-meet-details/:id" element={<ProtectedRoute element={<AdminMeetView />} allowedRoles={['1']} />} />
        <Route path="/admin-postmeet-details/:id" element={<ProtectedRoute element={<AdminPostMeetView />} allowedRoles={['1']} />} />
        <Route path="/admin-mason-meet" element={<ProtectedRoute element={<AdminMasonMeet />} allowedRoles={['1']} />} />
        <Route path="/manage-products" element={<ProtectedRoute element={<ProductUpdate />} allowedRoles={['1']} />} />

      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
