import * as XLSX from 'xlsx';

const DownloadExcel = (tableId, filename) => {
    try {
        // Extract table data from the HTML element
        const table = document.getElementById(tableId);
        if (!table) {
            throw new Error('Table element not found');
        }

        const rows = Array.from(table.querySelectorAll('tr'));
        const headers = Array.from(rows[0].querySelectorAll('th')).map(th => th.innerText);
        const data = rows.slice(1).map(row => {
            const cells = Array.from(row.querySelectorAll('td'));
            return headers.reduce((acc, header, i) => {
                acc[header] = cells[i] ? cells[i].innerText : '';
                return acc;
            }, {});
        });

        const worksheet = XLSX.utils.json_to_sheet(data, { header: headers });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, filename);
    } catch (error) {
        console.error('Error generating Excel file:', error);
    }
};

export default DownloadExcel;