import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';
import { getUser, updateUser } from '../../redux/slices/admin/registerUserSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../loader/Loader';

const UsersList = () => {
    const dispatch = useDispatch();
    const { user, status } = useSelector(state => state.users);
    const [search, setSearch] = useState('');
    const [editing, setEditing] = useState(false);
    const [currentUser, setCurrentUser] = useState({
        name: '',
        email: '',
        phone: '',
        role: '',
        territory: '',
        reports_to: '',
        is_active: '1',
    });
    const [popupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    const userMapping = useMemo(() => {
        const map = {};
        (Array.isArray(user) ? user : []).forEach(u => {
            map[u.id] = u.name;
        });
        return map;
    }, [user]);

    const filteredUsers = (Array.isArray(user) ? user : []).filter(u =>
        (u.name || '').toLowerCase().includes(search.toLowerCase()) ||
        (u.email || '').toLowerCase().includes(search.toLowerCase()) ||
        (u.phone || '').toLowerCase().includes(search.toLowerCase()) ||
        (u.territory || '').toLowerCase().includes(search.toLowerCase()) ||
        (u.rolename || '').toLowerCase().includes(search.toLowerCase()) ||
        (u.is_active === 1 ? 'Active' : 'Deactive').toLowerCase().includes(search.toLowerCase()) ||
        (u.reportsTo || '').toLowerCase().includes(search.toLowerCase())
    );

    const handleEdit = (user) => {
        setCurrentUser(user);
        setPopupVisible(true);
    };

    const handleSave = async (e) => {
        e.preventDefault();

        try {
            setPopupVisible(false);
            await dispatch(updateUser({
                ...currentUser,
                is_active: Number(currentUser.is_active),
            })).unwrap();
            alert('Updated successfully!');
            dispatch(getUser());
        } catch (error) {
            alert('Failed to update the user:', error);
        }
    };

    const handlePopupChange = (e) => {
        const { name, value } = e.target;
        setCurrentUser(prevState => ({ ...prevState, [name]: value }));
    };

    const reportToOptions = useMemo(() => {
        const filteredUsers = (Array.isArray(user) ? user : []).filter(u => Number(u.role_id) === 4);

        return filteredUsers.map(user => (
            <option key={user.id} value={user.id}>{user.name}</option>
        ));
    }, [user]);



    return (
        <>
            <Nav />
            {status === 'loading' && (
                <Loader />
            )}
            <div className="flex flex-col pt-24">
                <div className="flex flex-col items-center">
                    <div className='flex items-center  mb-6 gap-[36vw] max-h-11'>
                        <input
                            type="search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search by Name, Email, Phone, etc."
                            className="border border-gray-300 rounded-lg p-3 w-full md:w-[50rem] lg:w-[37rem] xl:w-[40rem] text-sm md:text-base lg:text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
                        />
                        <Link to="/admin-user-register">
                            <button className="bg-[#28205E] hover:bg-red-500 hover:text-white text-white font-semibold py-3 px-4 rounded"
                            >
                                Register User
                            </button>
                        </Link>
                    </div>
                    <div className="pt-3 overflow-hidden w-[80rem]">
                        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
                            <thead className=' h-20 bg-gradient-to-r from-blue-500 to-teal-500 text-white'>
                                <tr>
                                    <th className="px-6 py-3 text-left border-b border-gray-200">ID</th>
                                    <th className="px-6 py-3 text-left border-b border-gray-200">Name</th>
                                    <th className="px-6 py-3 text-left border-b border-gray-200">Email</th>
                                    <th className="px-6 py-3 text-left border-b border-gray-200">Phone</th>
                                    <th className="px-6 py-3 text-left border-b border-gray-200">Territory</th>
                                    <th className="px-6 py-3 text-left border-b border-gray-200">Role</th>
                                    <th className="px-6 py-3 text-left border-b border-gray-200">Status</th>
                                    <th className="px-6 py-3 text-left border-b border-gray-200">Reports To</th>
                                    <th className="px-6 py-3 text-left border-b border-gray-200">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {Array.isArray(filteredUsers) && filteredUsers.map((user) => (
                                    <tr key={user.id} className="hover:bg-gray-100 transition-all duration-300 ease-in-out">
                                        <td className="px-6 py-3 text-left">{user.id}</td>
                                        <td className="px-6 py-3 text-left">{user.name}</td>
                                        <td className="px-6 py-3 text-left">{user.email}</td>
                                        <td className="px-6 py-3 text-left">{user.phone}</td>
                                        <td className="px-6 py-3 text-left">{user.territory}</td>
                                        <td className="px-6 py-3 text-left">{user.rolename}</td>
                                        <td className={`px-6 py-3 text-left ${user.is_active == 1 ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
                                            {user.is_active == 1 ? 'Active' : 'Deactive'}
                                        </td>
                                        <td className="px-6 py-3 text-left">{user.report_name}</td>
                                        <td className="px-6 py-3 text-left">
                                            <button
                                                className="bg-gradient-to-r from-green-400 to-teal-400 hover:from-green-500 hover:to-teal-500 text-white font-bold py-2 px-4 rounded transition-colors duration-300 shadow-md hover:shadow-lg"
                                                onClick={() => handleEdit(user)}
                                            >
                                                Edit
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            {popupVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-[600px]">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={currentUser.name}
                                    onChange={handlePopupChange}
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={currentUser.email}
                                    onChange={handlePopupChange}
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Phone</label>
                                <input
                                    type="phone"
                                    name="phone"
                                    value={currentUser.phone}
                                    onChange={handlePopupChange}
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Territory</label>
                                <input
                                    type="text"
                                    name="territory"
                                    value={currentUser.territory}
                                    onChange={handlePopupChange}
                                    className="border border-gray-300 rounded p-2 w-full"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Role</label>
                                <select
                                    name="role"
                                    value={currentUser.role_id}
                                    onChange={handlePopupChange}
                                    className="border border-gray-300 rounded p-2 w-full"
                                >
                                    <option value="">Select Role</option>
                                    <option value="2">Accounts</option>
                                    <option value="3">Production Head</option>
                                    <option value="4">Sales Head</option>
                                    <option value="5">Dispatch Head</option>
                                    <option value="6">RSM</option>
                                    <option value="7">Sales Officer</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="is_active" className="block text-sm font-medium text-gray-700">Status</label>
                                <select
                                    id="is_active"
                                    name="is_active"
                                    value={currentUser.is_active}
                                    onChange={handlePopupChange}
                                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                >
                                    <option value="1">Activate</option>
                                    <option value="0">Deactivate</option>
                                </select>
                            </div>
                            {currentUser.role === '5' && (
                                <div className="mb-4 col-span-2">
                                    <label htmlFor="reports_to" className="block text-sm font-bold text-gray-700 mb-2">Reports To</label>
                                    <select
                                        id="reports_to"
                                        name="reports_to"
                                        value={currentUser.reports_to}
                                        onChange={handlePopupChange}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    >
                                        <option value="">Select</option>
                                        {reportToOptions}
                                    </select>
                                </div>
                            )}
                        </div>
                        <div className="flex justify-end">
                            <button
                                onClick={() => setPopupVisible(false)}
                                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleSave}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default UsersList;
