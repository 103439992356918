import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrl } from '../apiConfig';

const getTokenFromLocalStorage = () => localStorage.getItem("token");

export const fetchProduct = createAsyncThunk('productList/fetchProduct', async (_, { rejectWithValue }) => {
    try {
        const token = getTokenFromLocalStorage();
        const response = await fetch(`${apiUrl}admin/product`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            return rejectWithValue(errorData.message || 'Something went wrong');
        }

        const data = await response.json();
        return data.data;

    } catch (error) {
        return rejectWithValue(error.message || 'Something went wrong');
    }
});

export const addProduct = createAsyncThunk(
    'addProduct',
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: '*/*',
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;

        } catch (error) {
            return rejectWithValue({ message: 'Something went wrong' });
        }
    });

export const addType = createAsyncThunk(
    'addType',
    async (name, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/compaint/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: '*/*',
                },
                body: JSON.stringify({ name }), // Wrap name in an object
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;

        } catch (error) {
            return rejectWithValue({ message: 'Something went wrong' });
        }
    }
);



const productUpdateSlice = createSlice({
    name: 'productList',
    initialState: {
        updateStatus: 'idle',
        products: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchProduct.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProduct.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload;
            })
            .addCase(fetchProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addProduct.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(addProduct.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
            })
            .addCase(addProduct.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addType.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(addType.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
            })
            .addCase(addType.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            });
    },
});

export default productUpdateSlice.reducer;
