import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComplaints, updateComplaint } from '../../redux/slices/admin/complaintListSlice';
import { Link } from 'react-router-dom';
import Nav from './Nav';
import Notification from '../notification/Notification';
import Loader from '../loader/Loader';
import DownloadExcel from '../excel/DownloadExcel';
import Filters from '../../component/utils/filter/Fiilter';

const AdminComplainList = () => {
    const dispatch = useDispatch();
    const { complaints, loading, error } = useSelector((state) => state.complaintList);
    const [searchQuery, setSearchQuery] = useState('');
    // const [filteredComplaints, setFilteredComplaints] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedComplaint, setSelectedComplaint] = useState(null);
    const [status, setStatus] = useState('approve');
    const [comment, setComment] = useState('');
    const [ids, setIds] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(true);
    const [showNotification, setShowNotification] = useState(false);
    const [grievanceFilter, setGrievanceFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');


    useEffect(() => {
        dispatch(fetchComplaints());
        if (shouldFetch) {
            setShouldFetch(false);
        }
    }, [dispatch, shouldFetch]);


    const filteredComplaints = complaints?.filter(complaint => {
        const searchTermLower = searchQuery.trim().toLowerCase().replace(/[^a-zA-Z0-9\s]/g, '');
        return (
            (!grievanceFilter || complaint.type === grievanceFilter) &&
            (!statusFilter || complaint.status === statusFilter) &&
            (
                complaint.invoice_no?.toLowerCase().includes(searchTermLower) ||
                complaint.distributor_name?.toLowerCase().includes(searchTermLower) ||
                complaint.username?.toLowerCase().includes(searchTermLower) ||
                complaint.dealer_name?.toLowerCase().includes(searchTermLower) ||
                complaint.complaint_no?.toLowerCase().includes(searchTermLower) ||
                complaint.status?.toLowerCase().includes(searchTermLower) ||
                complaint.type?.toLowerCase().includes(searchTermLower) ||
                complaint.customer_name?.toLowerCase().includes(searchTermLower) ||
                complaint.invoice_amount?.toString().includes(searchTermLower) // removed the regex replacement
            )
        );
    });

    const indexOfLastComplaint = currentPage * pageSize;
    const indexOfFirstComplaint = indexOfLastComplaint - pageSize;
    const currentComplaints = filteredComplaints.slice(indexOfFirstComplaint, indexOfLastComplaint);
    const totalPages = Math.ceil(filteredComplaints.length / pageSize);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePageSizeChange = (e) => {
        setPageSize(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleEditClick = (complaint) => {
        setSelectedComplaint(complaint);
        setStatus(complaint.status);
        setIds(complaint.id);
    };

    const handleSave = async () => {

        dispatch(updateComplaint({ selectedComplaint, status, ids }));
        setSelectedComplaint(null);
        dispatch(fetchComplaints());
        setShouldFetch(true);
        setTimeout(() => {
            setShowNotification(true);
        }, 1000);
    };

    const handleClose = () => {
        setSelectedComplaint(null);
    };


    return (
        <>
            <Nav />
            {loading === true && (
                <Loader />
            )}
            <div className="container mx-auto pt-24 pb-3">
                {showNotification && (
                    <Notification
                        message="Complaint updated successfully!"
                        onClose={() => setShowNotification(false)}
                    />
                )}


                <div className="flex justify-between mb-6 gap-4  max-h-11">
                    <h2 className="lg:block hidden text-3xl text-blue-900 font-bold">Complaints</h2>

                    <input
                        type="search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search complaints..."
                        className="border border-gray-300 rounded-lg p-3 w-full md:w-[50rem] lg:w-[37rem] xl:w-[40rem] text-sm md:text-base lg:text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
                    />
                    <button
                        onClick={() => DownloadExcel('download-table', 'production-complaints.xlsx')}
                        className="bg-[#28205E] hover:bg-red-500 hover:text-white text-white font-bold py-2 px-4 rounded"
                    >
                        Download
                    </button>
                </div>
                {/* Pagination Controls/ extra filters */}
                <div className="flex items-center justify-between gap-2 mb-4">
                    <div className="flex gap-4">
                        <div>
                            <label className="block text-sm font-bold mb-2">Show:</label>
                            <select
                                id="pageSize"
                                value={pageSize}
                                onChange={handlePageSizeChange}
                                className="border border-gray-300 rounded-lg py-2 px-4"
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <Filters
                            grievanceFilter={grievanceFilter}
                            statusFilter={statusFilter}
                            handleGrievanceChange={(e) => setGrievanceFilter(e.target.value)}
                            handleStatusChange={(e) => setStatusFilter(e.target.value)}
                        />
                    </div>
                    <div className="text-right text-sm">
                        Page {currentPage} of {totalPages}
                    </div>
                </div>
                <div className="overflow-hidden">
                    <table
                        id="download-table"
                        className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
                        <thead className='bg-gradient-to-r from-blue-500 to-teal-500 text-white'>
                            <tr>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Sr No.</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Complaint No.</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Complaint By</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Complaint Date</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Grievance Type</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Distributor Name</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Dealer Name</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Customer Name</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Amount</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Action By</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Status</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {currentComplaints.length > 0 ? (
                                currentComplaints.map((complaint, index) => (
                                    <tr key={complaint.id} className="hover:bg-gray-100 transition-all duration-300 ease-in-out transform">
                                        <td className="px-3 py-4 text-left">{indexOfFirstComplaint + index + 1}</td>
                                        <td className="px-3 py-4 text-left">{complaint.complaint_no}</td>
                                        <td className="px-3 py-4 text-left">{complaint.username}</td>
                                        <td className="px-3 py-4 text-left">{complaint.invoice_date ? complaint.invoice_date.split('-').reverse().join('/') : ""}</td>
                                        <td className="px-3 py-4 text-left">{complaint.type}</td>
                                        <td className="px-3 py-4 text-left">{complaint.distributor_name}</td>
                                        <td className="px-3 py-4 text-left">{complaint.dealer_name}</td>
                                        <td className="px-3 py-4 text-left">{complaint.customer_name}</td>
                                        <td className="px-3 py-4 text-left">{complaint.invoice_amount}</td>
                                        <td className="px-3 py-4 text-left">{complaint.approved_name}</td>
                                        <td className="px-3 py-4 text-left">
                                            <span
                                                className={`inline-block py-1 px-3 rounded-full text-xs font-medium
                                                ${complaint.status === "open"
                                                        ? "bg-blue-200 text-blue-800"
                                                        : complaint.status === "close"
                                                            ? "bg-green-200 text-green-800"
                                                            : complaint.status === "reject"
                                                                ? "bg-red-200 text-red-800"
                                                                : "bg-gray-200 text-gray-800"
                                                    }`}
                                            >
                                                {complaint.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 text-left">
                                            <div className="flex space-x-2">
                                                <button
                                                    className="bg-gradient-to-r from-blue-500 to-teal-500 text-white py-1 px-3 rounded"
                                                    onClick={() => handleEditClick(complaint)}
                                                >
                                                    Edit
                                                </button>
                                                <Link to={`/admin/complaint/${complaint.id}`} className="bg-gradient-to-r from-green-500 to-teal-500 text-white py-1 px-3 rounded">
                                                    View
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (

                                <h1>No comaplaint found</h1>

                            )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls * */}
                <div className=" flex items-center justify-between w-full pt-2">
                    {/* Previous Button */}
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l disabled:opacity-50"
                    >
                        Previous
                    </button>

                    {/* Pagination Numbers */}
                    <div className="flex flex-grow justify-center mx-4">
                        {Array.from({ length: totalPages }, (_, i) => (
                            <button
                                key={i + 1}
                                onClick={() => handlePageChange(i + 1)}
                                className={`px-3 py-2 rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                            >
                                {i + 1}
                            </button>
                        ))}
                    </div>

                    {/* Next Button */}
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r disabled:opacity-50"
                    >
                        Next
                    </button>
                </div>

                {/* Modal */}

                {
                    selectedComplaint && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                            <div className="bg-white p-8 rounded-lg shadow-lg w-96">
                                <h2 className=" font-bold mb-4">Complaint ID : {selectedComplaint.id}</h2>
                                <p className=" font-bold mb-4">Customer : {selectedComplaint.customer_name}</p>
                                <p className=" font-bold mb-4">Dealer : {selectedComplaint.dealer_name}</p>
                                <div className="mb-8">
                                    <label className="block text-sm font-bold mb-2">Status</label>
                                    <select
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                        className="border border-gray-300 rounded-lg p-2 w-full"
                                    >
                                        <option value="">select</option>
                                        <option value="open">Approve</option>
                                    </select>
                                </div>
                                {/* <div className="mb-4">
                                <label className="block text-sm font-bold mb-2">Comment</label>
                                <textarea
                                    value={selectedComplaint.admincomment}
                                    onChange={(e) => setComment(e.target.value)}
                                    className="border border-gray-300 rounded-lg p-2 w-full"
                                    rows="4"
                                ></textarea>
                            </div> */}
                                <div className="flex justify-end gap-4">
                                    <button
                                        onClick={handleSave}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Save
                                    </button>
                                    <button
                                        onClick={handleClose}
                                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div >
        </>
    );
};

export default AdminComplainList;
