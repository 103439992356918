import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Notification from '../../../pages/notification/Notification';
import Loader from '../../../pages/loader/Loader';

const MasonMeetList = ({
    fetchData,
    postData,
    fetchStatus,
    updateStatus,
    // error,
    view,
    postView,
    editThunk,
    registerLink,
    editting = true
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [postsearchQuery, setpostSearchQuery] = useState('');
    const [selectedMeet, setSelectedMeet] = useState(null);
    const [status, setStatus] = useState('');
    const [comment, setComment] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [id, setId] = useState('');
    const [error, setError] = useState('');
    const [masonMeetFilter, setMasonMeetFilter] = useState('pre');
    const dispatch = useDispatch();

    const handleEditClick = (meet) => {
        setSelectedMeet(meet);
        setStatus(meet.status);
        setId(meet.id);
        setComment('');
    };

    const handleSave = async (e) => {
        e.preventDefault();

        if (!status) {
            setError("* required");
            return;
        }
        if (!comment) {
            setError("* required");
            return;
        }

        await dispatch(editThunk({ status, comment, id }));
        setSelectedMeet(null);
        if (updateStatus === 'succeeded') {
            setTimeout(() => {
                setShowNotification(true);
            }, 1000);
        }
    };

    const handleClose = () => {
        setSelectedMeet(null);
    };

    const filteredpreData = fetchData.filter(item =>
        Object.values(item).some(value =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );
    const filteredpostData = postData.filter(item =>
        Object.values(item).some(value =>
            value.toString().toLowerCase().includes(postsearchQuery.toLowerCase())
        )
    );

    return (
        <>
            {showNotification && (
                <Notification
                    message="Updated successfully!"
                    onClose={() => setShowNotification(false)}
                />
            )}
            {fetchStatus === 'loading' && (
                <Loader />
            )}
            {/* Pre Mason Meet */}
            {masonMeetFilter === 'pre' && (
                <div className="p-4 md:p-8 mt-[5rem]">
                    <div className="mb-4 flex flex-row items-center justify-between lg:gap-[10rem]">
                        <div className="flex flex-col float-left items-center justify-center mb-6">
                            <label className="block text-lg text-blue-800 font-bold mb-4">Mason Meet:</label>
                            <div>
                                <button
                                    className={`px-6 py-2 text-md font-bold rounded-l del ${masonMeetFilter === 'pre' ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-gray-300 hover:bg-gray-400 text-gray-800'}`}
                                    onClick={() => setMasonMeetFilter('pre')}
                                >
                                    Pre
                                </button>
                                <button
                                    className={`px-6 py-2 text-md font-bold rounded-r ${masonMeetFilter === 'post' ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-gray-300 hover:bg-gray-400 text-gray-800'}`}
                                    onClick={() => setMasonMeetFilter('post')}
                                >
                                    Post
                                </button>
                            </div>
                        </div>
                        <input
                            type="text"
                            placeholder="Search by any field..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="border border-gray-300 rounded-lg p-3 w-full md:w-[50rem] lg:w-[37rem] xl:w-[40rem] text-sm md:text-base lg:text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
                        />
                        {registerLink && (
                            <Link to={`/${registerLink}`}>
                                <button className="bg-[#28205E] text-white py-3 px-6 rounded-lg shadow-md transition-colors duration-300 hover:bg-red-500 hover:text-white text-sm font-semibold tracking-wide border-0 cursor-pointer">
                                    Register
                                </button>
                            </Link>
                        )}
                    </div>

                    {/* View for Mobile */}
                    <div className="block md:hidden">
                        {filteredpreData.map((item, index) => (
                            <div key={index} className="bg-white border border-gray-200 rounded-lg shadow-lg mb-6 p-4">
                                <h3 className="text-2xl font-bold mb-4">Meet #{item.id}</h3>
                                <h3 className="text-xl font-semibold mb-2">{item.dealerName}</h3>
                                <p className="text-sm text-gray-600 mb-2"><strong>Location:</strong> {item.location}</p>
                                <p className="text-sm text-gray-600 mb-2"><strong>Mason Attendance:</strong> {item.mason_attendance}</p>
                                <p className="text-sm text-gray-600 mb-2"><strong>Planned Expenses:</strong> {item.planned_expenses}</p>
                                <p className="text-sm text-gray-600 mb-2"><strong>Date:</strong> {item.date}</p>
                                <p className="text-sm text-gray-600 mb-2"><strong>Status:</strong>
                                    <span className={`inline-block py-1 px-3 rounded-full text-xs font-medium
                                                ${item.status === "open"
                                            ? "bg-blue-200 text-blue-800"
                                            : item.status === "close"
                                                ? "bg-green-200 text-green-800"
                                                : item.status === "reject"
                                                    ? "bg-red-200 text-red-800"
                                                    : "bg-gray-200 text-gray-800"
                                        }`}>
                                        {item.status}
                                    </span>
                                </p>
                                {editting && (
                                    <button
                                        onClick={() => handleEditClick(item)}
                                        className={`bg-gradient-to-r from-blue-400 to-teal-400 hover:from-blue-500 hover:to-teal-500 text-white font-bold py-2 px-4 rounded transition-colors duration-300 shadow-md hover:shadow-lg ${item.edit === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                                        disabled={item.edit === 1}                                    >
                                        Edit
                                    </button>
                                )}
                                <Link to={`/${view}/${item.id}`}>
                                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4">
                                        View
                                    </button>
                                </Link>
                            </div>
                        ))}
                    </div>

                    {/* View for Desktop */}
                    <div className="hidden md:block">
                        <table className="min-w-full mt-8 bg-white border border-gray-200 rounded-lg shadow-lg">
                            <thead className="bg-gradient-to-r from-blue-500 to-teal-500 text-white">

                                <tr>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Sr. No.</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Meet ID</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Dealer</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Location</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Mason Attendance</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Planned Expenses</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Date</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Remarks</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Status</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {filteredpreData.map((item, index) => (
                                    <tr key={index} className="hover:bg-gray-100 transition-all duration-300 ease-in-out">
                                        <td className="px-3 py-4 text-left">{index + 1}</td>
                                        <td className="px-6 py-4 text-left">{item.uid}</td>
                                        <td className="px-6 py-4 text-left">{item.dealerName}</td>
                                        <td className="px-6 py-4 text-left">{item.location}</td>
                                        <td className="px-6 py-4 text-left">{item.mason_attendance}</td>
                                        <td className="px-6 py-4 text-left">{item.planned_expenses}</td>
                                        <td className="px-6 py-4 text-left">{item.date.split('-').reverse().join('/')}</td>
                                        <td className="px-6 py-4 text-left">{item.remarks}</td>
                                        <td className="px-6 py-4 text-left">

                                            <span
                                                className={`inline-block py-1 px-3 rounded-full text-xs font-medium
                                                    ${item.status === "open"
                                                        ? "bg-blue-200 text-blue-800"
                                                        : item.status === "close"
                                                            ? "bg-green-200 text-green-800"
                                                            : item.status === "reject"
                                                                ? "bg-red-200 text-red-800"
                                                                : "bg-gray-200 text-gray-800"
                                                    }`}
                                            >
                                                {item.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 text-left">
                                            {editting && (
                                                <button
                                                    onClick={() => handleEditClick(item)}
                                                    className={`bg-gradient-to-r from-blue-400 to-teal-400 hover:from-blue-500 hover:to-teal-500 text-white font-bold py-2 px-4 rounded transition-colors duration-300 shadow-md hover:shadow-lg ${item.edit === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                                                    disabled={item.edit === 1}

                                                >
                                                    Edit
                                                </button>
                                            )}

                                            <Link to={`/${view}/${item.id}`}>
                                                <button className="bg-green-500 ml-2 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                    View
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Modal */}
                    {updateStatus === 'pending' && (
                        <Loader />
                    )}
                    {selectedMeet && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                            <div className="bg-white p-8 rounded-lg shadow-lg w-96">
                                <h2 className="font-bold mb-4">Meet ID: {selectedMeet.id}</h2>
                                <p className="font-bold mb-4">Location: {selectedMeet.location}</p>
                                <p className="font-bold mb-4">Dealer: {selectedMeet.dealer_name}</p>
                                <p className="font-bold mb-4">Date: {selectedMeet.date.split('-').reverse().join('/')}</p>

                                <div className="mb-4">
                                    <label className="block text-sm font-bold mb-2">Status</label>
                                    <select
                                        // value={statusValue}
                                        onChange={(e) => setStatus(e.target.value)}
                                        className="border border-gray-300 rounded-lg p-2 w-full"
                                    >
                                        <option>Select</option>
                                        <option value="open">Approve</option>
                                        <option value="reject">Reject</option>
                                        <option value="close">Close</option>
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label className="block text-sm font-bold mb-2">
                                        Comment <span className="font-extrabold text-lg text-red-600">*</span>
                                    </label>
                                    <textarea
                                        onChange={(e) => {
                                            setComment(e.target.value);
                                        }}
                                        className="border border-gray-300 rounded-lg p-2 w-full"
                                        rows="4"
                                    ></textarea>
                                    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
                                </div>

                                <div className="flex justify-end gap-4">
                                    <button
                                        onClick={handleSave}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Save
                                    </button>
                                    <button
                                        onClick={handleClose}
                                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {/* Post Mason Meet */}
            {masonMeetFilter === 'post' && (
                <div className="p-4 md:p-8 mt-[5rem]">
                    <div className="mb-4 flex flex-row items-center justify-between lg:gap-[10rem]">
                        <div className="flex flex-col float-left items-center justify-center mb-6">
                            <label className="block text-lg text-blue-800 font-bold mb-4">Mason Meet:</label>
                            <div>
                                <button
                                    className={`px-6 py-2 text-md font-bold rounded-l ${masonMeetFilter === 'pre' ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-gray-300 hover:bg-gray-400 text-gray-800'}`}
                                    onClick={() => setMasonMeetFilter('pre')}
                                >
                                    Pre
                                </button>
                                <button
                                    className={`px-6 py-2 text-md font-bold rounded-r ${masonMeetFilter === 'post' ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-gray-300 hover:bg-gray-400 text-gray-800'}`}
                                    onClick={() => setMasonMeetFilter('post')}
                                >
                                    Post
                                </button>
                            </div>
                        </div>
                        <input
                            type="text"
                            placeholder="Search by any field..."
                            value={postsearchQuery}
                            onChange={(e) => setpostSearchQuery(e.target.value)}
                            className="border border-gray-300 rounded-lg p-3 w-full md:w-[50rem] lg:w-[37rem] xl:w-[40rem] text-sm md:text-base lg:text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
                        />
                        {registerLink && (
                            <Link to={`/${registerLink}`}>
                                <button className="bg-[#28205E] text-white py-3 px-6 rounded-lg shadow-md transition-colors duration-300 hover:bg-red-500 hover:text-white text-sm font-semibold tracking-wide border-0 cursor-pointer">
                                    Register
                                </button>
                            </Link>
                        )}
                    </div>

                    {/* View for Mobile */}
                    <div className="block md:hidden">
                        {filteredpostData.map((item, index) => (
                            <div key={index} className="bg-white border border-gray-200 rounded-lg shadow-lg mb-6 p-4">
                                <h3 className="text-2xl font-bold mb-4">Meet {item.uid}</h3>
                                <h3 className="text-xl font-semibold mb-2">{item.dealer}</h3>
                                <p className="text-sm text-gray-600 mb-2"><strong>Food Expense:</strong> {item.food_total_amount}</p>
                                <p className="text-sm text-gray-600 mb-2"><strong>Kit Expense:</strong> {item.kit_total_amount}</p>
                                <p className="text-sm text-gray-600 mb-2"><strong>Mason Attendance:</strong> {item.post_masons.length}</p>
                                <p className="text-sm text-gray-600 mb-2"><strong>Total Expenditure:</strong> {item.total_expenditure}</p>
                                <button
                                    onClick={() => setSelectedMeet(item)}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                                >
                                    Edit
                                </button>
                                <Link to={`/${postView}/${item.id}`}>
                                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4">
                                        View
                                    </button>
                                </Link>
                            </div>
                        ))}
                    </div>

                    {/* View for Desktop */}
                    <div className="hidden md:block">
                        <table className="min-w-full mt-8 bg-white border border-gray-200 rounded-lg shadow-lg">
                            <thead className="bg-gradient-to-r from-blue-500 to-teal-500 text-white">

                                <tr>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Sr. No.</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Meet ID</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Food Expense</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Kit Expense</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Mason Attendance</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Total Expense</th>
                                    <th className="px-3 py-4 text-left border-b border-gray-200">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {filteredpostData.map((item, index) => (
                                    <tr key={index} className="hover:bg-gray-100 transition-all duration-300 ease-in-out">
                                        <td className="px-3 py-4 text-left">{index + 1}</td>
                                        <td className="px-6 py-4 text-left">{item.uid}</td>
                                        <td className="px-6 py-4 text-left">{item.food_total_amount}</td>
                                        <td className="px-6 py-4 text-left">{item.kit_total_amount}</td>
                                        <td className="px-6 py-4 text-left">{item.post_masons.length}</td>
                                        <td className="px-6 py-4 text-left">{item.total_expenditure}</td>
                                        <td className="px-6 py-4 text-left">

                                            <Link to={`/${postView}/${item.id}`}>
                                                <button className="bg-green-500 ml-2 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                    View
                                                </button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            )}
        </>

    );
}

export default MasonMeetList;
