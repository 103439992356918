import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDispatchComplaint, updateDispatchComplaint } from '../../redux/slices/Dispatch/dispatchcomplaintslice';
import DispatchNavbar from './DispatchNavbar';
import Notification from '../notification/Notification'
import Loader from '../loader/Loader';
import DownloadExcel from '../excel/DownloadExcel';
import Filters from '../../component/utils/filter/Fiilter';

const DispatchComplaint = () => {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedComplaint, setSelectedComplaint] = useState(null);
    const [status, setStatus] = useState('approve');
    const [comment, setComment] = useState('');
    const [ids, setIds] = useState('');
    const { dispatchData, dispatchStatus, getStatus } = useSelector(state => state.dispatchComplaint);
    const [shouldFetch, setShouldFetch] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [showNotification, setShowNotification] = useState(false);
    const [error, setError] = useState('');
    const [grievanceFilter, setGrievanceFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');

    const searchTermLower = searchTerm.toLowerCase();
    const filteredComplaints = dispatchData?.filter(complaint => {
        return (
            (!grievanceFilter || complaint.type === grievanceFilter) &&
            (!statusFilter || complaint.status === statusFilter) &&
            (
                complaint.invoice_no?.toLowerCase().includes(searchTermLower) ||
                complaint.distributor_name?.toLowerCase().includes(searchTermLower) ||
                complaint.username?.toLowerCase().includes(searchTermLower) ||
                complaint.dealer_name?.toLowerCase().includes(searchTermLower) ||
                complaint.complaint_no?.toLowerCase().includes(searchTermLower) ||
                complaint.status?.toLowerCase().includes(searchTermLower) ||
                complaint.type?.toLowerCase().includes(searchTermLower) ||
                complaint.invoice_amount?.toString().includes(searchTermLower) ||
                complaint.customer_name?.toLowerCase().includes(searchTermLower)
            )
        );
    });

    const totalPages = Math.ceil(filteredComplaints.length / pageSize);
    const paginatedComplaints = filteredComplaints.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    useEffect(() => {
        dispatch(getDispatchComplaint());
        if (shouldFetch) {
            setShouldFetch(false);
        }
    }, [dispatch, shouldFetch]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePageSizeChange = (e) => {
        setPageSize(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleEditClick = (complaint) => {
        setSelectedComplaint(complaint);
        setStatus(complaint.status);
        setIds(complaint.id);
        setComment('');

    };

    const handleSave = () => {
        if (comment.trim() === '') {
            setError('Comment cannot be empty');
            return;
        }

        dispatch(updateDispatchComplaint({ selectedComplaint, status, comment, ids }));
        setSelectedComplaint(null);
        setTimeout(() => {
            setShowNotification(true);
            dispatch(getDispatchComplaint());
        }, 1000);
    };


    const handleClose = () => {
        setSelectedComplaint(null);
    };



    return (
        <>
            <DispatchNavbar />
            {getStatus === 'pending' && (
                <Loader />
            )}
            <div className="container mt-20 mx-auto p-4">
                {showNotification && (
                    <Notification
                        message="Complaint updated successfully!"
                        onClose={() => setShowNotification(false)}
                    />
                )}
                <div className="flex justify-between mb-6 gap-4  max-h-11">
                    <h2 className="lg:block hidden text-3xl text-blue-900 font-bold">Complaints</h2>

                    <input
                        type="search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search complaints..."
                        className="border border-gray-300 rounded-lg p-3 w-full md:w-[50rem] lg:w-[37rem] xl:w-[40rem] text-sm md:text-base lg:text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
                    />
                    <button
                        onClick={() => DownloadExcel('download-table', 'production-complaints.xlsx')}
                        className="bg-[#28205E] hover:bg-red-500 hover:text-white text-white font-bold py-2 px-4 rounded"
                    >
                        Download
                    </button>
                </div>

                {/* Pagination Controls/ extra filters */}
                <div className="flex items-center justify-between gap-2 mb-4">
                    <div className="flex gap-4">
                        <div>
                            <label className="block text-sm font-bold mb-2">Show:</label>
                            <select
                                id="pageSize"
                                value={pageSize}
                                onChange={handlePageSizeChange}
                                className="border border-gray-300 rounded-lg py-2 px-4"
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <Filters
                            grievanceFilter={grievanceFilter}
                            statusFilter={statusFilter}
                            handleGrievanceChange={(e) => setGrievanceFilter(e.target.value)}
                            handleStatusChange={(e) => setStatusFilter(e.target.value)}
                        />
                    </div>
                    <div className="text-right text-sm">
                        Page {currentPage} of {totalPages}
                    </div>
                </div>

                {/* Mobile View */}
                <div className="lg:hidden flex flex-wrap -mx-4">
                    {paginatedComplaints.map((complaint) => (
                        <div key={complaint.id} className="w-full md:w-1/2 xl:w-1/3 p-4">
                            <div className="bg-white p-8 border border-slate-300 rounded-lg shadow-md">
                                <h3 className="text-2xl font-bold mb-4">Complaint #{complaint.id}</h3>
                                <p className="text-sm font-bold mb-2">Complaint No.: {complaint.complaint_no}</p>
                                <p className="text-sm font-bold mb-2">Complaint By: {complaint.username}</p>
                                <p className="text-sm font-bold mb-2">Complaint Date: {complaint.invoice_date ? complaint.invoice_date.split('-').reverse().join('/') : ""}</p>
                                <p className="text-sm font-bold mb-2">Grievance Type: {complaint.type}</p>
                                <p className="text-sm font-bold mb-2">Distributor Name: {complaint.distributor_name}</p>
                                <p className="text-sm font-bold mb-2">Dealer Name: {complaint.dealer_name}</p>
                                <p className="text-sm font-bold mb-2">Customer Name: {complaint.customer_name}</p>
                                <p className="text-sm font-bold mb-2">Amount: {complaint.invoice_amount}</p>
                                <p className="text-sm font-bold mb-2">Status:
                                    <span className={`inline-block py-1 px-3 rounded-full text-xs font-medium
                                                ${complaint.status === "open"
                                            ? "bg-blue-200 text-blue-800"
                                            : complaint.status === "close"
                                                ? "bg-green-200 text-green-800"
                                                : complaint.status === "reject"
                                                    ? "bg-red-200 text-red-800"
                                                    : "bg-gray-200 text-gray-800"
                                        }`}>
                                        {complaint.status}
                                    </span>
                                </p>
                                <span className='flex gap-4'>
                                    <button
                                        className={`bg-blue-500 ml-3 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${!!(complaint.dipatchheadcomment && complaint.dipatchheadcomment.trim()) ? "opacity-50 cursor-not-allowed" : "hover:from-blue-500 hover:to-teal-500"}`}
                                        onClick={() => handleEditClick(complaint)}
                                        disabled={!!(complaint.dipatchheadcomment && complaint.dipatchheadcomment.trim())}
                                    >
                                        Edit
                                    </button>
                                    <Link to={`/dispatch-complaint-details/${complaint.id}`}>
                                        <button className="bg-green-500 -3 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                            View
                                        </button>
                                    </Link>
                                </span>

                            </div>
                        </div>
                    ))}
                </div>

                {/* Desktop View */}
                <div className="hidden lg:block ">
                    <table
                        id="download-table"
                        className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
                        <thead className='bg-gradient-to-r from-blue-500 to-teal-500 text-white'>
                            <tr>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Sr. No.</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Complaint No.</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Complaint By</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Complaint Date</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Grievance Type</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Distributor Name</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Dealer Name</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Customer Name</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Amount</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Action By</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Status</th>
                                <th className="px-3 py-4 text-left border-b border-gray-200">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {paginatedComplaints.length > 0 ? (
                                paginatedComplaints.map((complaint, index) => (
                                    <tr key={complaint.id} className="hover:bg-gray-100 transition-all duration-300 ease-in-out">
                                        <td className="px-3 py-4 text-left">{index + 1}</td>
                                        <td className="px-3 py-4 text-left">{complaint.complaint_no}</td>
                                        <td className="px-3 py-4 text-left">{complaint.username}</td>
                                        <td className="px-3 py-4 text-left">{complaint.invoice_date ? complaint.invoice_date.split('-').reverse().join('/') : ""}</td>
                                        <td className="px-3 py-4 text-left">{complaint.type}</td>
                                        <td className="px-3 py-4 text-left">{complaint.distributor_name}</td>
                                        <td className="px-3 py-4 text-left">{complaint.dealer_name}</td>
                                        <td className="px-3 py-4 text-left">{complaint.customer_name}</td>
                                        <td className="px-3 py-4 text-left">{complaint.invoice_amount}</td>
                                        <td className="px-3 py-4 text-left">{complaint.approved_name}</td>
                                        <td className="px-3 py-4 text-left">
                                            <span
                                                className={`inline-block py-1 px-3 rounded-full text-xs font-medium
                                                    ${complaint.status === "open"
                                                        ? "bg-blue-200 text-blue-800"
                                                        : complaint.status === "close"
                                                            ? "bg-green-200 text-green-800"
                                                            : complaint.status === "reject"
                                                                ? "bg-red-200 text-red-800"
                                                                : "bg-gray-200 text-gray-800"
                                                    }`}
                                            >
                                                {complaint.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 text-left">
                                            <div className="flex space-x-2">
                                                <button
                                                    className={`bg-gradient-to-r from-blue-400 to-teal-400 hover:from-blue-500 hover:to-teal-500 text-white font-bold py-2 px-4 rounded transition-colors duration-300 shadow-md hover:shadow-lg ${!!(complaint.dipatchheadcomment && complaint.dipatchheadcomment.trim()) ? "opacity-50 cursor-not-allowed" : "hover:from-blue-500 hover:to-teal-500"}`}
                                                    onClick={() => handleEditClick(complaint)}
                                                    disabled={!!(complaint.dipatchheadcomment && complaint.dipatchheadcomment.trim())}
                                                >
                                                    Edit
                                                </button>
                                                <Link to={`/dispatch-complaint-details/${complaint.id}`}>
                                                    <button className="bg-gradient-to-r from-green-400 to-teal-400 hover:from-green-500 hover:to-teal-500 text-white font-bold py-2 px-4 rounded transition-colors duration-300 shadow-md hover:shadow-lg">
                                                        View
                                                    </button>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10" className="px-6 py-4 text-center">No data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </div>

                {/* Pagination Controls for Mobile and Desktop */}
                <div className=" flex justify-between items-center mt-4">
                    {/* Mobile Pagination Controls */}
                    <div className="flex lg:hidden items-center justify-center gap-2 p-2 w-full">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Prev
                        </button>
                        <div className="flex gap-1 overflow-x-auto">
                            {Array.from({ length: totalPages }, (_, i) => (
                                <button
                                    key={i + 1}
                                    onClick={() => handlePageChange(i + 1)}
                                    className={`px-3 py-1 rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'} focus:outline-none`}
                                >
                                    {i + 1}
                                </button>
                            ))}
                        </div>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Next
                        </button>
                    </div>


                    {/* Desktop Pagination Controls */}
                    <div className="hidden lg:flex items-center justify-between w-full">
                        {/* Previous Button */}
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l disabled:opacity-50"
                        >
                            Previous
                        </button>

                        {/* Pagination Numbers */}
                        <div className="flex gap-1 overflow-x-auto">
                            {Array.from({ length: totalPages }, (_, i) => (
                                <button
                                    key={i + 1}
                                    onClick={() => handlePageChange(i + 1)}
                                    className={`px-3 py-1 rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'} focus:outline-none`}
                                >
                                    {i + 1}
                                </button>
                            ))}
                        </div>

                        {/* Next Button */}
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r disabled:opacity-50"
                        >
                            Next
                        </button>
                    </div>

                </div>


                {/* Modal */}
                {selectedComplaint && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg w-96">
                            <h2 className=" font-bold mb-4">Complaint ID : {selectedComplaint.id}</h2>
                            <p className=" font-bold mb-4">Customer : {selectedComplaint.customer_name}</p>
                            <p className=" font-bold mb-4">Dealer : {selectedComplaint.dealer_name}</p>
                            <div className="mb-4">
                                <label className="block text-sm font-bold mb-2">Status</label>
                                <select
                                    // value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    className="border border-gray-300 rounded-lg p-2 w-full"
                                >
                                    <option >Select</option>
                                    <option value="open">Approve</option>
                                    <option value="reject">Reject</option>
                                    <option value="close">Close</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-bold mb-2">Comment  <span className='font-extrabold text-lg text-red-600'>*</span></label>
                                <textarea
                                    // value={selectedComplaint.dispatchcomment}
                                    onChange={(e) => {
                                        setComment(e.target.value);
                                        setSelectedComplaint({ ...selectedComplaint, dispatchcomment: e.target.value });
                                    }} className="border border-gray-300 rounded-lg p-2 w-full"
                                    rows="4"
                                ></textarea>
                                {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
                            </div>
                            <div className="flex justify-end gap-4">
                                <button
                                    onClick={handleSave}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Save
                                </button>
                                <button
                                    onClick={handleClose}
                                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default DispatchComplaint;
