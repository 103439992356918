import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getresmcomplaint, updateresmcomplaint } from '../../redux/slices/Rsm/rsmcomplaint';
import RsmNavbar from './RsmNavbar';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Loader from '../loader/Loader';
import Edit from '../editComplaint/Edit';
import Pdf from '../../component/utils/pdfDownload/Pdf';
import Notification from '../notification/Notification';
import ComplaintDetails from '../../component/utils/ComplaintView/ComplaintDetails';

const RsmComplaintStructure = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [complaintDetails, setComplaintDetails] = useState(null);
    const [lightbox, setLightbox] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const { rsmdata = [], getStatus, updateStatus } = useSelector((state) => state.rsmcomplaint);
    const [comment, setComment] = useState('');
    const [status, setStatus] = useState('');
    const [ids, setIds] = useState("");
    const [edit, setEdit] = useState(false);
    const [notification, setNotification] = useState({ message: '', type: '' });

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };

    useEffect(() => {
        if (updateStatus === 'succeeded') {
            setTimeout(() => {
                setEdit(false);
            }, 1000);
        }

        if (updateStatus === 'succeeded' && edit === true) {
            setNotification({ message: 'Complaint updated successfully!', type: 'success' });
            setEdit(false);
        } else if (updateStatus === 'failed' && edit === true) {
            setNotification({ message: 'Failed to update. Try again later!', type: 'error' });
        }
    }, [updateStatus]);

    useEffect(() => {
        dispatch(getresmcomplaint());

    }, [dispatch]);

    useEffect(() => {
        if (rsmdata.length > 0) {
            const complaint = rsmdata.find((complaint) => complaint.id === parseInt(id));
            setComplaintDetails(complaint);
            setIds(complaint.id);
        }
    }, [rsmdata, id]);


    if (!complaintDetails) {
        return (
            <div className="container mx-auto pt-24 p-4 animate-pulse">

                <h2 className="h-8 bg-gray-300 rounded mb-8 mx-auto w-1/3 text-center"></h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Complaint Information Skeleton */}
                    <div className="bg-white p-8 border-2 border-gray-300 rounded-lg space-y-4">
                        <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                        <div className="space-y-2">
                            {Array(15).fill('').map((_, index) => (
                                <div key={index} className="h-4 bg-gray-300 rounded w-full"></div>
                            ))}
                        </div>
                    </div>


                    <div className="bg-white p-8 border-2 border-gray-300 rounded-lg space-y-4">
                        <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                        <div className="space-y-2">
                            {Array(3).fill('').map((_, index) => (
                                <div key={index} className="h-4 bg-gray-300 rounded w-full"></div>
                            ))}
                        </div>
                    </div>


                    <div className="bg-white p-8 border-2 border-gray-300 rounded-lg">
                        <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {Array(3).fill('').map((_, index) => (
                                <div key={index} className="h-24 bg-gray-300 rounded w-full"></div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="flex justify-center mt-8">
                    <div className="h-10 bg-gray-300 rounded w-32"></div>
                </div>
            </div>
        );
    }

    return (
        <>
            <RsmNavbar />
            {notification.message && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification({ message: '', type: '' })}
                />
            )}
            {getStatus === 'pending' && (
                <Loader />
            )}
            <div className="container mx-auto pt-20 p-4">
                <div className="flex justify-between items-center mt-8 mb-8">
                    <Link to="/rsm-complaint">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 lg:h-10 lg:w-10 p-1 delay-200 text-blue-500 hover:text-blue-700 cursor-pointer bg-gray-300 hover:bg-gray-100 rounded-full"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </Link>
                    <h2 className="text-2xl font-bold text-blue-900  ml-10">
                        Complaint Details
                    </h2>
                    <Pdf complaintDetails={complaintDetails} />

                </div>

                <ComplaintDetails
                    complaints={complaintDetails}
                    tagged={complaintDetails.tagname}

                />

                <div className="bg-white p-6 mt-8 border-2 border-gradient rounded-lg shadow-lg">
                    <h3 className="text-2xl font-semibold mb-6 text-center">Gallery</h3>
                    <div className="image-gallery grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4">
                        {complaintDetails.images && complaintDetails.images.map((img, index) => (
                            <div key={index} className=" image-thumb p-1">
                                <img
                                    src={img}
                                    alt={`Image ${index + 1}`}
                                    className="    h-32 w-32 object-fit-contain rounded-lg cursor-pointer transition-transform transform hover:scale-105"
                                    onClick={() => {
                                        setSelectedImage(img);
                                        setLightbox(true);
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    {lightbox && (
                        <div className="lightbox mt-16 fixed inset-0 z-40 bg-black bg-opacity-70 flex justify-center items-center">
                            <div className="slider-container lg:h-[38rem] lg:w-[37rem] h-[30rem] w-[22rem] rounded-lg relative overflow-hidden">
                                <button
                                    onClick={() => setLightbox(false)}
                                    className="absolute z-20 top-10 right-4 text-gray-500 hover:text-gray-900 bg-white p-2 rounded-full shadow-md hover:bg-gray-200 transition duration-300"
                                    aria-label="Close Lightbox"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                                <Slider {...settings} className="">
                                    {complaintDetails.images.map((img, index) => (
                                        <div key={index} className="flex justify-center items-center p-2">
                                            <img
                                                src={img}
                                                alt={`Image ${index + 1}`}
                                                className=" lg:h-[42rem] lg:w-[37rem] h-[30rem] w-[22rem] object-contain rounded-lg"
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    )}
                </div>
                {/* Edit complaint */}
                {edit === true && (<Edit
                    complaintDetails={complaintDetails}
                    statusOptions={[
                        { value: 'open', label: 'Approve' },
                        { value: 'reject', label: 'Reject' },
                        { value: 'close', label: 'Close' },
                    ]}
                    updateComplaint={updateresmcomplaint}
                    getComplaint={getresmcomplaint}
                    updateStatus={updateStatus}
                    ids={ids}
                    showComment={true}
                />
                )}
                {/* end */}

                <span className="flex space-x-4 mt-8 items-center justify-center gap-8">
                    <Link to="/rsm-complaint">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 lg:h-10 lg:w-10 p-1 delay-200 text-blue-500 hover:text-blue-700 cursor-pointer bg-gray-300 hover:bg-gray-100 rounded-full"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </Link>
                    <button
                        className={`${edit
                            ? "bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-300 shadow-md hover:shadow-lg"
                            : `bg-gradient-to-r from-blue-400 to-teal-400 hover:from-blue-500 hover:to-teal-500 text-white font-bold py-2 px-4 rounded transition-colors duration-300 shadow-md hover:shadow-lg 
    ${!!(complaintDetails.rsmcomment && complaintDetails.rsmcomment.trim()) || !!(complaintDetails.productcomment && complaintDetails.productcomment.trim()) || !!(complaintDetails.salesheadcomment && complaintDetails.salesheadcomment.trim()) ? "opacity-50 cursor-not-allowed" : "hover:from-blue-500 hover:to-teal-500"}`}`}
                        onClick={() => setEdit((prev) => !prev)}
                        disabled={!!(complaintDetails.rsmcomment && complaintDetails.rsmcomment.trim()) || !!(complaintDetails.productcomment && complaintDetails.productcomment.trim()) || !!(complaintDetails.salesheadcomment && complaintDetails.salesheadcomment.trim())}
                    >
                        {edit ? "Cancel" : "Edit"}
                    </button>

                </span>
            </div>
        </>
    );
};

export default RsmComplaintStructure;
