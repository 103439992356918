import React, { useEffect, useState } from 'react';
import SheadNavbar from './SheadNavbar';
import { getSheadComplaint } from '../../redux/slices/Shead/sheadcomplaintslice';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from '../../component/utils/Dashboard/Dashboard';

const SheadHome = () => {
    const dispatch = useDispatch();
    const { sheadData } = useSelector(state => state.sheadComplaint);
    const [toggleStatus, setToggleStatus] = useState(null);
    const handleToggleStatus = (data) => {
        setToggleStatus(data);
    };

    useEffect(() => {
        dispatch(getSheadComplaint());
    }, [dispatch]);

    return (
        <>
            <SheadNavbar
                onData={handleToggleStatus}
            />
            <Dashboard
                countSlice={sheadData}
                complaintLink={'shead-complaint'}
                masonMeetLink={'shead-mason-meet'}
                toggleStatus={toggleStatus}
                masonCard={true}
            />
        </>
    );
}

export default SheadHome;
