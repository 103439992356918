import RsmNavbar from './RsmNavbar';
import { addrsmregisterComplaint } from '../../redux/slices/Rsm/rsmcomplaint';
import Form from '../../component/utils/ComplaintForm/Form';
import { useSelector } from 'react-redux';

const RsmRegisterComplaint = () => {

    const complaintStatus = useSelector(state => state.rsmcomplaint.status);
    const complaintError = useSelector(state => state.rsmcomplaint.error);


    return (
        <>
            <RsmNavbar />
            <Form
                registerComplaint={addrsmregisterComplaint}
                complaintStatus={complaintStatus}
                complaintError={complaintError}
                backToComplaint={'rsm-complaint'}
            />
        </>
    );
};

export default RsmRegisterComplaint;
