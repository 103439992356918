import DirectorNavbar from './DirectorNavbar';
import Dashboard from '../../component/utils/Dashboard/Dashboard';
import { getDirectorComplaint } from '../../redux/slices/Director/directorcomplaintslice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const DirectorHome = () => {

    const { directorData } = useSelector(state => state.directorComplaint);
    const dispatch = useDispatch();
    const [toggleStatus, setToggleStatus] = useState(null);

    useEffect(() => {
        dispatch(getDirectorComplaint());
    }, [dispatch]);

    const handleToggleStatus = (data) => {
        setToggleStatus(data);
    };

    return (
        <>
            <DirectorNavbar
                onData={handleToggleStatus}
            />
            <Dashboard
                countSlice={directorData}
                complaintLink={'director-complaint'}
                masonMeetLink={'dir-mason-meet'}
                toggleStatus={toggleStatus}
                masonCard={true}
            />
        </>
    );
}

export default DirectorHome;
