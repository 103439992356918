import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../redux/slices/authSlice/authSlice';
import { useNavigate } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';

const ChangePassword = () => {
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    const { loading, error, changePasswordMessage, status } = useSelector((state) => state.auth);
    const [errors, setErrors] = useState({});
    const [resp, setResp] = useState();
    const [successMessage, setSuccessMessage] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.currentPassword) newErrors.currentPassword = 'Current password is required';
        if (!formData.newPassword) newErrors.newPassword = 'New password is required';
        if (formData.newPassword !== formData.confirmNewPassword) newErrors.confirmNewPassword = 'Passwords do not match';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();

        if (Object.keys(validationErrors).length === 0) {
            dispatch(changePassword({
                current_password: formData.currentPassword,
                new_password: formData.newPassword,
                new_password_confirmation: formData.confirmNewPassword,
            }));

            if (status === 'succeeded') {
                setSuccessMessage('Password changed successfully');
            } else {
                setSuccessMessage('');
                let apiResp = typeof error === 'string' ? error : JSON.stringify(error);
                setResp(apiResp);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    useEffect(() => {
        if (successMessage) {
            setTimeout(() => {
                navigate(-1);
            }, 2000);
        }
    }, [successMessage]);

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <div className="h-screen bg-[rgb(14,92,167)] flex justify-center items-center">
            <div className="lg:w-[35vw] sm:w-full p-4 pb-14 md:p-6 lg:p-8 bg-[#28205E] rounded-lg shadow-md mx-4 md:mx-6 relative">
                <button
                    onClick={handleCancel}
                    className="absolute top-4 right-4 h-10 w-10 rounded-3xl bg-red-600 text-white hover:bg-red-800 transition-colors transform hover:scale-110"
                >
                    ✕
                </button>
                <div className="p-4 md:p-6 lg:p-8 text-center">
                    <h1 className="text-3xl md:text-4xl font-bold text-white mb-10 mt-10">Change Password</h1>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="flex flex-col">
                            {/* <label htmlFor="currentPassword" className="text-sm font-medium text-white">Current Password</label> */}
                            <input
                                type="password"
                                name="currentPassword"
                                id="currentPassword"
                                value={formData.currentPassword}
                                onChange={handleChange}
                                placeholder="Enter your current password"
                                className={`w-full p-2 md:p-3 lg:p-4 pl-3 border rounded focus:border-red-500 focus:ring-red-500 focus:text-red-500 ${errors.currentPassword ? 'border-red-500' : 'border-white'}`}
                            />
                            {errors.currentPassword && <p className="text-red-500 text-sm mt-1">{errors.currentPassword}</p>}
                        </div>
                        <div className="flex flex-col">
                            {/* <label htmlFor="newPassword" className="text-sm font-medium text-white">New Password</label> */}
                            <input
                                type="password"
                                name="newPassword"
                                id="newPassword"
                                value={formData.newPassword}
                                onChange={handleChange}
                                placeholder="Enter your new password"
                                className={`w-full p-2 md:p-3 lg:p-4 pl-3 border rounded focus:border-red-500 focus:ring-red-500 focus:text-red-500 ${errors.newPassword ? 'border-red-500' : 'border-white'}`}
                            />
                            {errors.newPassword && <p className="text-red-500 text-sm mt-1">{errors.newPassword}</p>}
                        </div>
                        <div className="flex flex-col">
                            {/* <label htmlFor="confirmNewPassword" className="text-sm font-medium text-white">Confirm New Password</label> */}
                            <input
                                type="password"
                                name="confirmNewPassword"
                                id="confirmNewPassword"
                                value={formData.confirmNewPassword}
                                onChange={handleChange}
                                placeholder="Confirm your new password"
                                className={`w-full p-2 md:p-3 lg:p-4 pl-3 border rounded focus:border-red-500 focus:ring-red-500 focus:text-red-500 ${errors.confirmNewPassword ? 'border-red-500' : 'border-white'}`}
                            />
                            {errors.confirmNewPassword && <p className="text-red-500 text-sm mt-1">{errors.confirmNewPassword}</p>}
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-red-500 text-white py-2 md:py-3 lg:py-4 px-4 md:px-6 lg:px-8 rounded shadow-md transform transition duration-300 hover:bg-red-700 disabled:bg-gray-500"
                            disabled={loading}
                        >
                            {loading ? 'Changing password...' : 'Change Password'}
                        </button>
                        {error && <p className="text-red-500 text-sm mt-4">{resp}</p>}
                        {status === 'succeeded' && <p className="text-green-500 text-sm mt-4">{successMessage}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
