import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const PostMeetDetails = ({ meet }) => {

    const [lightbox, setLightbox] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,

    };

    return (
        <>
            <table className="table-auto w-full border-collapse shadow-lg mb-8 rounded-lg overflow-hidden">
                <thead className="bg-gradient-to-r from-blue-500 to-blue-700 text-white">
                    <tr>
                        <th className="border-b border-gray-200 px-6 py-3 text-left">S.N.</th>
                        <th className="border-b border-gray-200 px-6 py-3 text-left">MASON NAME</th>
                        <th className="border-b border-gray-200 px-6 py-3 text-left">PHONE NO.</th>
                        <th className="border-b border-gray-200 px-6 py-3 text-left">KIT</th>
                        <th className="border-b border-gray-200 px-6 py-3 text-left">FOOD</th>
                        <th className="border-b border-gray-200 px-6 py-3 text-left">PRESENTATION</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {meet.post_masons && meet.post_masons.map((postMason, index) => (
                        <tr key={index} className="hover:bg-gray-100 transition duration-200 ease-in">
                            <td className="px-6 py-4 text-sm font-medium text-gray-900">{index + 1}</td>
                            <td className="px-6 py-4 text-sm text-gray-700">{postMason.masonName}</td>
                            <td className="px-6 py-4 text-sm text-gray-700">{postMason.masonPhone}</td>
                            <td className="px-6 py-4 text-sm text-gray-700">{postMason.food}</td>
                            <td className="px-6 py-4 text-sm text-gray-700">{postMason.masonKit}</td>
                            <td className="px-6 py-4 text-sm text-gray-700">{postMason.presentation}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="mt-8">
                <table className="table-auto w-full border-collapse shadow-lg rounded-lg overflow-hidden">
                    <thead className="bg-gradient-to-r from-green-500 to-green-700 text-white">
                        <tr>
                            <th className="border-b border-gray-200 px-6 py-3 text-left">Category</th>
                            <th className="border-b border-gray-200 px-6 py-3 text-left">TOTAL NUMBER OF MASON</th>
                            <th className="border-b border-gray-200 px-6 py-3 text-left">EXPENSES PER MASON</th>
                            <th className="border-b border-gray-200 px-6 py-3 text-left">TOTAL AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        <tr className="hover:bg-gray-100 transition duration-200 ease-in">
                            <td className="px-6 py-4 text-sm font-bold text-gray-900">FOODING</td>
                            <td className="px-6 py-4 text-sm text-gray-700">{meet.food_number_of_masons}</td>
                            <td className="px-6 py-4 text-sm text-gray-700">{meet.food_expense_per_mason}</td>
                            <td className="px-6 py-4 text-sm text-gray-700">{meet.food_total_amount}</td>
                        </tr>
                        <tr className="hover:bg-gray-100 transition duration-200 ease-in">
                            <td className="px-6 py-4 text-sm font-bold text-gray-900">KIT</td>
                            <td className="px-6 py-4 text-sm text-gray-700">{meet.kit_number_of_masons}</td>
                            <td className="px-6 py-4 text-sm text-gray-700">{meet.kit_expense_per_mason}</td>
                            <td className="px-6 py-4 text-sm text-gray-700">{meet.kit_total_amount}</td>
                        </tr>
                        <tr className="hover:bg-gray-100 transition duration-200 ease-in">
                            <td className="px-6 py-4 text-sm font-bold text-gray-900">Total Expenditure</td>
                            <td className="px-6 py-4 text-sm text-gray-700"></td>
                            <td className="px-6 py-4 text-sm text-gray-700"></td>
                            <td className="px-6 py-4 text-sm font-bold text-gray-900">{meet.total_expenditure}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="bg-white p-6 mt-8 border-2 border-gradient rounded-lg shadow-lg">
                    <h3 className="text-2xl font-semibold mb-6 text-center">Image Gallery</h3>
                    <div className="image-gallery grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4">
                        {meet.images && meet.images.map((img, index) => (
                            <div key={index} className=" image-thumb p-1">
                                <img
                                    src={img.Imagesdata}
                                    alt={`Image ${index + 1}`}
                                    className="    h-32 w-32 object-fit-contain rounded-lg cursor-pointer transition-transform transform hover:scale-105"
                                    onClick={() => {
                                        setSelectedImage(img.Imagesdata);
                                        setLightbox(true);
                                    }}
                                />
                            </div>
                        ))}
                    </div>

                    {lightbox && (
                        <div className="lightbox mt-10 fixed inset-0 z-40 bg-black bg-opacity-70 flex justify-center items-center">
                            <div className="slider-container lg:h-[42rem] lg:w-[37rem] h-[30rem] w-[22rem] rounded-lg relative overflow-hidden">
                                <button
                                    onClick={() => setLightbox(false)}
                                    className="absolute z-30 top-20 right-4 text-gray-500 hover:text-gray-900 bg-white p-2 rounded-full shadow-md hover:bg-gray-200 transition duration-300"
                                    aria-label="Close Lightbox"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                                <Slider {...settings} className="">
                                    {meet.images.map((img, index) => (
                                        <div key={index} className="flex justify-center items-center p-2">
                                            <img
                                                src={img.Imagesdata}
                                                alt={`Image ${index + 1}`}
                                                className=" lg:h-[42rem] lg:w-[37rem] h-[30rem] w-[22rem] object-contain rounded-lg"
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    )}
                </div>

                <div className="download-button mt-8 flex">
                    {meet.images && meet.images.length > 0 && meet.images[0].postmasonpdf && (
                        <Link
                            to={meet.images[0].postmasonpdf}
                            download="meet-details.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded shadow-md transition duration-300 ease-in-out mx-auto"
                        >
                            <i className="fas fa-file-pdf mr-2 text-lg text-white"></i>
                            <span className="text-sm">Download Bill</span>
                        </Link>
                    )}
                </div>
            </div>

        </>
    );
};

export default PostMeetDetails