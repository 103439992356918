import React from "react";

const ComplaintDetails = ({ complaints, tagged }) => {
    const roles = {
        2: "Account Head",
        3: "Production Head",
        4: "NSM",
        5: "Dispatch Head",
        6: "RSM",
        10: "Director",
        11: "Billing Department",
    };

    function parseDate(dateString) {
        if (!dateString) return null;
        const parts = dateString.split('-');
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        return new Date(`${month}/${day}/${year}`);
    }

    const getCommentRows = () => {
        switch (complaints.type) {
            case "product":
                return [
                    {
                        role_id: 6,
                        comment: complaints.rsmcomment,
                        date: complaints.rsmcommentdate,
                    },
                    {
                        role_id: 3,
                        comment: complaints.productcomment,
                        date: complaints.productcommentdate,
                    },
                    {
                        role_id: 4,
                        comment: complaints.salesheadcomment,
                        date: complaints.salesheadcommentdate,
                    },
                    {
                        role_id: 10,
                        comment: complaints.directorcomment,
                        date: complaints.directorcommentdate,
                    },
                    {
                        role_id: 2,
                        comment: complaints.accountcomment,
                        date: complaints.accountcommentdate,
                    },
                ];
            case "dispatch":
                return [
                    {
                        role_id: 6,
                        comment: complaints.rsmcomment,
                        date: complaints.rsmcommentdate,
                    },
                    {
                        role_id: 5,
                        comment: complaints.dipatchheadcomment,
                        date: complaints.dipatchheadcommendate,
                    },
                    {
                        role_id: 4,
                        comment: complaints.salesheadcomment,
                        date: complaints.salesheadcommentdate,
                    },
                    {
                        role_id: 10,
                        comment: complaints.directorcomment,
                        date: complaints.directorcommentdate,
                    },
                    {
                        role_id: 2,
                        comment: complaints.accountcomment,
                        date: complaints.accountcommentdate,
                    },
                ];
            case "billing":
                return [
                    {
                        role_id: 11,
                        comment: complaints.billingcomment,
                        date: complaints.billingcommentdate,
                    },
                    {
                        role_id: 6,
                        comment: complaints.rsmcomment,
                        date: complaints.rsmcommentdate,
                    },
                    {
                        role_id: 4,
                        comment: complaints.salesheadcomment,
                        date: complaints.salesheadcommentdate,
                    },
                    {
                        role_id: 10,
                        comment: complaints.directorcomment,
                        date: complaints.directorcommentdate,
                    },
                    {
                        role_id: 2,
                        comment: complaints.accountcomment,
                        date: complaints.accountcommentdate,
                    },
                ];
            default:
                return [];
        }
    };

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-white p-8 border-2 border-gradient rounded-lg">
                    <h3 className="text-2xl font-semibold mb-6 text-gray-800">Complaint Information</h3>
                    <table className="w-full mb-4">
                        {/* Grievance based compalint details */}
                        {complaints.type === "product" ? (

                            <table className="w-full mb-4">
                                <tbody>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Complaint No:</td>
                                        <td className="text-sm py-2">{complaints.complaint_no}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Grievance Type:</td>
                                        <td className="text-sm py-2">{complaints.type}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Complaint Date:</td>
                                        <td className="text-sm py-2">{new Date(complaints.created_at).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                                    </tr>
                                    {/* <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Complaint Type:</td>
                                        <td className="text-sm py-2">{complaints.complaint_type}</td>
                                    </tr> */}
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Complaint Remarks:</td>
                                        <td className="text-sm py-2">{complaints.complaint_remarks}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Distributor Name:</td>
                                        <td className="text-sm py-2">{complaints.distributor_name}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Distributor Phone No.:</td>
                                        <td className="text-sm py-2">{complaints.distributor_phone_no}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Dealer Name:</td>
                                        <td className="text-sm py-2">{complaints.dealer_name}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Dealer Phone No.:</td>
                                        <td className="text-sm py-2">{complaints.dealer_phone_no}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Customer Name:</td>
                                        <td className="text-sm py-2">{complaints.customer_name}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Customer Address:</td>
                                        <td className="text-sm py-2">{complaints.customer_address}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Customer Phone No.:</td>
                                        <td className="text-sm py-2">{complaints.customer_phone_no}</td>
                                    </tr>
                                    {/* <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Product Name:</td>
                                        <td className="text-sm py-2">{complaints.product_name}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Product Size:</td>
                                        <td className="text-sm py-2">{complaints.product_size}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Product Quantity (in box):</td>
                                        <td className="text-sm py-2">{complaints.complaint_qty_box}</td>
                                    </tr> */}
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Invoice Date:</td>
                                        <td className="text-sm py-2">{complaints.invoice_date.split('-').reverse().join('/')}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Invoice No.:</td>
                                        <td className="text-sm py-2">{complaints.invoice_no}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Complaint Amount:</td>
                                        <td className="text-sm py-2">{complaints.invoice_amount}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Action By:</td>
                                        <td className="text-sm py-2">
                                            {complaints.approved_name ? complaints.approved_name : "pending..."}
                                        </td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Tagged:</td>
                                        <td className="text-sm py-2">{tagged}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Status:</td>
                                        <td className="text-sm py-2">{complaints.status}</td>
                                    </tr>
                                </tbody>
                            </table>

                        ) : complaints.type === "dispatch" ? (
                            <table className="w-full mb-4">

                                <tbody>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Complaint No:</td>
                                        <td className="text-sm py-2">{complaints.complaint_no}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Grievance Type:</td>
                                        <td className="text-sm py-2">{complaints.type}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Complaint Date:</td>
                                        <td className="text-sm py-2">{new Date(complaints.created_at).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Distributor Name:</td>
                                        <td className="text-sm py-2">{complaints.distributor_name}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Dealer Name:</td>
                                        <td className="text-sm py-2">{complaints.dealer_name}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Customer Name:</td>
                                        <td className="text-sm py-2">{complaints.customer_name}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Product Name:</td>
                                        <td className="text-sm py-2">{complaints.product_name}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Product Size:</td>
                                        <td className="text-sm py-2">{complaints.product_size}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Product Quantity (in box):</td>
                                        <td className="text-sm py-2">{complaints.complaint_qty_box}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Complaint Remarks:</td>
                                        <td className="text-sm py-2">{complaints.complaint_remarks}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Invoice Date:</td>
                                        <td className="text-sm py-2">{complaints.invoice_date.split('-').reverse().join('/')}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Invoice No.:</td>
                                        <td className="text-sm py-2">{complaints.invoice_no}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Complaint Amount:</td>
                                        <td className="text-sm py-2">{complaints.invoice_amount}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Action By:</td>
                                        <td className="text-sm py-2">
                                            {complaints.approved_name ? complaints.approved_name : "pending..."}
                                        </td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Tagged:</td>
                                        <td className="text-sm py-2">{tagged}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Status:</td>
                                        <td className="text-sm py-2">{complaints.status}</td>
                                    </tr>
                                </tbody>
                            </table>

                        ) : complaints.type === "billing" ? (
                            <table className="w-full mb-4">
                                <tbody>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Complaint No:</td>
                                        <td className="text-sm py-2">{complaints.complaint_no}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Grievance Type:</td>
                                        <td className="text-sm py-2">{complaints.type}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Complaint Date:</td>
                                        <td className="text-sm py-2">{new Date(complaints.created_at).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Customer Name:</td>
                                        <td className="text-sm py-2">{complaints.customer_name}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Complaint Remarks:</td>
                                        <td className="text-sm py-2">{complaints.complaint_remarks}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Invoice Date:</td>
                                        <td className="text-sm py-2">{complaints.invoice_date.split('-').reverse().join('/')}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Invoice No.:</td>
                                        <td className="text-sm py-2">{complaints.invoice_no}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Complaint Amount:</td>
                                        <td className="text-sm py-2">{complaints.invoice_amount}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Action By:</td>
                                        <td className="text-sm py-2">
                                            {complaints.approved_name ? complaints.approved_name : "pending..."}
                                        </td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Tagged:</td>
                                        <td className="text-sm py-2">{tagged}</td>
                                    </tr>
                                    <tr className="border-b-2">
                                        <td className="text-sm font-bold py-2">Status:</td>
                                        <td className="text-sm py-2">{complaints.status}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : null}
                    </table>
                </div>

                {/* comments of roles   */}

                <div className="bg-white p-8 border-2 border-gradient rounded-lg shadow-md">
                    <h3 className="text-2xl font-semibold mb-6 text-gray-800">Comments</h3>
                    <div className="bg-gray-50 p-6  rounded-lg ">
                        {getCommentRows().length > 0 ? (
                            <div className="relative border-l border-gray-300 pl-6">
                                {getCommentRows().map((comment, index) => (
                                    <div key={index} className="mb-8">
                                        <div className="absolute -left-4 w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center shadow-md">
                                            <span className="text-xs font-bold">{roles[comment.role_id]?.[0] || "?"}</span>
                                        </div>
                                        <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200 relative">
                                            <div className="flex justify-between items-center mb-2">
                                                <p className="text-sm font-semibold text-gray-900">{roles[comment.role_id]}</p>

                                                <p className="text-xs text-gray-500 text-right">
                                                    {comment.comment && comment.date ? parseDate(comment.date).toLocaleDateString() : "pending"}
                                                </p>
                                            </div>
                                            <p className="text-sm text-gray-600">{comment.comment}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-sm text-gray-500">No comments available.</p>
                        )}
                    </div>
                </div>
            </div>

            {/* product */}

            {complaints.type === 'product' && (
                <div className="bg-white p-6 mt-8 border-2 border-gradient rounded-lg shadow-lg">
                    <div className="overflow-x-auto">
                        <table className="w-full bg-white rounded-lg shadow-lg">
                            <thead>
                                <tr className="bg-gradient-to-r  from-blue-700 to-blue-500 text-white">
                                    <th className="text-left px-4 py-2">Sr No.</th>
                                    <th className="text-left px-4 py-2">SKU Code</th>
                                    <th className="text-left px-4 py-2">Product Name</th>
                                    <th className="text-left px-4 py-2">Size</th>
                                    <th className="text-left px-4 py-2">Complaint Type</th>
                                    <th className="text-left px-4 py-2">Quantity (In Box)</th>
                                </tr>
                            </thead>
                            <tbody className="text-sm text-gray-800">
                                {complaints.product_compaint.map((product, index) => (
                                    <tr
                                        key={index}
                                        className={`transition-all duration-300 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                                            } hover:bg-blue-100 rounded-lg`}
                                    >
                                        <td className="px-4 py-4 text-[1.3rem] font-bold border-b border-gray-300">{index + 1}</td>
                                        <td className="px-4 py-4 text-[1.3rem] border-b border-gray-300">{product.sku_code}</td>
                                        <td className="px-4 py-4 text-[1.3rem] border-b border-gray-300">{product.productName}</td>
                                        <td className="px-4 py-4 text-[1.3rem] border-b border-gray-300">{product.size}</td>
                                        <td className="px-4 py-4 text-[1.3rem] border-b border-gray-300">{product.complaint_type}</td>
                                        <td className="px-4 py-4 text-[1.3rem] border-b border-gray-300">{product.complaint_qty_box}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
};






export default ComplaintDetails;
