import Dashboard from '../../component/utils/Dashboard/Dashboard';
import { getBillingComplaint } from '../../redux/slices/Billing/billingcomplaintslice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import BillingNavbar from './BillingNavbar';

const BillingHome = () => {

    const { billingData } = useSelector(state => state.billingComplaint);
    const dispatch = useDispatch();
    const [toggleStatus, setToggleStatus] = useState(null);
    const handleToggleStatus = (data) => {
        setToggleStatus(data);
    };

    useEffect(() => {
        dispatch(getBillingComplaint());
    }, [dispatch]);

    return (
        <>
            <BillingNavbar
                onData={handleToggleStatus}
            />
            <Dashboard
                countSlice={billingData}
                complaintLink={'billing-complaint'}
                toggleStatus={toggleStatus}
            />
        </>
    );
}

export default BillingHome;
