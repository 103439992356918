import React, { useEffect } from 'react';
import Navbar from './Navbar';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSalesMasonMeetData, fetchSalesPostMasonMeetData } from '../../redux/slices/SalesSlice/salesMasonMeet';
import MasonMeetList from '../../component/utils/MasonList/MasonMeetList';
import { Link } from 'react-router-dom';

const SalesMasonMeet = () => {

    const dispatch = useDispatch();
    const { data, preStatus, error, postMasonData, postStatus } = useSelector((state) => state.meetApproval);

    useEffect(() => {
        dispatch(fetchSalesMasonMeetData());
        dispatch(fetchSalesPostMasonMeetData());

    }, [dispatch]);

    return (
        <>
            <Navbar />
            <MasonMeetList
                fetchData={data}
                postData={postMasonData}
                fetchStatus={preStatus}
                error={error}
                view={'sales-meet-details'}
                postView={'sales-postmeet-details'}
                registerLink={'meet'}
                editting={false}
            />
        </>
    )
}

export default SalesMasonMeet