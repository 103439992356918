import React, { useEffect, useState } from 'react';
import AccountNavbar from './AccountNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountComplaint } from '../../redux/slices/Account/accountcomplaintslice';
import Dashboard from '../../component/utils/Dashboard/Dashboard';

const AccountHome = () => {

    const { accountData } = useSelector(state => state.accountComplaint);
    const dispatch = useDispatch();
    const [toggleStatus, setToggleStatus] = useState(null);

    useEffect(() => {
        dispatch(getAccountComplaint());
    }, [dispatch]);


    const handleToggleStatus = (data) => {
        setToggleStatus(data);
    };
    // console.log('home' + toggleStatus);

    return (
        <>
            <AccountNavbar
                onData={handleToggleStatus}
            />
            <Dashboard
                countSlice={accountData}
                complaintLink={'account-complaint'}
                masonMeetLink={'acct-mason-meet'}
                toggleStatus={toggleStatus}
                masonCard={true}
            />
        </>
    );
}

export default AccountHome;
