import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComplaints } from '../../redux/slices/admin/complaintListSlice';

const AdminDashboard = () => {
    const dispatch = useDispatch();
    const { complaints, loading, error } = useSelector(state => state.complaintList);

    const [openCount, setOpenCount] = useState(0);
    const [closedCount, setClosedCount] = useState(0);
    const [rejectedCount, setRejectedCount] = useState(0);

    useEffect(() => {
        dispatch(fetchComplaints());
    }, [dispatch]);

    useEffect(() => {
        if (complaints.length) {

            const open = complaints.filter(complaint => complaint.status === 'open').length;
            const closed = complaints.filter(complaint => complaint.status === 'close').length;
            const rejected = complaints.filter(complaint => complaint.status === 'reject').length;

            setOpenCount(open);
            setClosedCount(closed);
            setRejectedCount(rejected);
        }
    }, [complaints]);

    return (
        <>
            <Nav />
            <div className="bg-gray-100 min-h-screen flex justify-center items-center p-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mx-auto my-auto gap-[20rem] max-w-md translate-x-20">
                    <Link to='/admin-complaint-list'>
                        <div className="w-72 h-48 bg-gradient-to-r from-blue-300 to-blue-500 border border-gray-200 rounded-xl shadow-lg p-6 hover:shadow-2xl hover:scale-105 transition-transform duration-300">
                            <h2 className="text-lg font-bold text-white mb-4 text-center">Complaints</h2>
                            <div className="flex flex-col space-y-3">
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-medium text-white">Open</p>
                                    <p className="text-xl font-extrabold text-white">{openCount}</p>
                                </div>
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-medium text-white">Closed</p>
                                    <p className="text-xl font-extrabold text-white">{closedCount}</p>
                                </div>
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-medium text-white">Rejected</p>
                                    <p className="text-xl font-extrabold text-white">{rejectedCount}</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                    {/* <Link to='/admin-mason-meet'> */}
                    {/* <div className="w-72 h-48 bg-gradient-to-r from-blue-300 to-blue-500 border border-gray-200 rounded-xl shadow-lg p-6 hover:shadow-2xl hover:scale-105 transition-transform duration-300">
                        <h2 className="text-lg font-bold text-white mb-4 text-center">Mason Meet</h2>

                    </div> */}
                    {/* </Link> */}

                </div>
            </div>
        </>
    );
};

export default AdminDashboard;
