// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import complaintReducer from '../slices/user/complaintSlice';
import userReducer from '../slices/admin/registerUserSlice';
import authReducer from '../slices/authSlice/authSlice';
import complaintListReducer from '../slices/admin/complaintListSlice';
import productListReducer from '../slices/admin/productUpdateSlice';
import salesComplaintReducer from '../slices/SalesSlice/salesComplaintSlice';
import accountcomplaintReducer from '../slices/Account/accountcomplaintslice';
import tsmcomplaintReducer from '../slices/Tsm/tsmcomplaintslice';
import asmcomplaintReducer from '../slices/Asm/asmcomplaintslice';
import sheadcomplaintReducer from '../slices/Shead/sheadcomplaintslice';
import dispatchcomplaintReducer from '../slices/Dispatch/dispatchcomplaintslice';
import directorcomplaintReducer from '../slices/Director/directorcomplaintslice';
import billingcomplaintReducer from '../slices/Billing/billingcomplaintslice';
import productioncomplaintReducer from '../slices/Production/productionComplaintSlice';
import rsmcomplaintReducer from '../slices/Rsm/rsmcomplaint';
import masonReducer from '../slices/SalesSlice/salesMasonMeet'
import rmMasonMeetReducer from '../slices/Rsm/masonMeetSlice';
import productionMasonMeetReducer from '../slices/Production/masonMeetSlice';
import dirMasonMeetReducer from '../slices/Director/masonMeetSlice';
import acctMasonMeetReducer from '../slices/Account/masonMeetSlice';
import sheadMasonMeetReducer from '../slices/Shead/masonMeetSlice';
import adminMasonMeetReducer from '../slices/admin/masonMeetSlice';
import suggestionsReducer from '../slices/FormProductSuggestion/suggestionsSlice';



export const store = configureStore({
    reducer: {
        complaints: complaintReducer,
        users: userReducer,     // admin register user 
        auth: authReducer,     //for the different user login
        complaintList: complaintListReducer, //   complainit list for admin
        productList: productListReducer,
        salesComplaint: salesComplaintReducer,
        accountComplaint: accountcomplaintReducer,
        sheadComplaint: sheadcomplaintReducer,
        dispatchComplaint: dispatchcomplaintReducer,
        directorComplaint: directorcomplaintReducer,
        billingComplaint: billingcomplaintReducer,
        tsmComplaint: tsmcomplaintReducer,
        asmComplaint: asmcomplaintReducer,
        productionComplaint: productioncomplaintReducer,
        rsmcomplaint: rsmcomplaintReducer,
        meetApproval: masonReducer,    //pre mason meet form
        postMeetApproval: masonReducer,  // post mason meet form
        rmMasonMeet: rmMasonMeetReducer,
        dirMasonMeet: dirMasonMeetReducer,
        acctMasonMeet: acctMasonMeetReducer,
        sheadMasonMeet: sheadMasonMeetReducer,
        adminMasonMeet: adminMasonMeetReducer,
        productionMasonMeet: productionMasonMeetReducer,
        suggestions: suggestionsReducer,  //product form suggestion
    },
});
