import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const getDispatchComplaint = createAsyncThunk(
    "dispatchComplaint/getDispatchComplaint",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}dispatchshead`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data.data; // Return the data array
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const updateDispatchComplaint = createAsyncThunk(
    "dispatchComplaint/updateDispatchComplaint",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}dispatchshead/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data.data; // Return the updated data
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const dispatchComplaintSlice = createSlice({
    name: 'dispatchComplaint',
    initialState: {
        getStatus: 'idle',
        updateStatus: 'idle',
        dispatchData: [],
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDispatchComplaint.pending, (state) => {
                state.getStatus = "pending";
            })
            .addCase(getDispatchComplaint.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.dispatchData = action.payload;
            })
            .addCase(getDispatchComplaint.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(updateDispatchComplaint.pending, (state) => {
                state.updateStatus = "pending";
            })
            .addCase(updateDispatchComplaint.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';

            })
            .addCase(updateDispatchComplaint.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default dispatchComplaintSlice.reducer;
